/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Accordion, Row, Col, Dropdown, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import momentTimezone from 'moment-timezone';

/* -------------------------- Internal Dependencies ------------------------- */
import Radio from 'components/radio';
import Card from 'components/card';
import ProgressBar from 'components/progress-bar';
import BASE_URL from 'services/config';
import { setAlert } from 'redux/alert/actions';
import ResultProfile from './result-profile';
import SEO from 'components/seo';
import { AccordionHeader } from 'components/layout/dashboard-layout/component/filter-test';
import MBTI_DATA from 'utils/mbti-data';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Analytics } from '../../../../assets/icons/analytics.svg';
import { ReactComponent as Date } from '../../../../assets/icons/date.svg';
import { ReactComponent as Time } from '../../../../assets/icons/timer.svg';
import { ReactComponent as View } from '../../../../assets/icons/log-out.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close-circle.svg';
import { ReactComponent as Pass } from '../../../../assets/icons/icon-pass.svg';
import { getNumberWithOrdinal } from 'utils';

/* ---------------------------- Results propTypes --------------------------- */
const propTypes = {
	data: PropTypes.object,
	user: PropTypes.object,
	setAlert: PropTypes.func,
	isMyTest: PropTypes.bool,
};

const Results = ({
	data,
	user,
	$user$: currentUser,
	setAlert,
	isMyTest = false,
}) => {
	const [mbti] = useState(MBTI_DATA);

	const ReportURL =
		!data?.hasOwnProperty('mbti_score') &&
		!isMyTest &&
		`${BASE_URL}/api/tests/${user?.slug}/${
			data?.results[0]?.questions[0]?.segment?.id
		}/download_user_test_report/${
			user?.user
		}?timezone=${momentTimezone.tz.guess()}`;

	const copyLink = (link) => {
		const textField = document.createElement('textarea');
		textField.innerText = link || ReportURL;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();

		setAlert('Share Link Copied link to clipboard', 'success');
	};

	const [show, setShow] = useState(false);

	return (
		<>
			{isMyTest ? (
				<SEO title={`Result Page`} />
			) : (
				<SEO
					title={`Results for ${currentUser?.first_name}  ${currentUser?.last_name}`}
				/>
			)}

			{data?.hasOwnProperty('mbti_score') ? (
				<JungWrapper>
					<Header>
						<h4>
							Test Summary{' '}
							{!isMyTest && (
								<button
									className="btn btn-default"
									type="button"
									onClick={() => setShow(true)}
								>
									<View /> View profile
								</button>
							)}
						</h4>
					</Header>

					<Card>
						<div className="row">
							<Col md={8} className="mbti_detail">
								<span>{data?.mbti_score}</span>
								<JungTestDetails>{mbti[data.mbti_score]}</JungTestDetails>
							</Col>
							<Col md={4} className="test_date">
								<span>
									<Date />
								</span>
								<p>Taken on</p>
								<p>
									<b>
										{moment(data?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
									</b>
								</p>
							</Col>
						</div>
					</Card>
				</JungWrapper>
			) : null}

			<Wrapper>
				<Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
					{data?.results?.reverse()?.map((result, index) => (
						<Tab
							eventKey={index + 1}
							title={`${getNumberWithOrdinal(data.results.length - index)} try`}
						>
							<Header>
								{!isMyTest && (
									<h4>
										Test Summary{' '}
										<button
											className="btn btn-default"
											type="button"
											onClick={() => setShow(true)}
										>
											<View /> View profile
										</button>
										<Dropdown className="float-right mt-2px">
											{result?.questions?.[0]?.segment?.id && (
												<>
													{' '}
													<Dropdown.Toggle
														variant="primary"
														className="btn btn-default mr-4"
														id="dropdown-basic"
													>
														Export Report
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() =>
																copyLink(
																	`${BASE_URL}/api/tests/${
																		result?.test?.slug
																	}/${
																		result?.questions?.[0]?.segment?.id
																	}/download_user_test_report/${
																		user?.user
																	}?timezone=${momentTimezone.tz.guess()}`
																)
															}
														>
															Copy Share Link
														</Dropdown.Item>
														<Dropdown.Item
															href={`${BASE_URL}/api/tests/${
																result?.test?.slug
															}/${
																result?.questions?.[0]?.segment?.id
															}/download_user_test_report/${
																user?.user
															}?timezone=${momentTimezone.tz.guess()}`}
															target="_blank"
															rel="noopener noreferrer"
														>
															Download / Preview in PDF
														</Dropdown.Item>
													</Dropdown.Menu>
												</>
											)}
										</Dropdown>
									</h4>
								)}
								<Card>
									<div className="more__flex">
										<ProgressBar
											strokeWidth="10"
											sqSize="200"
											percentage={result?.total_score}
											status={result?.status}
										/>
										<StatusButton status={result?.status}>
											{result?.status}
										</StatusButton>
									</div>
									<div className="summary__bits">
										<Analytics />
										<p>Score details</p>
										<p>
											<b>{result?.score_details} </b>
											Points
										</p>
									</div>
									<div className="summary__bits">
										<Date />
										<p>Taken on</p>
										<p>
											<b>
												{moment(result?.taken_on).format(
													'MMMM Do YYYY, h:mm:ss a'
												)}
											</b>
										</p>
									</div>
									<div className="summary__bits">
										<Time className="fill-dark" />
										<p>Time spent</p>
										<p>
											<b>
												{result?.time_spent?.split(':')[0]}hrs{' '}
												{result?.time_spent?.split(':')[1]}mins{' '}
												{Number(result?.time_spent?.split(':')[2]).toFixed(0)}
												secs
											</b>
										</p>
									</div>
								</Card>
							</Header>
							<Section>
								<h4>Question breakdown</h4>
								{result?.questions &&
									result?.questions?.map((question, index) => (
										<Accordion defaultActiveKey={0} key={question.id}>
											<Items>
												<AccordionHeader eventKey={index}>
													<p>
														<b>
															Question {index + 1} of {result?.questions.length}
														</b>
													</p>
													<p>
														<Analytics className="analytics__icon fill-dark" />{' '}
														{question?.answer?.score || 0}/{question.points}{' '}
														points
													</p>
													<p>
														<Time className="fill-dark" />{' '}
														{question?.time?.split(':')[0]}hrs{' '}
														{question?.time?.split(':')[1]}mins{' '}
														{question?.time?.split(':')[2]}secs
													</p>
													<p>
														{question.answer ? (
															<>
																{question?.answer?.hasOwnProperty('correct') &&
																	(question.answer.correct ? (
																		<Pass />
																	) : (
																		<Close className="fail" />
																	))}
																{question?.answer?.hasOwnProperty(
																	'correctness'
																) && (
																	<strong>
																		{question?.answer?.correctness || 0}%
																	</strong>
																)}
															</>
														) : (
															<Close className="fail" />
														)}
													</p>
												</AccordionHeader>
												<Accordion.Collapse eventKey={index}>
													<Card className="p-3 pt-1">
														<Row>
															<Col md={6}>
																<p></p>
																<div
																	dangerouslySetInnerHTML={{
																		__html: question.description,
																	}}
																></div>
															</Col>
															<Col md={6}>
																{!question.coding ? (
																	<>
																		{question?.options?.map((option) => (
																			// Please dont mind this code i'd need to refactor it
																			<div
																				className={`filled__answers ${(option.id ===
																					question?.answer?.option &&
																					question?.answer?.hasOwnProperty(
																						'correct'
																					) &&
																					(question?.answer.correct
																						? 'answer'
																						: 'failed')) ||
																					''}  ${(option.id ===
																					question?.answer?.option &&
																					question?.answer?.correctness >= 0 &&
																					(question?.answer?.correctness > 0
																						? 'answer'
																						: 'failed')) ||
																					''} ${
																					option?.correct === true &&
																					question?.answer?.correct === false
																						? 'correct__option'
																						: ''
																				}`}
																				key={option.id}
																			>
																				{option?.correct === true &&
																				question?.answer?.correct === false ? (
																					<>
																						<Radio
																							checked={
																								option.id ===
																								question?.answer?.option
																							}
																							disabled
																						>
																							{option.option}
																							<span className="float-right">
																								Correct Answer
																							</span>
																						</Radio>
																					</>
																				) : (
																					<Radio
																						checked={
																							option.id ===
																							question?.answer?.option
																						}
																						disabled
																					>
																						{option.option}
																					</Radio>
																				)}
																			</div>
																		))}
																	</>
																) : (
																	<div className="coding__question">
																		{question?.answer?.source_code
																			?.split('\n')
																			.map((code, index) => (
																				<code key={index}>{code}</code>
																			))}
																	</div>
																)}
															</Col>
														</Row>
													</Card>
												</Accordion.Collapse>
											</Items>
										</Accordion>
									))}
							</Section>
						</Tab>
					))}
				</Tabs>
			</Wrapper>
			{show && (
				<ResultProfile
					show={show}
					user={currentUser}
					closeShow={() => setShow(false)}
				/>
			)}
		</>
	);
};

const JungTestDetails = styled.div`
	margin-top: 20px;

	h3 {
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		color: #151a30;
	}

	p,
	b {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #3e4857;
	}

	p {
		margin-bottom: 1rem;
	}

	b {
		font-weight: 700;
	}
`;

const JungWrapper = styled.div`
	.card-body {
		padding: 40px;
	}

	.mbti_detail {
		span {
			background: rgba(62, 72, 87, 0.05);
			border-radius: 50px;
			padding: 8px 18px;
			font-size: 14px;
			font-weight: 600;
			text-transform: capitalize;
			color: #3e4857;
		}
	}

	.test_date {
		span {
			border-radius: 50%;
			background: rgba(21, 26, 48, 0.05);
			width: 40px;
			height: 40px;
			display: flex;
			margin-bottom: 16px;
			svg {
				display: block;
				margin: auto;
			}
		}
		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			margin-bottom: 4px;
			color: #3e4857;
			b {
				font-weight: 600;
				font-size: 16px;
				line-height: 27px;
				color: #151a30;
			}
		}
	}
`;

const Wrapper = styled.div`
	.fill-dark {
		path {
			fill: #151b30;
		}
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		color: #495057;
		/* background-color: #fff; */
		border-color: transparent;
		border-bottom: 2px solid #3e4857 !important;
		font-weight: 600;
		font-size: 16px;
		border: none;
		background: transparent;
		/* font-size: 30px; */
	}

	.nav-tabs .nav-link {
		border: 1px solid transparent;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		/* color: red; */
		color: #8f9bb3;
		font-size: 16px;
		font-weight: 500;
		padding: 0.2rem 0;
		margin-right: 2rem;
		text-transform: capitalize;
	}
	.nav-tabs {
		border-bottom: 0px solid #dee2e6;
		margin-bottom: 2rem;
	}
`;
const Section = styled.section`
	margin-top: 4rem;
	h4 {
		font-style: normal;
		font-weight: 600;
		margin-bottom: 1rem;
		font-size: 18px;
		line-height: 22px;
		/* identical to box height */

		color: #3e4857;
	}
`;
const Items = styled.div`
	margin-bottom: 1.5rem;
	.filled__answers {
		border: 1px solid #e0e0e0;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 17px 18px 6px;
		margin-bottom: 1.1rem;
		&.correct__option {
			background: #eaf4ed;
			color: #219653;
			border-color: #eaf4ed;
			span.float-right {
				font-size: 13px;
				margin-top: 2px;
			}
		}
		&.answer {
			background: rgba(33, 150, 83, 0.1);
			/* #219653 */

			border: 1px solid #219653;
			box-sizing: border-box;
			border-radius: 4px;
			color: #219653;
			.checkmark:after {
				background: #219653 !important;
			}
			.checkmark {
				border: 2px solid #219653 !important;
			}
		}
		&.failed {
			background: rgba(244, 67, 54, 0.15);
			/* #219653 */

			border: 1px solid #f44336;
			box-sizing: border-box;
			border-radius: 4px;
			color: #f44336;
			.checkmark:after {
				background: #f44336 !important;
			}
			.checkmark {
				border: 2px solid #f44336 !important;
			}
		}
	}
	.coding__question {
		min-height: 430px;
		width: 100%;
		max-height: 500px;
		overflow: auto;
		padding: 15px;
		background: #171717;
		border-left: 15px solid;
		code {
			font-size: 13px;
			color: #50c355;
			word-break: break-word;
			display: block;
		}
	}
	.accordion__header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 1.5rem 2.5rem;

		background: #fff;
		border: none;
		color: #3e4857;
		border-radius: 6px;
		.analytics__icon {
			path {
				fill: #3e4857;
			}
		}
		.fail {
			path {
				stroke: #eb5757;
			}
		}

		p {
			margin: 0;
			font-family: var(--font-primary);
			font-size: 15px;
			font-weight: 500;
			strong {
				color: #000;
			}
			.fill-dark {
				path {
					fill: #8f9bb3;
				}
			}
			&:first-child {
				flex: 0.5;
				text-align: left;
			}
		}
	}
`;

const Header = styled.div`
	h4 {
		font-style: normal;
		font-weight: 600;
		margin-bottom: 2.7rem;
		font-size: 18px;
		line-height: 22px;
		/* identical to box height */

		color: #3e4857;
		button {
			background: rgba(143, 155, 179, 0);
			border-radius: 4px;
			font-weight: 400;
			float: right;
			font-family: var(--font-primary);
			font-size: 15px;
			border: 1px solid #c2ccd9;

			line-height: 19px;
			padding: 8px 18px;
			color: #3e4857;
			box-shadow: none !important;
			box-shadow: none !important;
		}
	}
	.mt-2px {
		margin-top: 2px;
	}
	.card-body {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.more__flex {
		flex: 0.3;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.summary__bits {
		svg {
			background: rgba(21, 26, 48, 0.05);
			padding: 10px;
			border-radius: 50%;
			height: 40px;
			width: 40px;
			fill: #151a30;
			margin-bottom: 4px;
		}
		p {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			margin-bottom: 0;
			margin-top: 8.5px;
			color: #3e4857;
		}
	}
`;

export const StatusButton = styled.span`
	background: ${(props) =>
		props.status === 'failed'
			? '#eb575714'
			: props.status === 'passed'
			? '#4caf504a'
			: ''};
	border-radius: 50px;
	padding: 8px 18px;
	display: block;
	margin-left: 5rem;
	font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
	color: ${(props) =>
		props.status === 'failed'
			? '#EB5757'
			: props.status === 'passed'
			? '#219653'
			: ''};
`;

Results.propTypes = propTypes;
export default connect(null, { setAlert })(Results);
