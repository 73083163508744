/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import { toggleAppHidden } from 'redux/user/actions';
import { handleBackButton } from 'redux/developp-tests/actions/create-test';
import {
	CLEAR_CURRENT_TEST,
	CLEAR_CURRENT_QUESTION,
} from 'pages/dashboard/new-test/components/config';
import SEO from 'components/seo';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Xcircle } from 'assets/icons/x-circle.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import { ReactComponent as LinkOut } from 'assets/icons/log-out.svg';
import history from 'redux/history';

/* ------------------------- NavbarLayout propTypes ------------------------- */
const propTypes = {
	location: PropTypes.object,
	css: PropTypes.string,
	handleSave: PropTypes.func,
	handlePublish: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
	showActions: PropTypes.bool,
};

/* ------------------------ NavbarLayout defaultProps ----------------------- */
const defaultProps = {
	title: 'Tests',
	showActions: true,
	onClick: null,
	children: [],
	css: null,
};

const NavbarLayout = memo((props) => {
	const {
		handleSave,
		handlePublish,
		children,
		showActions,
		location,
		css: customStyle,
	} = props;

	const test_header = location?.pathname?.split('/')[1];
	const segmentIndex = location?.search?.slice(-1);
	const { question: questionId, id } = useParams();

	const dispatch = useDispatch();
	const handleBackBtnClick = useCallback(() => dispatch(handleBackButton()), [
		dispatch,
	]);

	return (
		<Nav css={customStyle}>
			<SEO
				title={`${
					questionId
						? 'Edit Question ' + questionId
						: test_header.replace(/\b\w/g, (l) => l.toUpperCase())
				}`}
			/>
			<div className="collapse__nav">
				<h3>
					<Xcircle
						onClick={() => {
							CLEAR_CURRENT_TEST();
							CLEAR_CURRENT_QUESTION();
							handleBackBtnClick();
						}}
						className="d-inline d-md-block"
					/>
				</h3>

				<Center>
					<h3>
						{questionId && segmentIndex ? (
							<>
								Edit <Arrow className="new__arrow" /> Segment {segmentIndex}{' '}
								<Arrow className="new__arrow" /> Question{' '}
								{Number(questionId) || ''}
							</>
						) : questionId === 'new' ? (
							<>Create A New Question</>
						) : (
							<>{test_header}</>
						)}
					</h3>
				</Center>
				{showActions && (
					<Items>
						<Item>
							<button
								className="btn btn-grey d-none d-md-block"
								onClick={() => handleSave()}
								type="button"
							>
								Save
							</button>
						</Item>
						{test_header === 'edit' && (
							<>
								<Item>
									<button
										className="btn btn-primary d-none d-md-block"
										onClick={() => handlePublish()}
										type="button"
									>
										Publish
									</button>
								</Item>
							</>
						)}

						{test_header === 'edit' && (
							<>
								<Item className="ml-3">
									<button
										className="btn btn- grey d-none d-md-block"
										onClick={() =>
											history.push(`/test-challenge/preview/${id}`)
										}
										type="button"
									>
										<LinkOut className="mr-2" />
										Preview
									</button>
								</Item>
							</>
						)}
						<Item>{children}</Item>
					</Items>
				)}
			</div>
		</Nav>
	);
});

const Nav = styled.nav`
	position: fixed;
	min-height: 70px;
	top: 0;
	z-index: 999;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 0 40px;
	border: 1px solid #edf1f7;
	box-sizing: border-box;
	@media screen and (max-width: 935px) {
		padding: 0 20px;
	}
	right: 0;
	width: 100%;
	.collapse__nav {
		display: flex;
		width: 100%;
		align-items: center;

		h3 {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			/* identical to box height */

			color: #151a30;
		}
		.new__arrow {
			width: 19px;
			fill: #8a8a8a;
			margin: 0 6px;
		}
		svg {
			align-self: center;
			cursor: pointer;
			margin-right: -2px;
		}
	}
	${css`
		${(props) => props.css};
	`};
`;

const Items = styled.div`
	margin-left: auto;
	display: flex;
	span:last-child {
		margin-right: 0 !important;
	}
`;

const Center = styled.div`
	margin-left: auto;
	padding-left: calc(40vw - var(--sidebar-width));
	h3 {
		text-transform: capitalize;
	}
`;

const Item = styled.span`
	margin: 0 0.7rem;
	button {
		background: rgba(143, 155, 179, 0.05);
		border-radius: 4px;
		font-weight: 400;
		font-size: 15px;
		line-height: 19px;
		font-family: 'HK Grotesk';
		padding: 8px 21px;
		color: #3e4857;
		&.btn-primary {
			background: rgba(72, 104, 248, 0.05);
			/* Blue */

			border: 1px solid #4868f8;
			box-sizing: border-box;
			border-radius: 4px;
			color: #4868f8;
		}
		svg {
			margin-right: 4px;
		}
		box-shadow: none !important;
	}
	&.ml-3 {
		border-left: 1px solid #c2ccd9b5;
		padding-left: 1.5rem;
	}
	.grey {
		background: rgba(143, 155, 179, 0);
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		border: 1px solid #c2ccd9;
		line-height: 19px;
		padding: 8px 18px;
		font-family: 'HK Grotesk';
		color: #3e4857;
		box-shadow: none !important;
		box-shadow: none !important;
		&:focus {
			outline: solid 2px var(--theme-primary);
		}
	}
`;

NavbarLayout.defaultProps = defaultProps;

NavbarLayout.propTypes = propTypes;

const mapDispatchToProps = (dispatch) => ({
	toggleHidden: () => dispatch(toggleAppHidden()),
});
export default connect(null, mapDispatchToProps)(withRouter(NavbarLayout));
