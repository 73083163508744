/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/* -------------------------- Internal Dependencies ------------------------- */
import App from 'App';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'redux/store';
import history from 'redux/history';
import * as serviceWorker from 'utils/serviceWorker';
import Alerts from 'components/alerts';

/* ---------------------------- Styles Dependency --------------------------- */
import 'assets/styles/index.css';
import PerformanceStat from 'utils/performance-stat';

// Integrate Sentry
Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_ID,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<Provider store={store}>
		<Alerts />
		<Router history={history}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
PerformanceStat();
