/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { createSelector } from 'reselect';

export const selectNotifications = (state) => state.notifications;

export const selectCurrentNotifications = createSelector(
	[selectNotifications],
	(notif) => notif?.notifications?.notifications
);

export const selectCurrentCount = createSelector(
	[selectNotifications],
	(notif) => notif?.notifications?.unread
);

export const selectisLoading = createSelector(
	[selectNotifications],
	(notif) => notif.isLoading
);
