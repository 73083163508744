/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import * as yup from 'yup';

export const loginValdationSchema = yup.object().shape({
	email: yup
		.string()
		.email('Email is invalid')
		.required('Email is required'),
	password: yup.string().required('Password is required'),
});

export const registerValdationSchema = yup.object().shape({
	name: yup
		.string()
		.matches(
			/^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/,
			'Name should be in "Firstname Lastname" format e.g John Doe, Barry Allen'
		)
		.required('Name is required'),
	email: yup
		.string()
		.email('Email is invalid')
		.required('Email is required'),
	password: yup
		.string()
		.min(6, 'Password is too short - should be 6 chars minimum.')
		.required('Password is required'),
	confirmPassword: yup
		.string()
		.required('Password confirmation is required')
		.oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const passwordValdationSchema = yup.object().shape({
	new_password: yup
		.string()
		.min(6, 'Password is too short - should be 6 chars minimum.')
		.required('Password is required'),
	current_password: yup.string().required('Current password is required'),
});

export const forgotValdationSchema = yup.object().shape({
	email: yup
		.string()
		.email('Email is invalid')
		.required('Email is required'),
});

export const resetValdationSchema = yup.object().shape({
	password: yup
		.string()
		.min(6, 'Password is too short - should be 6 chars minimum.')
		.required('Password is required'),
	newPassword: yup
		.string()
		.required('Password confirmation is required')
		.oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const profileValdationSchema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([a-zA-Z])/, 'Name should be in "Firstname" format e.g John'),
	last_name: yup
		.string()
		.matches(/^([a-zA-Z])/, 'Name should be in "Lastname" format e.g Doe'),
	phone_number: yup
		.string()
		.min(6, 'Phone number should be 6 chars minimum.')
		.max(14, 'Phone number should not be greater than 14 characters'),
	email: yup
		.string()
		.email('Email is invalid')
		.required('Email is required'),
	password: yup
		.string()
		.min(6, 'Password is too short - should be 6 chars minimum.'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Passwords must match'),
	street: yup.string().min(1, 'Address is too short'),
	city: yup.string().min(1, 'City is too short'),
	country: yup.string().min(1, 'Country is too short'),
	plot_number: yup.number(1, 'Plot must be greater than 1'),
	zip_code: yup
		.string()
		.matches(/^[0-9]{3}|[0-9]{8}$/, 'Must be more than 2 digits'),
});

export const newTestValidationSchema = yup.object().shape({
	title: yup
		.string()
		.min(5, 'Minimum of 5 characters is required')
		.max(60, 'Maximum of 60 characters is required')
		.required('Title is required'),
	description: yup
		.string()
		.min(5, 'Minimum of 5 characters is required')
		.required('Description is required'),
	category: yup.string().required('Please Select a Category'),
	level: yup.string().required('Please Select a Level'),
	score: yup.string().required('Please Select a Scoring Method'),
	skills: yup
		.array()
		.min(1, 'Please fill at least one skill')
		.required('Instructions is required'),
	instructions: yup
		.array()
		.of(
			yup.object().shape({
				instruction: yup
					.string()
					.min(2)
					.required(),
			})
		)
		.required('Instructions is required'),
	passmark: yup
		.number()
		.min(1)
		.max(100, 'Invalid Passmark, a maximum of 100 is accepted')
		.required('Pass Mark should be greater than 0'),
});

export const newShareValidation = yup.object().shape({
	email: yup
		.string()
		.email('Email is invalid')
		.required('Email is required'),
	expiration_days: yup
		.number()
		.min(1, 'Expiration days should be greater than 0')
		.required('Expiration days is required'),
});

export const multipleChoiceValidationSchema = yup.object().shape({
	description: yup
		.string()
		.min(5, 'Minimum of 5 characters is required')
		.required('Description is required'),
	points: yup
		.number()
		.min(1)
		.required('Points is required'),
	options_type: yup.string(),
	options: yup
		.array()
		.min(1)
		.of(
			yup.object({
				correct: yup.boolean(),
				points: yup.number(),
			})
		)
		.required('Options is required'),
	hours: yup
		.string()
		.min(1)
		.required('Hours is required'),
	minutes: yup
		.string()
		.min(1)
		.required('Minutes is required'),
	seconds: yup
		.string()
		.min(1)
		.required('Seconds is required'),
});

export const codingChoiceValidationSchema = yup.object().shape({
	description: yup
		.string()
		.min(5, 'Minimum of 5 characters is required')
		.required('Question is required'),
	points: yup
		.number()
		.min(1)
		.required('Points is required'),
	hours: yup
		.string()
		.min(1)
		.required('Hours is required'),
	minutes: yup
		.string()
		.min(1)
		.required('Minutes is required'),
	seconds: yup
		.string()
		.min(1)
		.required('Seconds is required'),
	mode: yup
		.string()
		.min(1)
		.required('Language is required'),
});
