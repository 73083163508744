/* -------------------------- Internal Dependencies ------------------------- */
import { UserActions } from 'redux/user/types';

export const initialState = {
	currentUser: {},
	isLoading: false,
	error: false,
	errorMessage: '',
	hidden: false,
	allUsers: [],
};

const userReducer = (state = initialState, action) => {
	const { type, payload } = action;
	const FAIL_RESPONSE = {
		...state,
		isLoading: false,
		error: true,
		errorMessage: payload ? payload.errorMessage : 'Error has occured',
	};

	switch (type) {
		case UserActions.TOGGLE_SIDEBAR_HIDDEN:
			return {
				...state,
				hidden: !state.hidden,
			};
		case UserActions.SIGN_IN:
		case UserActions.ACTIVATE_LOADING: {
			return { ...state, isLoading: true };
		}
		case UserActions.GET_USER: {
			return {
				...state,
				isLoading: payload.loading,
				currentUser: payload.data || state.currentUser,
			};
		}
		case UserActions.GET_ALL_USER: {
			return {
				...state,
				isLoading: payload.loading,
				allUsers: payload.data,
			};
		}
		case UserActions.EDIT_USER: {
			return {
				...state,
				isLoading: payload.loading,
				currentUser: payload.data,
			};
		}
		case UserActions.EDIT_USER_START: {
			return {
				...state,
				isLoading: payload.loading,
			};
		}
		case UserActions.GET_USER_ERROR: {
			return {
				...state,
				isLoading: false,
				error: true,
				errorMessage: payload.errorMessage,
			};
		}
		case UserActions.SIGN_IN_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case UserActions.SIGN_UP: {
			return { ...state, isLoading: true };
		}

		case UserActions.SIGN_UP_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case UserActions.SIGN_UP_FAIL:
		case UserActions.RESET_PASSWORD_FAIL:
		case UserActions.FORGOT_PASSWORD_FAIL:
		case UserActions.SIGN_IN_FAIL: {
			return FAIL_RESPONSE;
		}

		case UserActions.RESET_PASSWORD: {
			return { ...state, isLoading: true };
		}

		case UserActions.RESET_PASSWORD_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case UserActions.FORGOT_PASSWORD: {
			return { ...state, isLoading: true };
		}

		case UserActions.FORGOT_PASSWORD_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case UserActions.LOGOUT_USER: {
			return {
				currentUser: {},
				isLoading: false,
				error: null,
				hidden: false,
			};
		}

		case UserActions.RESET_TO_TEST: {
			return { ...state, question: '' };
		}
		case UserActions.CLEAR_USER_ERROR: {
			return { ...state, error: false, errorMessage: '' };
		}
		default:
			return { ...state };
	}
};

export default userReducer;
