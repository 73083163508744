/* -------------------------- Internal Dependencies ------------------------- */
import { UsersMetricsActions } from './types';

export const initialState = {
	isLoading: false,
	currentUsersMetrics: {
		count: 0,
		next: null,
		previous: null,
		results: [],
	},
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
const usersMetricsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case UsersMetricsActions.GET_USER_METRICS: {
			return { ...state, isLoading: true };
		}
		case UsersMetricsActions.GET_USER_METRICS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				currentUsersMetrics: payload.data,
			};
		}

		case UsersMetricsActions.GET_USER_METRICS_FAIL: {
			return { ...state, isLoading: false };
		}
		case UsersMetricsActions.CLEAR_METRICS_SESSION: {
			return {
				isLoading: false,
				currentUsersMetrics: {
					count: 0,
					next: null,
					previous: null,
					results: [],
				},
			};
		}

		default:
			return { ...state };
	}
};

export default usersMetricsReducer;
