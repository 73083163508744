/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

/* -------------------------- Internal Dependencies ------------------------- */
import Radio from '../../../../components/radio';
import Modal from '../../../../components/modal';
import { publishTest } from 'redux/developp-tests/actions/create-test';
import Button from '../../../../components/button';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Taxi } from '../../../../assets/icons/taxi.svg';

/* ------------------------- PublishModal propTypes ------------------------- */
const propTypes = {
	show: PropTypes.bool.isRequired,
	testStatus: PropTypes.bool,
	closeModal: PropTypes.func,
};

const PublishModal = ({ show, closeModal, testStatus }) => {
	const dispatch = useDispatch();
	const onPublishTest = useCallback((data) => dispatch(publishTest?.(data)), [
		dispatch,
	]);
	return (
		<>
			<ModalLayout
				title="Publish"
				show={show}
				size="lg"
				closeModal={closeModal}
			>
				<div className="row">
					<div className="col-md-6">
						<Taxi />
					</div>
					<div className="col-md-6">
						<Formik
							enableReinitialize
							initialValues={{
								public: testStatus,
							}}
							// validationSchema={newTestValidationSchema}
							onSubmit={async ({ public: publish }) => {
								const data = { public: publish, published: true };
								await onPublishTest(data);
							}}
						>
							{({ values, setFieldValue, handleSubmit }) => (
								<Form>
									<Radio
										name="public"
										type="radio"
										checked={values.public === true}
										label="public"
										value="public"
										onChange={() => setFieldValue('public', true)}
									>
										<Label>Public</Label>
										<P>Test will be available to everyone on the platform</P>
									</Radio>
									<br />
									<Radio
										name="private"
										type="radio"
										checked={values.public === false}
										label="Private"
										value="private"
										onChange={() => setFieldValue('public', false)}
									>
										<Label>Private</Label>
										<P>
											Only you can see the test and send invites to test takers.
										</P>
									</Radio>
									<RightBottomContainer>
										<Button
											size="small"
											onClick={() => handleSubmit}
											className="btn-developp"
										>
											Publish
										</Button>
									</RightBottomContainer>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</ModalLayout>
		</>
	);
};

PublishModal.propTypes = propTypes;

const RightBottomContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-top: 35px;
	justify-content: flex-end;
	button {
		margin-left: auto;
		padding: 10px 34px;
		cursor: pointer;
	}
`;

const Label = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: #151a30;
`;

const P = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #3e4857;
`;

const ModalLayout = styled(Modal)`
	@media (min-width: 992px) {
		.modal-lg,
		.modal-xl {
			max-width: 650px !important;
		}
	}
	.modal-header {
		h4 {
			text-align: center !important;
			margin-bottom: 2rem;
		}
	}
`;

export default PublishModal;
