/* -------------------------------------------------------------------------- */
/*                            External Dependecies                            */
/* -------------------------------------------------------------------------- */
import React from 'react';
import AceEditor from 'react-ace';

const CodeEditorField = (FieldProps) => {
	return (
		<AceEditor
			mode={FieldProps?.mode}
			theme={FieldProps?.theme}
			isOutputEditor={FieldProps?.isOutputEditor}
			onChange={(value) => {
				FieldProps.form.setFieldValue(FieldProps?.id, value);
				FieldProps.changeValue(FieldProps?.id, value);
			}}
			value={FieldProps?.value || ''}
			highlightActiveLine
			editorProps={{ $blockScrolling: true }}
			setOptions={{
				enableBasicAutocompletion: true,
				enableLiveAutocompletion: true,
				enableSnippets: true,
				showLineNumbers: true,
				tabSize: 2,
			}}
			className=""
		/>
	);
};

export default CodeEditorField;
