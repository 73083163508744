/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import PreviewLayout from 'components/layout/take-test-layout';
import { user_status } from 'utils/user_persist';
import Loader from 'components/loader';

/* -------------------------- Component Dependencies ------------------------- */
const TestPreview = lazy(() => import('pages/preview/test'));

const TakeTestsRoutes = () => {
	const { is_contributor, is_admin, is_superuser } = user_status;

	return (
		<PreviewLayout>
			<Suspense fallback={<Loader loadingText="Preparing Sandbox" />}>
				<Switch>
					<Route path="/test-challenge/:id" exact component={TestPreview} />
					{is_contributor || is_admin || is_superuser ? (
						<Route
							path="/test-challenge/preview/:id"
							exact
							component={TestPreview}
						/>
					) : (
						<Redirect to="/dashboard" />
					)}
				</Switch>
			</Suspense>
		</PreviewLayout>
	);
};

export default TakeTestsRoutes;
