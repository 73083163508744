/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* -------------------------- ProgressBar propTypes ------------------------- */
const propTypes = {
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	progress_text: PropTypes.oneOfType([PropTypes.string]),
	isPastTest: PropTypes.bool,
	status: PropTypes.string,
};

/* ------------------------ ProgressBar defaultProps ------------------------ */
const defaultProps = {
	size: 200,
	width: 160,
	height: 160,
	percentage: 45,
	strokeWidth: 10,
	isPastTest: false,
	progress_text: 'Total Score',
	status: 'failed',
};

const ProgressBar = ({
	size,
	percentage,
	strokeWidth,
	progress_text,
	height,
	isPastTest,
	width,
	status,
}) => {
	const radius = (size - strokeWidth) / 2;
	const dashArray = radius * Math.PI * 2;
	const dashOffset = dashArray - (dashArray * percentage) / 100;

	return (
		<Wrapper percentage={percentage} status={status}>
			<svg width={width} height={height} viewBox={`0 0 ${size} ${size}`}>
				<circle
					className="circle-background"
					cx={size / 2}
					cy={size / 2}
					r={radius}
					strokeWidth={`${strokeWidth}px`}
				/>
				<circle
					className="circle-progress"
					cx={size / 2}
					cy={size / 2}
					r={radius}
					strokeWidth={`${strokeWidth}px`}
					// Start progress marker at 12 O'Clock
					transform={`rotate(-90 ${size / 2} ${size / 2})`}
					style={{
						strokeDasharray: dashArray,
						strokeDashoffset: dashOffset,
					}}
				/>
				{!isPastTest && (
					<text
						className="progress-text"
						x="50%"
						y="34%"
						dy=".3em"
						textAnchor="middle"
						data-testid="progress-text"
					>
						{`${progress_text}`}
					</text>
				)}

				<text
					className="circle-text"
					x="50%"
					y={!isPastTest ? '55%' : '50%'}
					dy=".3em"
					textAnchor="middle"
				>
					{`${percentage}%`}
				</text>
			</svg>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	.circle-background,
	.circle-progress {
		fill: none;
	}

	.circle-background {
		/* stroke: ${(props) =>
			props.percentage <= 25
				? '#eb575714'
				: props.percentage <= 50
				? '#ffc10738'
				: props.percentage > 51
				? '#4caf504a'
				: ''}; */
        stroke: ${(props) =>
					props.status === 'failed'
						? '#eb575714'
						: props.status === 'passed'
						? '#4caf504a'
						: ''};
	}

	.circle-progress {
		/* stroke: ${(props) =>
			props.percentage <= 25
				? '#EB5757'
				: props.percentage <= 50
				? 'orange'
				: props.percentage > 51
				? '#219653'
				: ''}; */
        stroke: ${(props) =>
					props.status === 'failed'
						? '#EB5757'
						: props.status === 'passed'
						? '#219653'
						: ''};
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	.circle-text {
		font-size: 2.5em;
		font-weight: bold;
		fill: #151a30;
		font-family: var(--font-secondary);
		font-weight: 500;
	}
`;

ProgressBar.defaultProps = defaultProps;
ProgressBar.propTypes = propTypes;

export default ProgressBar;
