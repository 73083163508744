/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import Modal from 'components/modal';
import PropTypes from 'prop-types';

/* ---------------------------- Styles Dependency --------------------------- */
import { ModalContainer } from '../styles/navbar';

/* -------------------------- DeleteModal PropTypes ------------------------- */
const delemodalPropTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	deleteTests: PropTypes.func,
	id: PropTypes.string,
};

export const DeleteModal = ({ show, setShow, deleteTests, id }) => {
	return (
		<>
			<Modal
				show={show}
				closeModal={() => setShow(!show)}
				title="Delete test"
				className="modal__nav"
			>
				<ModalContainer className="mb-0 modal__button">
					<p>
						This action is irreversible. You will lose access to this test
						permanently. Are you sure you want to delete this test?
					</p>
					<h5 className="text-right m-0">
						<button
							type="button"
							className="btn-default"
							onClick={() => setShow(!show)}
						>
							No, go back
						</button>
						<button
							type="button"
							className="btn-developp"
							onClick={() => deleteTests(id)}
						>
							Delete
						</button>
					</h5>
				</ModalContainer>
			</Modal>
		</>
	);
};

DeleteModal.propTypes = delemodalPropTypes;

/* -------------------------- VerifyStart PropTypes ------------------------- */

const verifyStartPropTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	history: PropTypes.object,
	id: PropTypes.string,
};

export const VerifyStart = ({
	show: { show: view, type },
	setShow,
	history,
	id,
}) => {
	return (
		<>
			<Modal
				show={view}
				closeModal={() => setShow({ show: false, type })}
				title={`${type === 'retake' ? 'Retake' : 'Start'} Test`}
				className="modal__nav"
			>
				<ModalContainer className="mb-0 modal__button">
					{type === 'retake' ? (
						<p>
							The creator of this test has given you a chance to retake this
							test, when you are ready click on retake test to begin.
						</p>
					) : (
						<p>
							You are about to start this test's questions. When you are ready,
							click on Start Test to begin.
						</p>
					)}

					<h5 className="text-right m-0">
						<button
							type="button"
							className="btn-default"
							onClick={() => setShow({ show: false, type })}
						>
							Cancel
						</button>
						{type === 'retake' ? (
							<button
								type="button"
								className="btn-developp"
								onClick={() =>
									history.push(`/test-challenge/${id}?r=${window.btoa(id)}`)
								}
							>
								Retake Test
							</button>
						) : (
							<button
								type="button"
								className="btn-developp"
								onClick={() => history.push(`/test-challenge/${id}`)}
							>
								Start
							</button>
						)}
					</h5>
				</ModalContainer>
			</Modal>
		</>
	);
};

VerifyStart.propTypes = verifyStartPropTypes;
