/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

/* -------------------------- TestLayout propTypes -------------------------- */

const propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

/* ------------------------- TestLayout defaultProps ------------------------ */
const defaultProps = {
	children: <></>,
};

const TestLayout = ({ children }) => {
	return (
		<>
			<Main>
				<Wrapper data-testid="test-layout">{children}</Wrapper>
			</Main>
		</>
	);
};

const Main = styled.main`
	width: 100%;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	display: block;
	color: #fff;
	background: #171717;
	min-height: 100vh;
`;
const Wrapper = styled.section`
	padding: 6rem 0px 0;
	@media (max-width: 786px) {
		padding: 5rem 0px 0 !important;
	}
`;

TestLayout.defaultProps = defaultProps;

TestLayout.propTypes = propTypes;

export default withRouter(TestLayout);
