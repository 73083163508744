/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { combineReducers } from 'redux';

/* -------------------------- Internal Dependencies ------------------------- */
import userReducer from 'redux/user/reducers';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import testsReducer from './developp-tests/reducer';
import takeTestReducer from './take-test/reducer';
import alertReducer from './alert/reducer';
import testResultsReducer from './test-results/reducers';
import notificationsReducers from './notifications/reducer';
import userMetricsReducer from './get-usermetrics/reducers';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [''],
	stateReconciler: autoMergeLevel1,
};

export const rootReducer = combineReducers({
	alerts: alertReducer,
	notifications: notificationsReducers,
	user: userReducer,
	tests: testsReducer,
	test_take: takeTestReducer,
	test_results: testResultsReducer,
	user_metrics: userMetricsReducer,
});

export default persistReducer(persistConfig, rootReducer);
