/* -------------------------- External Dependencies ------------------------- */
import styled from 'styled-components';

export const Container = styled.aside`
	background: #ffffff;
	height: 100vh;
	width: 260px;

	overflow: ${(props) => (props.isDropdown ? 'unset' : 'auto')};

	transition: all 0.3s ease;
	.empited {
		justify-content: center;
		align-items: center;
		padding: 20px;

		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.6;
		}
		svg {
			margin-top: 3rem !important;
			display: block;
			width: 120px;
			height: 120px;
			margin: auto;
		}
	}
	.dropdown-submenu {
		position: relative;
		display: inline-block;

		/* Dropdown Content (Hidden by Default) */
		.dropdown-content {
			display: none;
			position: absolute;
			background-color: #fff;
			min-width: 160px;

			z-index: 1;
			box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
				0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
		}

		/* Links inside the dropdown */
		.dropdown-content a {
			color: black;
			padding: 12px 16px;
			text-decoration: none;
			display: block;
			font-weight: 400 !important;
			color: #3e4857 !important;
			font-size: 15px !important;
		}

		/* Change color of dropdown links on hover */
		.dropdown-content a:hover {
			background-color: #ddd;
		}

		/* Show the dropdown menu on hover */
		&:hover .dropdown-content {
			display: block;
		}

		/* Change the background color of the dropdown button when the dropdown content is shown */
		&:hover .dropbtn {
			opacity: 0.8;
		}
	}

	.margin__left {
		margin-left: 1.4rem;
	}
	.add__questions {
		margin-left: 3rem;
		.dropdown-item {
			padding: 10px 21px !important;
			span {
				font-family: var(--font-primary);
				font-size: 14px;
			}
		}
	}

	.accordion__header {
		border: none;
		background: #fff;
		font-size: 15px;
		font-weight: 600;
		font-family: var(--font-primary);
		/* padding: 12px 0; */
		width: 100%;
		outline: none;

		color: #151a30;
		padding: 20px 25px 15px 18px;

		display: flex;

		align-items: center;
		svg {
			margin-left: 1px;

			height: 15px;
			width: 22px;

			margin-right: 15px;

			path {
				fill: #8f9bb3;
			}
		}
		.more__icon svg.icons {
			display: block;
			margin: auto;
			height: 15px;
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		p {
			margin: 0;
			display: inline-block;
			flex-basis: 80.4%;
			text-align: left;
		}
	}
	@media screen and (max-width: 935px) {
		transform: translate(
			${(props) => (props.sideBarHidden ? '0px' : '-400px')}
		);
		z-index: 99999;
		width: 75% !important;
		&:before {
			content: '';
			position: absolute;
			background: #ffffff;
			top: 0;
			height: 100%;
			width: calc(100% - 62.5%);
			right: -28vw;
			z-index: -1;
		}
	}
	position: fixed;
	top: 0;
	left: 0;
	padding: 1.5rem 0 4rem;

	.footer__section {
		margin-top: 8rem;
	}
`;

export const Items = styled.div`
	padding: 15px 18px 15px 20px;
	display: block;

	margin-top: 0.5rem;
	border-radius: 6px;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	color: #8f9bb3 !important;
	transition: all 0.4s linear;
	cursor: pointer;
	svg {
		display: inline-block;
		margin-right: 15px;
		path {
			fill: #99a3ba;
		}
	}
	&.active__question {
		background: rgba(72, 104, 248, 0.03);
		svg {
			path {
				fill: #4867f8 !important;
			}
		}
	}
`;

export const ButtonContainer = styled.div`
	position: fixed;
	bottom: 0px;
	background: white;
	padding: 0px 26px 15px 26px;

	button {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		border-radius: 6px;
		text-align: center;
		display: block;
		color: #ffffff;
		padding: 0.585rem 2rem;
		font-size: 14px;
		svg {
			background: rgba(255, 255, 255, 0);
			height: 44px;
			width: 18px !important;
			margin-right: 0.7rem;
			display: inline-block !important;
			margin-top: -23px !important;
			-webkit-transform: translate(0, 11px);
			-ms-transform: translate(0, 11px);
			transform: translate(0, 11px);
		}
		&:disabled {
			opacity: 0.4;
		}
	}
`;

export const Item = styled.span`
	color: #151a30;
`;
export const MultiChoiceItem = styled.span`
	color: #151a30;
`;
export const MultiChoiceItemcontainer = styled.div`
	padding: 3px 25px 3px 18px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	border-radius: 6px;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	color: #8f9bb3 !important;
	transition: all 0.4s linear;
	cursor: pointer;
	svg {
		display: inline-block;
		margin-right: 15px;
		width: 21px;
	}
	.button__wrap {
		display: inline-block;
		padding: 9px 0;
	}
	.more__icon {
		svg.icons {
			display: block;
			margin: auto;
			height: 15px;

			transform: rotate(90deg);
		}
	}
	&.active_question {
		background: rgba(72, 104, 248, 0.03);
		svg {
			path {
				fill: #4867f8;
			}
		}
	}
`;
export const ItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	svg {
		display: inline-block;
		margin-right: 9.5px;
	}
	span {
		padding-left: 10px;
		padding-bottom: 5px;
	}
`;

export const OverlayContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
		0px 4px 8px rgba(0, 0, 0, 0.04);
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	padding: 20px 35px;
`;

export const StyledButton = styled.button`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	border-radius: 6px;
	text-align: center;
	display: block;
	text-align: left;
	font-size: 14px;
	padding: 16px 16px 6px;
	outline: unset !important;
	background: #fff;
	color: #151a30 !important;
	font-family: var(--font-primary);

	svg {
		background: rgba(255, 255, 255, 0);
		height: 44px;
		width: 18px !important;
		margin-right: 15px;
		margin-left: 27px;

		display: inline-block !important;
		margin-top: -23px !important;
		-webkit-transform: translate(0, 11px);
		-ms-transform: translate(0, 11px);
		transform: translate(0, 11px);
		path {
			fill: #151a30;
		}
	}
	&:after {
		display: none;
	}
`;
