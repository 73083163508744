/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, createGlobalStyle } from 'styled-components';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Xcircle } from 'assets/icons/x-circle.svg';

/* ------------------------- SideBarModal propTypes ------------------------ */
const propTypes = {
	show: PropTypes.bool,
	closeShow: PropTypes.func,
	size: PropTypes.string,
	overlayColor: PropTypes.string,
	css: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

/* ------------------------ SideBarModal defaultprops ----------------------- */
const defaultProps = {
	show: false,
	closeShow: () => {},
	size: 'md',
	overlayColor: 'rgba(21, 26, 48, 0.8)',
	css: {},
};

const SideBarModal = ({
	show,
	closeShow,
	size,
	overlayColor,
	css: styling,
	children,
}) => {
	return (
		<>
			{show && (
				<>
					<Body />
					<Wrapper size={size} css={styling} data-testid="sidebarmodal">
						<Overlay
							overlayColor={overlayColor}
							className="overlay"
							onClick={() => closeShow()}
						/>
						<aside className="fadeInLeft">
							<button
								onClick={() => closeShow()}
								className="none-button"
								type="button"
							>
								<Xcircle />
							</button>
							{children}
						</aside>
					</Wrapper>
				</>
			)}
		</>
	);
};

const generateSize = (size) => {
	if (size === 'sm')
		return css`
			width: 21.8em;
			padding: 1.5rem;
		`;
	if (size === 'lg')
		return css`
			width: 32em;
			padding: 2rem;
		`;
	if (size === 'md')
		return css`
			width: 29em;
			padding: 2rem;
		`;
};
const Body = createGlobalStyle`

body{
  overflow: hidden
}`;
const Wrapper = styled.div`
	.none-button {
		border: none;
		background: transparent;
	}

	@keyframes fadeLeft {
		from {
			opacity: 0.8;
			transform: translateX(80%);
		}
		to {
			opacity: 1;
			transform: translateX(0%);
		}
	}
	aside {
		background: #fff;
		${(props) => generateSize(props.size)}
		height: 100%;
		overflow-x: auto;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 999999;
		transition: all 0.3s linear;
		will-change: opacity, transform;
		&.fadeInLeft {
			animation-name: fadeLeft;
			animation-duration: 0.5s;
			animation-fill-mode: both;
		}
	}
`;

const Overlay = styled.div`
	z-index: 99999;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	background: ${(props) => props.overlayColor || 'rgba(21, 26, 48, 0.8)'};
`;

SideBarModal.propTypes = propTypes;
SideBarModal.defaultProps = defaultProps;

export default SideBarModal;
