/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* ---------------------------- Image Dependency ---------------------------- */
import { ReactComponent as Loading } from '../../assets/icons/loading.svg';

/* ------------------------- OverlayLoader propTypes ------------------------ */
const propTypes = {
	loadingText: PropTypes.string,
	loading: PropTypes.bool,
	wide: PropTypes.bool,
};

/* ----------------------- OverlayLoader defaultProps ----------------------- */
const defaultProps = {
	loadingText: 'Loading ...',
	loading: false,
	wide: false,
};

const OverlayLoader = ({ loadingText, loading, wide }) => {
	return (
		<>
			{loading ? (
				<>
					{' '}
					<Wrapper wide={wide}>
						<span>
							<Loading />
							<h3 data-testid="overlay-text">{loadingText}</h3>
						</span>
					</Wrapper>
				</>
			) : null}
		</>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	z-index: 999999999;
	width: ${(props) => (props.wide ? '100%' : 'calc(100% - 260px)')};
	right: 0;
	top: 0;
	position: fixed;
	background: #00000085;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	h3 {
		font-size: 13px;
		text-align: center;
		font-weight: 400;
		text-transform: uppercase;
		color: #dedede;
	}
	svg {
		width: 90px;
		height: 90px;
		margin-bottom: 1rem !important;
		circle {
			stroke: #dedede;
			stroke-width: 3px;
		}
	}
`;

OverlayLoader.defaultProps = defaultProps;

OverlayLoader.propTypes = propTypes;

export default OverlayLoader;
