/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

/* -------------------------- Internal Dependencies ------------------------- */
import CreateTestSidebarLayout from './sidebar';

/* ----------------------- CreateTestLayout propTypes ----------------------- */
const propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

/* ---------------------- CreateTestLayout defaultProps --------------------- */
const defaultProps = {
	children: <></>,
};

const CreateTestLayout = ({ children }) => {
	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<CreateTestSidebarLayout />
				<Main>
					<Wrapper>{children}</Wrapper>
				</Main>
			</DndProvider>
		</>
	);
};

const Main = styled.main`
	float: right;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	width: calc(100% - var(--sidebar-width));
	display: block;
	background: #fafafa;
	min-height: 100vh;
`;
const Wrapper = styled.section`
	padding: 6rem 40px 0;
	@media (max-width: 786px) {
		padding: 5rem 20px 0 !important;
	}
`;

CreateTestLayout.defaultProps = defaultProps;

CreateTestLayout.propTypes = propTypes;
export default CreateTestLayout;
