/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';

/* -------------------------- Internal Dependencies ------------------------- */
import ProtectedRoute from 'components/protected-routes';
import DashboardRoutes from 'routes/dashboard';
import TestsRoutes from 'routes/tests';
import CreateTestsRoutes from 'routes/create-tests';
import TakeTestsRoutes from 'routes/take-test';

/* -------------------------- Component Dependencies ------------------------- */
import ErrorBoundary from 'components/error-boundary';
import Login from 'pages/auth/login';
import Register from 'pages/auth/register';
import Forgot from 'pages/auth/forgotpassword';
import Reset from 'pages/auth/reset';

/* ---------------------------- routes propTypes ---------------------------- */
const propTypes = {
	location: PropTypes.any,
};

const routes = ({ location }) => (
	<HelmetProvider>
		<Wrapper>
			<ErrorBoundary>
				<TransitionGroup>
					<CSSTransition
						key={location.key}
						timeout={{ enter: 100, exit: 0 }}
						classNames="fade"
					>
						<Switch location={location}>
							<Route exact path="/" component={Login} />
							<Route path="/register" component={Register} />
							<Route path="/forgot" component={Forgot} />
							<Route path="/reset/:id" component={Reset} />
							<ProtectedRoute path="/dashboard" component={DashboardRoutes} />
							<ProtectedRoute path="/tests" component={TestsRoutes} />
							<ProtectedRoute path="/create" component={CreateTestsRoutes} />
							<ProtectedRoute
								path="/test-challenge"
								component={TakeTestsRoutes}
							/>
							<ProtectedRoute path="/edit" component={CreateTestsRoutes} />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			</ErrorBoundary>
		</Wrapper>
	</HelmetProvider>
);

const Wrapper = styled.div`
	.fade-enter {
		opacity: 0.6;
	}

	.fade-enter.fade-enter-active {
		opacity: 1;
		transition: opacity 0.3s ease-in;
	}

	.fade-exit {
		opacity: 1;
	}

	.fade-exit.fade-exit-active {
		opacity: 0.6;
		transition: opacity 0.3s ease-in;
	}
`;

routes.propTypes = propTypes;

export default withRouter(routes);
