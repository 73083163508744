/* eslint-disable react/jsx-props-no-spreading */

/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

/* -------------------------- TabWrapper propTypes -------------------------- */
const propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	id: PropTypes.string,
	activeTab: PropTypes.string,
	tabType: PropTypes.string,
};

/* ------------------------- TabWrapper defaultProps ------------------------ */
const defaultProps = {
	id: 'tabs',
	tabType: 'primary',
};

const TabWrapper = ({ children, id, tabType, activeTab, ...rest }) => {
	const [activeKey, setActiveKey] = useState(
		activeTab || children[0].props.eventKey
	);
	return (
		<TabStyling tabType={tabType}>
			<Tabs
				id={id}
				{...rest}
				activeKey={activeTab || activeKey}
				onClick={(e) => {
					const { target } = e;
					if (!activeTab) {
						setActiveKey(
							target.getAttribute('data-rb-event-key')
								? target.getAttribute('data-rb-event-key')
								: children[0].props.eventKey
						);
					}
				}}
			>
				{children.map((child) => {
					const {
						eventKey,
						title,
						disabled = false,
						children: childProps,
						badge,
						click,
						...childrens
					} = child.props;

					return (
						<Tab
							eventKey={eventKey}
							title={
								<span
									onClick={click}
									className="custom__handler"
									role="tab"
									data-rb-event-key={`${eventKey}`}
									id={`tabs-tab-${eventKey}`}
									aria-controls={`tabs-tabpane-${eventKey}`}
								>
									{title}{' '}
									{badge > 0 && <span className="tab__badge">{badge}</span>}
								</span>
							}
							disabled={disabled}
							key={title}
							{...childrens}
						>
							{activeTab ? (
								<>{eventKey !== activeTab ? false : childProps}</>
							) : (
								<>{eventKey !== activeKey ? false : childProps}</>
							)}
						</Tab>
					);
				})}
			</Tabs>
		</TabStyling>
	);
};

// eslint-disable-next-line consistent-return
const generateStyle = (type) => {
	if (type === 'primary')
		return css`
			border: none;
			color: #3e4857 !important;
			border-radius: 0;
			opacity: 0.5;
			font-size: 15px;
			font-weight: 600;
			font-style: normal;
			line-height: 19px;
			&:first-child {
				margin-right: 10px;
			}

			&.active {
				color: var(--theme-primary) !important;
				opacity: 1;
				background: transparent !important;
				border-bottom: 2px solid var(--theme-primary);
			}
		`;
	if (type === 'secondary') return css``;
};
const TabStyling = styled.div`
	.tab__badge {
		background: #eb5757;
		/* padding: 4px; */
		font-size: 14px;
		height: 19px;
		align-items: center;
		justify-content: center;
		display: inline-flex;
		width: 19px;
		margin-left: 6px;
		color: #fff !important;
		border-radius: 50%;
	}
	.custom__handler {
		padding: 0.5rem 1rem;
		display: block;
	}
	.nav-link {
		display: block;
		padding: 0 !important;
	}
	@media (max-width: 585px) {
		nav {
			white-space: nowrap;
			overflow-x: auto;
			margin: 0px auto;
			display: inline-flex;
			flex-wrap: nowrap;
		}
	}
	@media (max-width: 989px) {
		nav {
			white-space: nowrap;
			overflow-x: auto;
			margin: 0px auto;
			display: inline-flex;
			flex-wrap: nowrap;
		}
	}
	@media (max-width: 220px) {
		nav {
			white-space: nowrap;
			overflow-x: auto;
			margin: 0px auto;
			display: inline-flex;
			flex-wrap: nowrap;
		}
	}
	.tab-content {
		padding: 20px 0;
	}

	.nav-tabs {
		border: none;

		& > a {
			${(props) => generateStyle(props.tabType)};
		}
	}
`;

export const TabItem = styled.div`
	display: block;
`;

TabWrapper.defaultProps = defaultProps;

TabWrapper.propTypes = propTypes;

export default TabWrapper;
