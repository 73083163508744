/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'formik';
import { isEmpty } from 'codewonders-helpers';
import { createStructuredSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

/* -------------------------- Internal Dependencies ------------------------- */
import { getHours, getMinutes, getSeconds } from 'utils';
import RichTextEditor from 'components/rich-text-editor';
import Input from 'components/input';
import {
	deleteQuestionOption,
	updateQuestionOption,
} from 'redux/developp-tests/actions/create-test';
import { selectCurrentQuestion } from 'redux/developp-tests/selector';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Close } from 'assets/icons/ic_round-close.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';

/* --------------------------- Styles Dependencies -------------------------- */
import {
	DescriptionContainer,
	H3,
	Description,
	OptionInputContainer,
	AddOptionContainer,
	CompletionTimeHeader,
	SelectGroupContainer,
	SelectContainer,
	CodeSelectContainer,
} from '../styles/question';

/* -------------------- MultipleChoiceContainer propTypes ------------------- */
const propTypes = {
	question: PropTypes.object,
	deleteQuestionOption: PropTypes.func,
	updateQuestionOption: PropTypes.func,
	handleChange: PropTypes.func,
	changeValue: PropTypes.func,
	setFieldValue: PropTypes.func,
	handleBlur: PropTypes.func,
	values: PropTypes.object,
	timeError: PropTypes.string,
	errors: PropTypes.any,
	touched: PropTypes.any,
};

const MultipleChoiceContainer = ({
	handleChange,
	changeValue,
	setFieldValue,
	handleBlur,
	values,
	timeError,
	errors,
	touched,
	question,
	deleteQuestionOption,
}) => {
	const hour = getHours();
	const minute = getMinutes();
	const second = getSeconds();

	const dispatch = useDispatch();
	const updateOption = useCallback(
		(data) => dispatch(updateQuestionOption(data)),
		[dispatch]
	);

	return (
		<>
			<div className="row">
				<div className="col-md-6">
					<DescriptionContainer>
						<H3>Question</H3>
						<Description>Enter Question</Description>
					</DescriptionContainer>
				</div>

				<div className="col-md-6">
					<RichTextEditor
						hasStrip
						id="description"
						name="description"
						onBlur={(e) =>
							handleBlur({
								target: { value: e, name: 'description', id: 'description' },
							})
						}
						label=""
						placeholder="Write question here"
						value={values.description}
						onChange={(e) => {
							handleChange({
								target: { value: e, name: 'description', id: 'description' },
							});
							changeValue('description', e);
						}}
						errorMessage={errors.description}
						isInvalid={errors.description && touched.description}
					/>
				</div>

				<div className="col-md-6 mb-4">
					<DescriptionContainer>
						<H3>Configure</H3>
						<Description>
							Set skills, expected time for task completion and number of
							points.
						</Description>
					</DescriptionContainer>
				</div>
				<div className="col-md-6">
					<Input
						hasStrip
						id="points"
						name="points"
						type="number"
						onBlur={handleBlur}
						label="Number of points"
						placeholder="Enter points"
						value={values.points}
						onChange={(e) => {
							handleChange(e);
							changeValue('points', e.target.value);
						}}
						errorMessage={errors.points}
						isInvalid={errors.points && touched.points}
					/>
				</div>
				<div className="col-md-6">
					<DescriptionContainer>
						<H3>Options</H3>
						<Description>
							{values.options_type === 'single-choice'
								? 'Add answer options and tick the correct answer'
								: 'Add answer options and their point of correctness (NB: this points should not be more than the Number of points)'}
						</Description>
					</DescriptionContainer>
				</div>
				<div className="col-md-6">
					<CompletionTimeHeader>Option Type</CompletionTimeHeader>

					<CodeSelectContainer className="w-100 mt-2">
						<Field
							name="options_type"
							as="select"
							onChange={(e) => {
								setFieldValue('options_type', e.target.value);
								changeValue('options_type', e.target.value);
							}}
							disabled={values.points < 1}
						>
							<option value="" disabled>
								--Option Type--
							</option>
							<option value="single-choice">Single Choice</option>
							<option value="multiple-choice">Viable Answers</option>
						</Field>
						<ArrowDown />
					</CodeSelectContainer>

					{values.options_type === 'single-choice' ? (
						<FieldArray
							name="options"
							render={(arrayHelpers) => (
								<div>
									{values.options &&
										values.options.map((option, index) => (
											<OptionInputContainer key={index}>
												<fieldset id="options.correct">
													<Field name={`options[${index}].correct`}>
														{({ field, form }) => (
															<input
																id={`options[${index}].correct`}
																type="radio"
																name="options.correct"
																className="custom-radio"
																checked={option?.correct}
																onChange={() => {
																	form.setFieldValue(field.name, !field.value);
																	changeValue(
																		`options`,
																		values.options.map(
																			({ correct, ...opt }, option_index) => {
																				return {
																					...opt,
																					correct:
																						option_index !== index
																							? false
																							: true,
																				};
																			}
																		)
																	);
																}}
																disabled={values.points < 1}
																onBlur={(e) => {}}
															/>
														)}
													</Field>
												</fieldset>
												<Field
													id={`options[${index}].option`}
													type="text"
													className="ml-2"
													name={`options[${index}].option`}
													placeholder="Enter option"
													disabled={values.points < 1}
													onBlur={() => {
														if (values.options[index]?.id) {
															updateOption(option);
														}
														changeValue('options', values.options);
													}}
												/>
												{values.options.length === 1 ? (
													<div />
												) : (
													<Close
														disabled={values.points < 1}
														onClick={() => {
															arrayHelpers.remove(index);
															if (question && !isEmpty(option) && option.id) {
																deleteQuestionOption(option.id, index);
															}

															return changeValue(
																'options',
																values.options.filter(
																	(val, indx) => indx !== index
																)
															);
														}}
													/>
												)}
											</OptionInputContainer>
										))}
									<AddOptionContainer
										onClick={() => {
											arrayHelpers.push('');
										}}
									>
										<Plus /> <span>Add answer option</span>
										<span
											className="d-block mt-2"
											style={{
												color: 'red',
												fontSize: '12px',
											}}
										>
											{errors.options && errors.options.length > 0 ? (
												<>Option is required</>
											) : (
												''
											)}{' '}
											<br />
										</span>
									</AddOptionContainer>
								</div>
							)}
						/>
					) : (
						<FieldArray
							name="options"
							render={(arrayHelpers) => (
								<div className="contain__inputs">
									{values.options &&
										values.options.map((option, index) => (
											<>
												<OptionInputContainer key={index}>
													<Field
														id={`options[${index}].option`}
														type="text"
														className="ml-2"
														name={`options[${index}].option`}
														placeholder="Enter option"
														disabled={values.points < 1}
														onBlur={() => {
															if (values.options[index]?.id) {
																updateOption(option);
															}
															changeValue('options', values.options);
														}}
													/>
													<Field
														id={`options[${index}].points`}
														type="number"
														className="ml-2 mr-2 form-control "
														name={`options[${index}].points`}
														placeholder="Enter point(s)"
														disabled={values.points < 1}
														onChange={(e) => {
															if (e.target.value > values.points) {
																setFieldValue(
																	`options[${index}].points`,
																	values.points
																);
															} else {
																setFieldValue(
																	`options[${index}].points`,
																	e.target.value
																);
															}
														}}
														onBlur={() => {
															if (values.options[index]?.id) {
																updateOption(option);
															}
															changeValue('options', values.options);
														}}
													/>

													{values.options.length === 1 ? (
														<div />
													) : (
														<Close
															disabled={values.points < 1}
															onClick={() => {
																arrayHelpers.remove(index);
																if (question && !isEmpty(option) && option.id) {
																	deleteQuestionOption(option.id, index);
																}
																return changeValue(
																	'options',
																	values.options.filter(
																		(val, indx) => indx !== index
																	)
																);
															}}
														/>
													)}
												</OptionInputContainer>
												{values?.options[index]?.points > values.points && (
													<span
														className="d-block"
														style={{
															color: 'red',
															fontSize: '12px',
															margin: '-9px 13px 4px',
														}}
													>
														({values?.options[index]?.points}) point(s) cannot
														be grater than total number of points (
														{values.points})
													</span>
												)}
											</>
										))}
									<AddOptionContainer
										onClick={() => {
											arrayHelpers.push('');
										}}
									>
										<Plus /> <span>Add answer option</span>
										<span
											className="d-block mt-2"
											style={{
												color: 'red',
												fontSize: '12px',
											}}
										>
											{errors.options && errors.options.length > 0 ? (
												<>Option is required</>
											) : (
												''
											)}{' '}
											<br />
										</span>
									</AddOptionContainer>
								</div>
							)}
						/>
					)}
				</div>
				<div className="col-md-6">
					<DescriptionContainer>
						<H3>Completion time</H3>
						<Description>
							Set hours, minutes and seconds expected to complete this question.
						</Description>
					</DescriptionContainer>
				</div>
				<div className="col-md-6">
					<CompletionTimeHeader>Expected completion time</CompletionTimeHeader>
					<SelectGroupContainer>
						<SelectContainer questionType={'mutiple'}>
							<Field
								name="hours"
								as="select"
								onChange={(e) => {
									setFieldValue('hours', e.target.value);
									changeValue('hours', e.target.value);
								}}
							>
								<option value="">Hours</option>
								{hour.map((hr, index) => (
									<option key={index} value={hr.value}>
										{hr.text}
									</option>
								))}
							</Field>
							<ArrowDown />
						</SelectContainer>
						<SelectContainer questionType={'mutiple'}>
							<Field
								name="minutes"
								as="select"
								onChange={(e) => {
									setFieldValue('minutes', e.target.value);
									changeValue('minutes', e.target.value);
								}}
							>
								<option value="">Minutes</option>
								{minute.map((min, index) => (
									<option key={index} value={min.value}>
										{min.text}
									</option>
								))}
							</Field>
							<ArrowDown />
						</SelectContainer>
						<SelectContainer questionType={'mutiple'}>
							<Field
								name="seconds"
								as="select"
								onChange={(e) => {
									setFieldValue('seconds', e.target.value);
									changeValue('seconds', e.target.value);
								}}
							>
								<option value="">Seconds</option>
								{second.map((sec, index) => (
									<option key={index} value={sec.value}>
										{sec.text}
									</option>
								))}
							</Field>
							<ArrowDown />
						</SelectContainer>
					</SelectGroupContainer>
					<span className="d-block" style={{ color: 'red', fontSize: '12px' }}>
						{timeError ||
							(values.hours === '00' &&
								values.minutes === '00' &&
								values.seconds === '00' && <>Time cannot be 00:00:00</>)}
						{touched.hours && errors.hours} <br />
						{touched.minutes && errors.minutes} <br />
						{touched.seconds && errors.seconds}
					</span>
				</div>
			</div>
		</>
	);
};

MultipleChoiceContainer.propTypes = propTypes;

const mapStateToProps = createStructuredSelector({
	question: selectCurrentQuestion,
});

export default connect(mapStateToProps, {
	deleteQuestionOption,
	updateQuestionOption,
})(MultipleChoiceContainer);
