/* eslint-disable import/no-dynamic-require */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import isEmpty from 'codewonders-helpers/bundle-cjs/helpers/is-empty';

/* -------------------------- Internal Dependencies ------------------------- */
import {
	multipleChoiceValidationSchema,
	codingChoiceValidationSchema,
} from 'utils/validation-schema';

import {
	addOrUpdateQuestion,
	getTestQuestionById,
	newQuestion,
	getSegments,
} from 'redux/developp-tests/actions/create-test';
import Modal from './components/modal';
import { getTestById, getTestQuestions } from 'redux/developp-tests/actions';
import {
	selectisLoading,
	selectCurrentQuestion,
	selectQuestionType,
	selectCodingLanguages,
	selectCurrentQuestionStatus,
} from 'redux/developp-tests/selector';
import Loader from 'components/loader';
import NavLayout from 'components/layout/create-test-layout/navbar';
import { getCodingLanguageID } from 'utils';
import MultipleChoiceContainer from './components/multiple-choice';
import CodingQuestionContainer from './components/coding-question';
import {
	SET_CURRENT_QUESTION,
	GET_CURRENT_QUESTION,
	CLEAR_CURRENT_QUESTION,
} from './components/config';

/* ---------------------------- Style Dependency ---------------------------- */
import { Container } from './styles/question';

/* --------------------------- Question propTypes --------------------------- */
const propTypes = {
	isLoading: PropTypes.bool,
	question: PropTypes.object,
	questionType: PropTypes.string,
	questionStatus: PropTypes.bool,
	getTestQuestionById: PropTypes.func,
	getTestQuestions: PropTypes.func,
	coding_languages: PropTypes.array,
	newQuestion: PropTypes.func,
	getSegments: PropTypes.func,
};

const Question = ({
	isLoading,
	question,
	questionType,
	coding_languages,
	questionStatus,
	newQuestion,

	getTestQuestionById: loadQuestionById,
}) => {
	const dispatch = useDispatch();
	const submitQuestion = useCallback(
		(data) => dispatch(addOrUpdateQuestion(data)),
		[dispatch]
	);

	const [showModal, toggleModal] = useState(false);
	const [timeError, setError] = useState('');

	const [state, setStateValue] = useState({
		description:
			question?.description || GET_CURRENT_QUESTION().description || '',
		selected: GET_CURRENT_QUESTION().selected || null,
		theme: 'monokai',
		mode:
			question?.language && !isEmpty(question?.language)
				? question?.language?.name?.toLowerCase() || ''
				: GET_CURRENT_QUESTION().mode || 'python',
		stdin: question?.stdin?.split('\n') ||
			GET_CURRENT_QUESTION()?.stdin || ['\n'],
		options_type:
			question?.options_type ||
			GET_CURRENT_QUESTION()?.options_type ||
			'single-choice',
		hours: question?.hours || GET_CURRENT_QUESTION()?.hours || '',
		minutes: question?.minutes || GET_CURRENT_QUESTION()?.minutes || '',
		seconds: question?.seconds || GET_CURRENT_QUESTION()?.seconds || '',
		options: question?.options ||
			GET_CURRENT_QUESTION()?.options || [{ option: '', correct: true }],
		points: question?.points || GET_CURRENT_QUESTION()?.points || '',
		code_snippet:
			question?.code_snippet || GET_CURRENT_QUESTION()?.code_snippet || '',
		expected_output:
			question?.expected_output ||
			GET_CURRENT_QUESTION()?.expected_output ||
			'',
	});

	const changeValue = (key, val) => {
		setStateValue((prev) => {
			return { ...prev, [key]: val };
		});
		if (isEmpty(question)) {
			return SET_CURRENT_QUESTION({
				...GET_CURRENT_QUESTION(),
				[key]: val,
			});
		}
	};

	const { id, question: questionId, segmentId } = useParams();
	const getQuestionData = useCallback(async () => {
		const data = await loadQuestionById(questionId, id);
		setStateValue((prev) => {
			return {
				...prev,
				description: data?.description,
				selected: null,
				theme: 'monokai',
				mode:
					data?.language && !isEmpty(data?.language)
						? data?.language?.name?.toLowerCase() || ''
						: 'python',
				stdin: data?.stdin?.split('\n'),
				options_type: data?.options_type || 'single-choice',
				hours: data?.hours,
				minutes: data?.minutes,
				seconds: data?.seconds,
				options: data?.options,
				points: data?.points,
				code_snippet: data?.code_snippet,
				expected_output: data?.expected_output,
			};
		});
		// eslint-disable-next-line
	}, [loadQuestionById, questionId, id]);
	useEffect(() => {
		if (questionId === 'new') {
			newQuestion();
		}
	}, [questionId, newQuestion]);

	useEffect(() => {
		if (id && questionId !== 'new') {
			getQuestionData();
		}
		// eslint-disable-next-line
	}, [id, questionId, getQuestionData]);

	return (
		<>
			{!isLoading ? (
				<Container>
					<div className="row  justify-content-center mb-8">
						<div className="col-md-9">
							<Formik
								enableReinitialize
								initialValues={state}
								validationSchema={
									questionType === 'multiple'
										? multipleChoiceValidationSchema
										: codingChoiceValidationSchema
								}
								onSubmit={async ({
									description,
									hours,
									minutes,
									seconds,
									options,
									points,
									stdin,
									mode,
									options_type,
									code_snippet,
									expected_output,
								}) => {
									let data = {
										points,
										description,
										time: `${hours}:${minutes}:${seconds}`,
										options_type,
										segment: segmentId,
									};

									if (questionType === 'multiple') {
										if (options_type === 'single-choice') {
											data = {
												...data,
												options: options.map(({ points, ...rest }) => rest),
											};
										} else {
											if (
												!isEmpty(options.filter((op) => op.points < 0)) ||
												options.filter((opt) => opt.hasOwnProperty('points'))
													.length < options.length
											) {
												return alert(`Please assign points to options`);
											} else {
												data = {
													...data,
													options: options.map(({ correct, ...rest }) => rest),
												};
											}
										}
										if (data.time === '00:00:00') {
											return setError('Time cannot be 00:00:00');
										}

										await submitQuestion(data);

										CLEAR_CURRENT_QUESTION();
										setError('');
									}

									if (questionType === 'coding') {
										data = {
											description,
											time: `${hours}:${minutes}:${seconds}`,
											code_snippet,
											stdin: stdin.join('\n'),
											coding: true,
											language: {
												id: getCodingLanguageID(mode),
												name: mode,
											},
											segment: segmentId,
											points,
											expected_output,
										};

										if (data.time === '00:00:00') {
											return setError('Time cannot be 00:00:00');
										}

										await submitQuestion(data);
										CLEAR_CURRENT_QUESTION();
										setError('');
									}
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									setFieldValue,
								}) => (
									<>
										<NavLayout
											title="Create"
											handleSave={handleSubmit}
											handlePublish={() => toggleModal(!showModal)}
										/>
										<Modal
											show={showModal}
											closeModal={() => toggleModal(!showModal)}
											testStatus={questionStatus}
										/>
										<Form onSubmit={handleSubmit}>
											{questionType === 'multiple' ? (
												<MultipleChoiceContainer
													handleChange={handleChange}
													changeValue={changeValue}
													setFieldValue={setFieldValue}
													handleBlur={handleBlur}
													values={values}
													timeError={timeError}
													errors={errors}
													touched={touched}
												/>
											) : (
												<CodingQuestionContainer
													handleChange={handleChange}
													changeValue={changeValue}
													setFieldValue={setFieldValue}
													handleBlur={handleBlur}
													values={values}
													timeError={timeError}
													errors={errors}
													touched={touched}
												/>
											)}
										</Form>
									</>
								)}
							</Formik>
						</div>
					</div>
				</Container>
			) : (
				<Loader loadingText="Loading" />
			)}
		</>
	);
};

Question.propTypes = propTypes;
const mapStateToProps = createStructuredSelector({
	question: selectCurrentQuestion,
	isLoading: selectisLoading,
	questionType: selectQuestionType,
	questionStatus: selectCurrentQuestionStatus,
	coding_languages: selectCodingLanguages,
});

export default connect(mapStateToProps, {
	addOrUpdateQuestion,
	getTestQuestions,
	newQuestion,
	getTestQuestionById,
	getTestById,
	getSegments,
})(Question);
