/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, getState } from 'codewonders-helpers';
import { Dropdown } from 'react-bootstrap';

/* -------------------------- Internal Dependencies ------------------------- */
import Button from 'components/button';
import {
	selectQuestion,
	deleteQuestion,
	deleteSegmentById,
	addSegment,
	moveSegment,
	getSegments,
} from 'redux/developp-tests/actions/create-test';
import Modal from 'components/modal';
import history from 'redux/history';
import {
	SET_CURRENT_QUESTION,
	GET_CURRENT_QUESTION,
	CLEAR_CURRENT_QUESTION,
} from 'pages/dashboard/new-test/components/config';
import AccordionSlate, {
	AccordionHeader,
	AccordionCollapse,
} from './components/accordion-slate';
import DragComponent from './components/drag-component';
import { numtoWords } from 'utils';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Chat } from 'assets/icons/chat.svg';
import { ReactComponent as Brackets } from 'assets/icons/brackets.svg';
import { ReactComponent as Add } from 'assets/icons/ic_round-add.svg';
import { ReactComponent as MultiChoice } from 'assets/icons/multichoice.svg';
import { ReactComponent as More } from '../../../assets/icons/more.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import { ReactComponent as Move } from '../../../assets/icons/icon-move.svg';
import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import { ReactComponent as EmptyStateImage } from 'assets/icons/empty-state.svg';
/* --------------------------- Styles Dependencies -------------------------- */
import {
	ButtonContainer,
	Container,
	Item,
	ItemContainer,
	Items,
	MultiChoiceItem,
	MultiChoiceItemcontainer,
	StyledButton,
} from './styles/sidebar';
import useMount from 'utils/useMount';
import { getTestById, getTestQuestions } from 'redux/developp-tests/actions';

/* -------------------- CreateTestSidebarLayout propTypes ------------------- */
const propTypes = {
	hidden: PropTypes.bool,
	dispatch: PropTypes.func,
	current_questions: PropTypes.object,
	current_question: PropTypes.object,
	current_test: PropTypes.any,
	location: PropTypes.object,
	segments: PropTypes.array,
	getSegments: PropTypes.func,
	deleteSegmentById: PropTypes.func,
	addSegment: PropTypes.func,
};

const CreateTestSidebarLayout = ({
	dispatch,
	hidden: sideBarHidden,
	location,
	current_questions,
	current_test,
	segments,
}) => {
	const [isToggled, togglePopover] = useState(false);
	const [show, toggleSaveModal] = useState(false);

	const [showSegment, toggleDeleteModal] = useState({
		show: false,
		title: null,
		id: null,
	});
	const [showQuestion, toggleDeleteQuestion] = useState({
		show: false,
		id: null,
	});

	const questId = location?.pathname?.split('/')[4];
	const segmentId = location?.pathname?.split('/')[5];
	const slugId = location?.pathname?.split('/')[3];

	const requestBeforeLeave = useCallback((e) => {
		const dialogText = 'Are you sure you want to close the Window?';
		e.returnValue = dialogText;
		return dialogText;
	}, []);

	const segmentIndex = {};

	const getQuestionNumber = (id, questionId) => {
		if (id in segmentIndex) {
			segmentIndex[id] += 1;
			segmentIndex[questionId] = segmentIndex[id];
		} else {
			segmentIndex[id] = 1;
			segmentIndex[questionId] = segmentIndex[id];
		}

		return segmentIndex[id];
	};

	useEffect(() => {
		window.addEventListener('beforeunload', requestBeforeLeave);

		return () => {
			window.removeEventListener('beforeunload', requestBeforeLeave);
		};
	}, [requestBeforeLeave]);

	useMount(() => {
		if (slugId) {
			if (isEmpty(segments) || isEmpty(current_questions)) {
				Promise.all([
					dispatch(getTestQuestions(slugId)),
					dispatch(getSegments(slugId)),
				]);
			}
			if (isEmpty(current_test)) {
				dispatch(getTestById(slugId));
			}
		}
	});
	return (
		<Container sideBarHidden={sideBarHidden}>
			<div className="side__bar-items">
				<Items exact to="" />
				<Items
					onClick={() => {
						dispatch(selectQuestion({ question: '' }));

						return history.push(`/edit/test/${current_test?.slug || slugId}`);
					}}
					className={`${!questId ? 'active__question' : ''}`}
				>
					<Info />
					<Item>Test Details</Item>
				</Items>
				<div>
					<AccordionSlate
						defaultKey={segmentId || (!isEmpty(segments) && segments[0]?.id)}
					>
						{/* {isEmpty(segments) && location.pathname === '/create/test' ? (
							<div className="d-flex empited ">
								<div>
									<EmptyStateImage />
									<p className="text-center">
										No Segements yet add a new segment to add questions.
									</p>
								</div>
							</div>
						) : null} */}
						<>
							<>
								{' '}
								{!isEmpty(segments) ? (
									segments?.map((segment, segindex) => (
										<>
											<AccordionHeader
												eventKey={segment?.id}
												item={{
													name: `Segment ${numtoWords(segindex + 1)}`,
													id: segment?.id,
												}}
												key={segindex}
											>
												<p>Segment {numtoWords(segindex + 1)}</p>
												<Dropdown className="float-right more__icon">
													<Dropdown.Toggle as={More} className="icons" />

													<Dropdown.Menu>
														<Dropdown.Item
															href="#"
															onClick={() => {
																toggleDeleteModal((prev) => {
																	return {
																		...prev,
																		title: segment.name,
																		id: segment.id,
																		show: true,
																	};
																});
															}}
														>
															<Delete className="dropd-icon mr-2" /> Delete
															Segment
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</AccordionHeader>

											<AccordionCollapse eventKey={segment?.id}>
												<>
													<div className="margin-left">
														{current_questions.results &&
															current_questions.results.map(
																(current_question, index) => {
																	const isCoding = current_question.coding;

																	return (
																		<>
																			{current_question?.segment?.id ===
																				segment?.id && (
																				<MultiChoiceItemcontainer
																					key={index}
																					className={`${
																						current_question?.id === questId
																							? 'active_question'
																							: ''
																					}`}
																				>
																					<DragComponent
																						className="w-100 h-100 button__wrap"
																						dispatch={dispatch}
																						slug={slugId}
																						items={{
																							name:
																								'Question ' +
																								getQuestionNumber(
																									segment.id,
																									current_question?.id
																								),
																							segment:
																								'Segment ' +
																								numtoWords(segindex + 1),
																							questId: current_question?.id,
																						}}
																						onClick={() => {
																							history.push(
																								`/edit/test/${current_test?.slug ||
																									slugId}/${
																									current_question?.id
																								}/${
																									current_question?.segment?.id
																								}/${
																									segmentIndex[
																										current_question?.id
																									]
																								}?segment=${segindex + 1}`
																							);
																							dispatch(
																								selectQuestion({
																									question: isCoding
																										? 'coding'
																										: 'multiple',
																									question_id:
																										current_question?.id,
																								})
																							);
																						}}
																					>
																						{isCoding ? (
																							<Brackets className="margin__left" />
																						) : (
																							<MultiChoice className="margin__left" />
																						)}

																						<MultiChoiceItem>
																							{`Question ${
																								segmentIndex[
																									current_question?.id
																								]
																							}`}{' '}
																						</MultiChoiceItem>
																					</DragComponent>
																					<Dropdown className="float-right more__icon">
																						<Dropdown.Toggle
																							as={More}
																							className="icons"
																						/>

																						<Dropdown.Menu>
																							<Dropdown.Item
																								href="#!"
																								onClick={(e) => {
																									e.preventDefault();

																									toggleDeleteQuestion(
																										(prev) => {
																											return {
																												...prev,
																												show: true,
																												id:
																													current_question?.id,
																											};
																										}
																									);
																								}}
																							>
																								<Delete className="dropd-icon mr-2" />{' '}
																								Delete question
																							</Dropdown.Item>
																							<li className="dropdown-submenu">
																								<Dropdown.Item
																									onClick={(e) => {
																										e.stopPropagation();
																										e.preventDefault();
																									}}
																									className="dropbtn"
																								>
																									<Move className="dropd-icon mr-2" />{' '}
																									Move question
																								</Dropdown.Item>

																								<div className="dropdown-content">
																									{!isEmpty(segments) &&
																										segments.map(
																											(segmentNames, seg) => {
																												return (
																													<>
																														{segmentNames.id !==
																															current_question
																																?.segment
																																?.id && (
																															<a
																																href="#!"
																																onClick={() =>
																																	dispatch(
																																		moveSegment(
																																			slugId,
																																			current_question?.id,
																																			{
																																				segment:
																																					segmentNames?.id,
																																			},
																																			{
																																				questionNum:
																																					segmentIndex[
																																						current_question
																																							?.id
																																					],
																																				segmentNum:
																																					seg +
																																					1,
																																			}
																																		)
																																	)
																																}
																															>
																																Segment{' '}
																																{numtoWords(
																																	seg + 1
																																)}
																															</a>
																														)}
																													</>
																												);
																											}
																										)}
																								</div>
																							</li>
																						</Dropdown.Menu>
																					</Dropdown>
																				</MultiChoiceItemcontainer>
																			)}
																		</>
																	);
																}
															)}
													</div>
													<Dropdown
														show={isToggled}
														onToggle={() => {
															if (
																getState('CURRENT_TEST') ||
																getState('CURRENT_QUESTION') ||
																isEmpty(current_test)
															) {
																return toggleSaveModal(!show);
															}
															return togglePopover(!isToggled);
														}}
													>
														<Dropdown.Toggle
															as={StyledButton}
															className="button btn-developp btn-block"
														>
															{' '}
															<Add />
															Add Questions
														</Dropdown.Toggle>

														<Dropdown.Menu className="add__questions p-0">
															<Dropdown.Item
																as={ItemContainer}
																eventKey="1"
																onClick={async () => {
																	await dispatch(
																		selectQuestion({
																			question: 'multiple',
																			question_id: 'new',
																		})
																	);
																	await SET_CURRENT_QUESTION({
																		...GET_CURRENT_QUESTION(),
																		segment: segment?.id,
																		questionType: 'multiple',
																	});
																	history.push(
																		`/edit/test/${current_test?.slug ||
																			slugId}/new/${segment?.id}`
																	);
																}}
															>
																<Chat />
																<Item>Multiple Choice</Item>
															</Dropdown.Item>
															<Dropdown.Item
																as={ItemContainer}
																eventKey="2"
																onClick={async () => {
																	await dispatch(
																		selectQuestion({
																			question: 'coding',
																			question_id: 'new',
																		})
																	);
																	await SET_CURRENT_QUESTION({
																		...GET_CURRENT_QUESTION(),
																		segment: segment?.id,
																		questionType: 'coding',
																	});
																	history.push(
																		`/edit/test/${current_test?.slug ||
																			slugId}/new/${segment?.id}`
																	);
																}}
															>
																<Brackets />
																<Item>Coding Task</Item>
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</>
											</AccordionCollapse>
										</>
									))
								) : segments?.length === 0 ? (
									<div className="d-flex empited ">
										<div>
											<EmptyStateImage />
											<p className="text-center">
												No Segements yet add a new segment to add questions.
											</p>
										</div>
									</div>
								) : (
									<Loading className="w-25" />
								)}
							</>
						</>
					</AccordionSlate>
				</div>

				<ButtonContainer>
					<button
						className="button btn-developp btn-block"
						onClick={() => {
							dispatch(
								addSegment(slugId, {
									name: `Segment ${numtoWords(segments?.length + 1)}`,
								})
							);
						}}
						disabled={isEmpty(current_test)}
					>
						<Add /> Add new segment
					</button>
				</ButtonContainer>
				<Modal
					title={
						getState('CURRENT_TEST')
							? 'Save Test'
							: getState?.('CURRENT_QUESTION')
							? 'Save Question'
							: null
					}
					show={show}
					closeModal={() => toggleSaveModal(!show)}
				>
					<div>
						{getState?.('CURRENT_TEST') ? (
							<>Please save this test before adding questions to it.</>
						) : getState?.('CURRENT_QUESTION') ? (
							<>
								You currently have an unsaved question please save it before
								adding more questions{' '}
								<a
									onClick={() => {
										dispatch(
											selectQuestion({
												question: GET_CURRENT_QUESTION().questionType,
												question_id: 'new',
											})
										);
										history.push(
											`/edit/test/${current_test?.slug || slugId}/new/${
												GET_CURRENT_QUESTION().segment
											}`
										);
									}}
									href="#0"
								>
									finish creating this question here
								</a>
								<Button
									className="btn-developp mt-3 mb-auto"
									onClick={() => {
										CLEAR_CURRENT_QUESTION();
										dispatch(
											selectQuestion({
												question: '',
											})
										);
										history.push(`/edit/test/${current_test?.slug || slugId}`);
									}}
									style={{ background: '#ff4949', padding: '0.785rem 2rem' }}
								>
									Trash Question
								</Button>
								<Button
									className="btn-developp mt-3 mb-auto"
									onClick={() => {
										dispatch(
											selectQuestion({
												question: GET_CURRENT_QUESTION().questionType,
												question_id: 'new',
											})
										);
										history.push(
											`/edit/test/${current_test?.slug || slugId}/new/${
												GET_CURRENT_QUESTION().segment
											}`
										);
									}}
								>
									Finish Creating Question
								</Button>
							</>
						) : null}
					</div>
				</Modal>
				<Modal
					title={`Warning: Delete ${showSegment?.title}`}
					show={showSegment.show}
					closeModal={() =>
						toggleDeleteModal((prev) => {
							return { ...prev, id: null, show: false };
						})
					}
				>
					<div>
						<p>
							Please be warned that when you delete a segment you delete its
							associating questions.
						</p>
						<Button
							className="btn-developp mt-3 mb-auto"
							onClick={() => {
								dispatch(deleteSegmentById(slugId, showSegment?.id));
								toggleDeleteModal((prev) => {
									return { ...prev, id: null, show: false };
								});
							}}
							style={{ background: '#ff4949', padding: '0.785rem 2rem' }}
						>
							Delete Segment
						</Button>
					</div>
				</Modal>
				<Modal
					title={`Warning: Delete Question`}
					show={showQuestion.show}
					closeModal={() =>
						toggleDeleteQuestion((prev) => {
							return { ...prev, id: null, show: false };
						})
					}
				>
					<div>
						<p>Please be warned that you are about to delete this question.</p>
						<Button
							className="btn-developp mt-3 mb-auto"
							onClick={() => {
								dispatch(deleteQuestion(showQuestion?.id));
								toggleDeleteQuestion((prev) => {
									return { ...prev, id: null, show: false };
								});
							}}
							style={{ background: '#ff4949', padding: '0.785rem 2rem' }}
						>
							Delete Question
						</Button>
					</div>
				</Modal>
			</div>
		</Container>
	);
};

CreateTestSidebarLayout.propTypes = propTypes;

const mapStateToProps = (state) => ({
	test_details: state.tests.test_details,
	current_question: state.tests.current_question,
	current_questions: state.tests.current_questions,
	current_test: state.tests.current_test,
	hidden: state.user.hidden,
	segments: state.tests.segments,
});
export default connect(
	mapStateToProps,
	null
)(withRouter(CreateTestSidebarLayout));
