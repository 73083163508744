/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

/* -------------------------- Internal Dependencies ------------------------- */
import { getMoreTests } from 'redux/developp-tests/actions';
import { withRouter } from 'react-router-dom';
import TestCard from 'components/card/test-card';
import OverlayLoader from 'components/loader/overlay';
import { createGlobalStyle } from 'styled-components';
import { ActionCards } from '..';

/* --------------------------- TestItems propTypes -------------------------- */
const propTypes = {
	data: PropTypes.array.isRequired,
	count: PropTypes.number,
	getMoreTests: PropTypes.func,
	query: PropTypes.array,
};

const TestItems = ({ data, count, getMoreTests: loadMoreTests, query }) => {
	const [hasReached, sethasReached] = useState(false);
	const [overlayLoading, setOverlayLoading] = useState(false);

	const scrollWindow = useCallback(() => {
		const d = document.documentElement;
		const offset = d.scrollTop + window.innerHeight;
		const height = d.offsetHeight - 50;
		if (offset >= height && data.length !== count) {
			sethasReached(false);
			return loadMoreTests(data.length, 5, query);
		}
		return sethasReached(true);
	}, [data.length, query, loadMoreTests, count]);
	const getTest = async () => {
		setOverlayLoading(!overlayLoading);
		if (data.length !== count) {
			sethasReached(false);

			await loadMoreTests(data.length, 5, query);
			return setOverlayLoading(false);
		}

		sethasReached(true);
		return setOverlayLoading(false);
	};

	const handleScroll = debounce(async () => {
		setOverlayLoading(!overlayLoading);
		await scrollWindow();
		setOverlayLoading(false);
	}, 100);

	useEffect(
		function setupListener() {
			window.addEventListener('scroll', handleScroll);

			return function cleanupListener() {
				window.removeEventListener('scroll', handleScroll);
			};
		},
		[handleScroll]
	);
	return (
		<>
			<OverlayLoader
				loading={overlayLoading}
				loadingText="Getting More Tests..."
			/>
			<Body loading={overlayLoading} />
			<div className="grid">
				{data &&
					data.map((card_data, index) => (
						<TestCard key={index} data={card_data} />
					))}
				{hasReached ? (
					<ActionCards> You have reached the end </ActionCards>
				) : (
					<ActionCards className="active" onClick={() => getTest()}>
						Get More Tests
					</ActionCards>
				)}
			</div>
		</>
	);
};

TestItems.propTypes = propTypes;
const Body = createGlobalStyle`

body{
  overflow: ${({ loading }) => loading && 'hidden'} 
}`;
export default connect(null, { getMoreTests })(withRouter(TestItems));
