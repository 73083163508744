/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* -------------------------- Internal Dependencies ------------------------- */
import { trunc } from 'utils';
import Card from 'components/card';
import { countTasks, pluralize } from 'utils/pluralize';

/* --------------------------- Image dependencies --------------------------- */
import { ReactComponent as Time } from '../../assets/icons/timer.svg';
import { ReactComponent as Tasks } from '../../assets/icons/tasks.svg';
import { ReactComponent as CodeTag } from '../../assets/icons/code-tag.svg';
import { ReactComponent as BehaveTag } from '../../assets/icons/behaviour-tag.svg';
import { ReactComponent as JungTag } from '../../assets/icons/jung-tag.svg';

/* --------------------------- TestCard PropTypes --------------------------- */
const proptypes = {
	data: PropTypes.any,
	isMyTests: PropTypes.bool,
	onClick: PropTypes.func,
};

export const testTime = (total_time) => {
	return `${total_time?.split(':')[2] > 1 ? '~ ' : ''}${
		total_time?.split(':')[0]
	} hr  ${total_time?.split(':')[1]} mins`;
};

const TestCard = React.memo(({ data, isMyTests = false, onClick }) => {
	return (
		<CardContainer key={data.id} onClick={onClick} data-testid="test-card">
			<Card minHeight="300px">
				{data.category.name === 'Technical' ? (
					<CodeTag className="tags__card" />
				) : data.category.name === 'Personality' ? (
					<JungTag className="tags__card" />
				) : (
					<BehaveTag className="tags__card" />
				)}
				<Link to={`/tests/details/${data.slug}`}>
					<h4>{trunc(data.title, 30)}</h4>
				</Link>
				{isMyTests ? (
					<strong className={`${data.published ? 'published' : 'draft'}`}>
						{data.published ? 'Published' : 'Draft'}
					</strong>
				) : (
					<>{data.essential && <strong className="draft">Essential</strong>}</>
				)}
				<p>{data.category.name}</p>
				<div className="other__action">
					<span>
						<Time />{' '}
						{data?.total_time && data?.slug !== 'jung-personality-test' ? (
							<>{testTime(data?.total_time)}</>
						) : (
							'No time yet'
						)}
					</span>
					<span>
						<Tasks />
						{data?.slug === 'jung-personality-test' ? (
							'20'
						) : (
							<>{countTasks(data)}</>
						)}{' '}
						Task{pluralize(countTasks(data))}
					</span>
				</div>
			</Card>
		</CardContainer>
	);
});
const CardContainer = styled.div`
	cursor: pointer;
	strong {
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 17px;
		padding: 10px 17px;
		position: absolute;
		top: 24px;
		right: 14px;
		/* identical to box height */

		text-align: center;

		&.draft {
			color: #3e4857;
			background: rgba(62, 72, 87, 0.05);
			border-radius: 50px;
		}
		&.published {
			color: #219653;
			background: rgba(33, 150, 83, 0.05);
			border-radius: 50px;
		}
		&.draft {
			color: #3e4857;
			background: rgba(62, 72, 87, 0.05);
			border-radius: 50px;
		}
	}
`;

TestCard.propTypes = proptypes;

export default TestCard;
