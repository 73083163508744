/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import { signUpUser } from 'redux/user/actions';
import { getFirstAndLastName } from 'utils';
import { selectisLoading } from 'redux/user/selector';
import AuthLayout, {
	AuthStylingLayoutChildren,
} from '../../components/layout/auth-layout';
import { registerValdationSchema } from 'utils/validation-schema';
import Input from 'components/input';
import Button from 'components/button';
import SEO from 'components/seo';

/* --------------------------- Register propTypes --------------------------- */
const propTypes = {
	isLoading: PropTypes.bool,
	dispatch: PropTypes.func,
};

const Register = ({ dispatch, isLoading }) => {
	return (
		<AuthLayout>
			<SEO title="Create account" />
			<AuthStylingLayoutChildren>
				<h3>Create account</h3>
				<p className="intro__text">Enter your details to get started</p>
				<Formik
					initialValues={{
						name: '',
						email: '',
						password: '',
						confirmPassword: '',
					}}
					validationSchema={registerValdationSchema}
					onSubmit={async ({ name, email, password }) => {
						const { first_name, last_name } = getFirstAndLastName(name);
						const data = {
							first_name,
							last_name,
							email,
							password,
						};
						dispatch(signUpUser?.(data));
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
					}) => (
						<form onSubmit={handleSubmit}>
							<Input
								hasStrip
								id="name"
								name="name"
								type="text"
								onBlur={handleBlur}
								label="Name"
								placeholder="Enter your full name"
								value={values.name}
								onChange={handleChange}
								errorMessage={errors.name}
								isInvalid={errors.name && touched.name}
							/>
							<Input
								hasStrip
								id="email"
								name="email"
								type="email"
								label="Email address"
								placeholder="Enter email address"
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								errorMessage={errors.email}
								isInvalid={errors.email && touched.email}
							/>
							<Input
								hasStrip
								id="password"
								name="password"
								type="password"
								label="Password"
								placeholder="Enter password"
								value={values.password}
								onChange={handleChange}
								onBlur={handleBlur}
								errorMessage={errors.password}
								isInvalid={errors.password && touched.password}
							/>
							<Input
								hasStrip
								id="confirmPassword"
								name="confirmPassword"
								type="password"
								label="Confirm Password"
								placeholder="Confirm Password"
								value={values.confirmPassword}
								onChange={handleChange}
								errorMessage={errors.confirmPassword}
								isInvalid={errors.confirmPassword && touched.confirmPassword}
							/>
							<Button
								type="submit"
								className="btn-developp btn-block"
								isLoading={isLoading}
								disabled={isLoading}
							>
								Create account
							</Button>
						</form>
					)}
				</Formik>
				<p className="intro__link-link-out">
					Already have an account? <Link to="/">Login</Link>
				</p>
			</AuthStylingLayoutChildren>
		</AuthLayout>
	);
};

Register.propTypes = propTypes;

const mapStateToProps = createStructuredSelector({
	isLoading: selectisLoading,
});

export default connect(mapStateToProps)(Register);
