import styled from 'styled-components';

export const Container = styled.div`
	.group {
		width: 150px;
		margin-bottom: 3rem !important;
		margin: auto;
		input {
			width: 30px;
			height: 30px;
			box-shadow: 0 2px 15px #00000033;
			z-index: 9999;
			top: 90px;
			margin-left: 5rem;
			position: absolute;
			background: red;
			border-radius: 50%;
			&:before {
				content: '';
				background: #ffffff;
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
			}
		}
	}

	.contain__inputs {
		.form-control {
			flex: 0 0 9rem;
		}
		input:first-child {
			flex-basis: auto;
		}
		input {
			font-size: 15px;
			padding: 20px 15px;
			width: 100% !important;
			font-family: 'HK Grotesk';
		}
	}
`;
export const H3 = styled.h3`
	font-style: normal;
	margin: 1rem 0;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;

	color: #151a30;
`;

export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Description = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 180%;
`;

export const OptionInputContainer = styled.div`
	margin-top: 1.5em;
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	align-items: center;
	input[type='radio'] {
		border: 1px solid rgba(194, 204, 217, 0.25);
		margin: 5px;
	}
	input[type='text'] {
		border: 1px solid rgba(194, 204, 217, 0.25);
		box-sizing: border-box;
		border-radius: 4px;
		background: #ffffff;
		box-sizing: border-box;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		/* identical to box height */

		padding: 0.6rem 1.3rem;
		width: 250px;
		box-shadow: none;
		line-height: 150%;
		/* identical to box height, or 24px */

		color: #151a30;

		${(props) => (props.touched ? `box-shadow: none !important;` : ``)}
		&:focus {
			border: 1px solid var(--theme-primary) !important;
			box-shadow: none !important;
		}
	}
	svg {
		flex-basis: 2em;
		flex-shrink: 0;
		flex-grow: 0;
	}
`;

export const AddOptionContainer = styled.div`
	dislay: flex;
	flex-direction: 'row';
	cursor: pointer;
	margin-bottom: 1.5em;
	span {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: #151a30;
	}
`;

export const SelectGroupContainer = styled.div`
	display: flex;
	flex-direction: 'row';
	margin-bottom: 20px;
`;

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: 'row';
	align-items: center;
	svg {
		margin-left: -1.7rem;
		margin-right: 5px;
	}
	select {
		border: 1px solid rgba(194, 204, 217, 0.25);
		background: #ffffff;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		margin-right: 10px;
		/* identical to box height */

		padding: 0rem 1rem;
		box-shadow: none;
		line-height: 150%;
		min-height: 40px;
		width: ${(props) => (props.questionType === 'coding' ? '133px' : '98px')};
		-webkit-appearance: none;
		/* identical to box height, or 24px */

		color: #8f9bb3;
	}
`;

export const CompletionTimeHeader = styled.span`
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #151a30;
`;

export const CodeEditorContainer = styled.div`
	max-width: 100px;
	margin-bottom: 20px;
`;

export const OptionGroupContainer = styled.div`
	display: flex;
	flex-direction: 'row';
	margin-bottom: 10px;
`;
export const CodeSelectContainer = styled.div`
	display: flex;
	flex-direction: 'row';
	align-items: center;
	svg {
		margin-left: -1.7rem;
		margin-right: 5px;
	}
	select {
		border: 1px solid rgba(194, 204, 217, 0.25);
		background: #ffffff;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		margin-right: 10px;
		/* identical to box height */

		padding: 0rem 1rem;
		box-shadow: none;
		line-height: 150%;
		min-height: 40px;
		width: 158px;
		-webkit-appearance: none;
		/* identical to box height, or 24px */

		color: #8f9bb3;
	}
`;

export const ThemeSelectContainer = styled(CodeSelectContainer)`
	margin-left: 5em;
`;
