export const TakeTestActions = {
	PREV_TEST: 'PREV_TEST',
	NEXT_TEST: 'NEXT_TEST',
	GET_PREVIEW_QUESTION: 'GET_PREVIEW_QUESTION',
	GET_PREVIEW_QUESTION_SUCCESS: 'GET_PREVIEW_QUESTION_SUCCESS',
	GET_PREVIEW_QUESTION_FAIL: 'GET_PREVIEW_QUESTION_FAIL',
	SEND_QUESTION: 'SEND_QUESTION',
	SUBMIT_QUESTION: 'SUBMIT_QUESTION',
	SUBMIT_QUESTION_SUCCESS: 'SUBMIT_QUESTION_SUCCESS',
	SET_SELECTED_ANSWER: 'SET_SELECTED_ANSWER',
	CLEAR_SESSION: 'CLEAR_SESSION',
	TEST_CODE: 'TEST_CODE',
};
