/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/* ---------------------------- Image Dependency ---------------------------- */
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';

/* ----------------------------- Input PropTypes ---------------------------- */
const propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	errorMessage: PropTypes.string,
	placeholder: PropTypes.string,
	hasStrip: PropTypes.bool,
	label: PropTypes.string,
	isInvalid: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

/* --------------------------- Input defaultProps --------------------------- */
const defaultProps = {
	type: 'input',
	id: 0,
	placeholder: 'input',
	errorMessage: 'This field is required',
	hasStrip: null,
	label: 'Label',
	isInvalid: null,
	value: '',
};

const Input = ({
	id,
	type,
	placeholder,
	hasStrip,
	label,
	isInvalid,
	errorMessage,
	value,
	...rest
}) => {
	const [showPassword, onShowPassword] = useState('password');

	return (
		<InputContainer
			hasStrip={hasStrip}
			isInvalid={isInvalid}
			touched={value !== ''}
			disabled={rest?.disabled}
			data-testid="input"
		>
			{label && <label htmlFor={id}>{label}</label>}
			<input
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...rest}
				type={type === 'password' ? showPassword : type}
				id={id}
				value={value}
				className="form-control"
				placeholder={placeholder}
				data-testid="inputs"
			/>

			{type === 'password' && (
				<button
					onClick={() =>
						onShowPassword(
							`${showPassword === 'password' ? 'input' : 'password'}`
						)
					}
					className={`input-icon ${
						showPassword === 'input' ? 'active__showpassword' : ''
					}`}
					type="button"
				>
					<Eye />
				</button>
			)}
			{isInvalid && (
				<Text color="#f66262" fontSize="12px" className="error">
					{errorMessage}
				</Text>
			)}
		</InputContainer>
	);
};

const InputContainer = styled.div`
	margin-bottom: 1.5rem;

	input {
		border: 1px solid rgba(194, 204, 217, 0.25);
		box-sizing: border-box;
		border-radius: 4px;
		background: #ffffff;
		box-sizing: border-box;
		font-style: normal;
		font-family: var(--font-primary);
		font-weight: normal;
		font-size: 15px;
		/* identical to box height */

		padding: 1.4rem 1.3rem;
		box-shadow: none;
		line-height: 150%;
		/* identical to box height, or 24px */

		color: #8f9bb3;

		${(props) => (props.touched ? `box-shadow: none !important;` : ``)}
		&:focus {
			border: 1px solid var(--theme-primary) !important;
			box-shadow: none !important;
		}
	}
	label {
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		/* identical to box height, or 21px */

		color: #151a30;
	}
	button {
		&.input-icon {
			height: 43px;
			z-index: 12;
			width: 34px;
			align-items: center;
			justify-content: center;
			display: flex;
			margin-right: 2px;
			float: right;
			cursor: pointer;
			background: white;
			border: none;
			margin-top: -2.8rem;

			&.active__showpassword {
				svg {
					stroke: var(--theme-primary);
				}
			}
		}

		&.error {
			color: red;
			font-size: 12px;
			margin-top: 3px;
			display: block;
		}
	}
`;

export const Text = styled.span`
	color: ${(props) => props.color};
	font-size: ${(props) => props.fontSize};
	overflow-wrap: break-word;
`;

Input.defaultProps = defaultProps;

Input.propTypes = propTypes;

export default Input;
