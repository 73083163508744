/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import ReactGA from 'react-ga';

export const initGA = () => {
	console.log(
		`%c
    JOIN TUNGA
   ----------------------------------------
        \\   ^__^
         \\  (oo)\\_______
            (__)\\       )\\/\\
                ||----w |
                ||     || 
                
  Hey There we are glad you liked the application and it's process and what to see whats going on ?, check out https://tunga.io for more info.
  
  And dont forget to shoot a message to the team at Tunga if you have an issues.`,
		'font-family:inherit'
	);
	ReactGA.initialize('UA-70644715-1', {
		testMode: process.env.NODE_ENV === 'test',
	});
};

export const logPageView = () => {
	ReactGA.set({ page: window.location.pathname });
	ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category = '', action = '', label = '') => {
	if (category && action && label) {
		ReactGA.event({ category, action, label });
	}
};

export const logException = (description = '', fatal = false) => {
	if (description) {
		ReactGA.exception({ description, fatal });
	}
};
