/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/* -------------------------- Internal Dependencies ------------------------- */
import { loginValdationSchema } from 'utils/validation-schema';
import Input from 'components/input';
import Button from 'components/button';
import { Link } from 'react-router-dom';
import { signInUser } from 'redux/user/actions';
import { selectisLoading } from 'redux/user/selector';
import AuthLayout, {
	AuthStylingLayoutChildren,
} from '../../components/layout/auth-layout';
import SEO from 'components/seo';

/* ----------------------------- Login propTypes ---------------------------- */
const propsTypes = {
	isLoading: PropTypes.bool,
	forgotPassword: PropTypes.func,
};

const Login = ({ dispatch, isLoading }) => {
	return (
		<AuthLayout>
			<SEO title="Login" />
			<AuthStylingLayoutChildren>
				<h3>Welcome back</h3>
				<p className="intro__text">Log in to continue to your account</p>
				<Formik
					initialValues={{
						email: '',
						password: '',
					}}
					validationSchema={loginValdationSchema}
					onSubmit={async ({ email, password }) => {
						await dispatch(signInUser?.({ email, password }));
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
					}) => (
						<form onSubmit={handleSubmit}>
							<Input
								hasStrip
								id="email"
								name="email"
								type="email"
								label="Email address"
								placeholder="Enter email address"
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								errorMessage={errors.email}
								isInvalid={errors.email && touched.email}
							/>
							<span className="intro__link-ext">
								<Link to="forgot">Forgot your password?</Link>
							</span>
							<Input
								hasStrip
								id="password"
								name="password"
								type="password"
								label="Password"
								placeholder="Enter password"
								value={values.password}
								onChange={handleChange}
								onBlur={handleBlur}
								errorMessage={errors.password}
								isInvalid={errors.password && touched.password}
							/>
							<Button
								type="submit"
								className="btn-developp btn-block"
								isLoading={isLoading}
								disabled={isLoading}
							>
								Log in to your account
							</Button>
						</form>
					)}
				</Formik>
				<p className="intro__link-link-out">
					Don't have an account yet? <Link to="/register"> Get Started</Link>
				</p>
			</AuthStylingLayoutChildren>
		</AuthLayout>
	);
};

Login.propTypes = propsTypes;
const mapStateToProps = createStructuredSelector({
	isLoading: selectisLoading,
});
export default connect(mapStateToProps)(Login);
