/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

/* -------------------------- Internal Dependencies ------------------------- */
import history from 'redux/history';
import { toggleAppHidden } from 'redux/user/actions';
import Button from 'components/button';
import { searchPage } from 'redux/developp-tests/actions/index';
/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import Search from 'assets/icons/search.svg';
import { user_status } from 'utils/user_persist';
import { Link } from 'react-router-dom';

import debounce from 'codewonders-helpers/bundle-cjs/helpers/debounce';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalize } from 'utils';
import useGlobal, { globalFilter } from 'utils/useGlobal';
import makeObservable from 'utils/makeObservable';

/* ------------------------- NavbarLayout propTypes ------------------------- */
const propTypes = {
	title: PropTypes.string,
	css: PropTypes.string,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
	showActions: PropTypes.bool,
	toggleAppHidden: PropTypes.func.isRequired,
	createTest: PropTypes.bool,
	profileImg: PropTypes.bool,
	searchPage: PropTypes.func,
};
export const SearchContext = makeObservable('');
/* ------------------------ NavbarLayout defaultProps ----------------------- */
const defaultProps = {
	title: 'Tests',
	showActions: true,
	onClick: null,
	onChange: null,
	css: null,
	profileImg: false,
};

const NavbarLayout = memo((props) => {
	const {
		title,
		onClick,

		children,
		showActions,
		location,
		searchPage,
		css: customStyle,
		toggleAppHidden,
		createTest,
		profileImg,
	} = props;
	const [searchTerm, setSearchTerm] = useState('');
	const previousSearchTermRef = useRef('');
	const [filter] = useGlobal(globalFilter);
	const [, setSearchInstance] = useGlobal(SearchContext);

	const search = debounce(async (value) => {
		setSearchInstance(searchTerm);
		if (previousSearchTermRef.current === value) {
			try {
				// await refetch();
			} finally {
				await searchPage(
					value,
					location.pathname,
					filter.map((itm) => `&${itm.section}=${itm.id}`)
				);
			}
		}
	}, 500);

	return (
		<>
			<Nav css={customStyle}>
				<div className={`collapse__nav ${profileImg && 'align-items-center'}`}>
					<h3>
						<Menu
							className="d-inline d-md-none"
							onClick={() => toggleAppHidden()}
						/>{' '}
						{title}
					</h3>
					{showActions ? (
						<Items>
							{!location.pathname.includes('test-takers') && (
								<Item>
									<button
										className="btn btn- grey d-none d-md-block"
										onClick={onClick}
										type="button"
									>
										<Filter /> Filter
									</button>
								</Item>
							)}

							<Item>
								<input
									placeholder={`Search ${
										location?.pathname === '/dashboard'
											? 'Test'
											: capitalize(
													location?.pathname
														?.split('/')[2]
														?.replace('-', ' ')
														?.replace('/', '')
											  )
									}`}
									className="form-control d-none d-md-block"
									type="search"
									value={searchTerm}
									onChange={(e) => {
										setSearchTerm(e.target.value);
										previousSearchTermRef.current = e.target.value;
										search(e.target.value);
									}}
								/>
							</Item>
							{createTest ? (
								<CreateItem>
									<Button
										onClick={() => {
											history.push('/create/test');
										}}
										size="small"
										className="btn-developp btn-block"
									>
										Create Test
									</Button>
								</CreateItem>
							) : (
								<div />
							)}
							{children && <Item>{children}</Item>}

							<Item>
								<Link to="/dashboard/profile">
									{' '}
									{user_status?.photo ? (
										<img
											src={user_status?.photo}
											alt="profile"
											className="avatar__image"
										/>
									) : (
										<span className="generate_image">
											{user_status?.first_name?.split('')[0]}
											{user_status?.last_name?.split('')[0]}
										</span>
									)}{' '}
								</Link>
							</Item>
						</Items>
					) : (
						<Items>
							{children}
							{profileImg && (
								<Item>
									<Link to="/dashboard/profile">
										{' '}
										{user_status?.photo ? (
											<img
												src={user_status?.photo}
												alt="profile"
												className="avatar__image"
											/>
										) : (
											<span className="generate_image">
												{user_status?.first_name?.split('')[0]}
												{user_status?.last_name?.split('')[0]}
											</span>
										)}{' '}
									</Link>
								</Item>
							)}
						</Items>
					)}
				</div>
			</Nav>
		</>
	);
});

const Nav = styled.nav`
	position: fixed;
	min-height: 70px;
	top: 0;
	z-index: 999;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 0 40px;
	@media screen and (max-width: 935px) {
		padding: 0 20px;
	}
	right: 0;
	width: calc(100% - var(--sidebar-width));
	.collapse__nav {
		display: flex;
		width: 100%;
		align-items: baseline;
		h3 {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			/* identical to box height */

			color: #151a30;
			svg {
				margin-top: -4px;
				cursor: pointer;
				margin-right: 2px;
			}
		}
	}
	${css`
		${(props) => props.css};
	`};
`;

const Items = styled.div`
	margin-left: auto;
	display: flex;
	span:last-child {
		margin-right: 0 !important;
	}
`;

const Item = styled.span`
	margin: 0 0.7rem;
	button {
		background: rgba(143, 155, 179, 0.05);
		border-radius: 4px;
		font-weight: 400;
		font-size: 15px;
		line-height: 19px;
		padding: 8px 21px;
		color: #3e4857;
		svg {
			margin-right: 4px;
		}
		box-shadow: none !important;
	}
	input {
		font-size: 14px;
		background-image: url(${Search});
		background-position: 7px 7px;
		transition: all 0.5s ease-in-out;
		background-repeat: no-repeat;
		padding-left: 2rem !important;
		width: 200px;
		&:focus {
			border: 1px solid var(--theme-primary);
			box-shadow: none !important;
			width: 300px;
			display: block;
			width: 300px !important;
		}
	}
	img.avatar__image {
		width: 40px;
		height: 40px;
		border: 2px solid #cfe0fb;
		border-radius: 50%;
		object-fit: cover;
		display: block;
		margin-top: -5px;
	}

	.generate_image {
		color: #fff;
		width: 40px;
		height: 40px;
		border: 2px solid #cfe0fb;
		margin-top: -5px;
		background: #062e64;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: 14px;
		font-weight: 900;
		/* margin: 2.5rem auto; */
	}
`;

const CreateItem = styled.span`
	margin: 0 0.7rem;
`;

NavbarLayout.defaultProps = defaultProps;

NavbarLayout.propTypes = propTypes;

export default connect(null, { searchPage, toggleAppHidden })(
	withRouter(NavbarLayout)
);
