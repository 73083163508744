/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children }, error) => {
	const [errorInfo, setErrorInfo] = useState(null);

	useEffect(() => {
		return () => {
			setErrorInfo(error);
		};
	}, [errorInfo, error]);

	if (errorInfo) {
		// Error path
		return (
			<Sentry.ErrorBoundary fallback={'Something went wrong.'}>
				<div>
					<h2>Something went wrong.</h2>
					<details style={{ whiteSpace: 'pre-wrap' }}>
						{error?.toString()}
						<br />
						{errorInfo.componentStack}
					</details>
				</div>
			</Sentry.ErrorBoundary>
		);
	}
	// Normally, just render children
	return children;
};

export default ErrorBoundary;
