/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'codewonders-helpers/bundle-cjs/helpers/is-empty';

/* -------------------------- Internal Dependencies ------------------------- */
import Loader from 'components/loader';
import { getAvatar } from 'utils';
import SideBarModal from 'components/sidebar-modal';

/* ------------------------- ResultProfile propTypes ------------------------ */
const propTypes = {
	show: PropTypes.bool,
	closeShow: PropTypes.func,
	user: PropTypes.object,
};

const ResultProfile = ({ show, closeShow, user }) => {
	return (
		<Wrapper>
			<SideBarModal show={show} closeShow={closeShow}>
				{!isEmpty(user) ? (
					<>
						{user.photo ? (
							<img src={user.photo} alt={user.first_name} />
						) : (
							<span
								className="generate_image"
								style={{
									background: getAvatar(user.first_name),
								}}
							>
								{user.first_name.split('')[0]}
								{user.last_name.split('')[0]}
							</span>
						)}
						<article>
							<div className="infos">
								<h4>Full name</h4>
								<p className="text-capitalize">
									{user?.first_name?.toLowerCase()}{' '}
									{user?.last_name?.toLowerCase()}
								</p>
							</div>
							<div className="infos">
								<h4>Email address</h4>
								<p>{user?.email}</p>
							</div>
							<div className="infos">
								<h4>Phone number</h4>
								<p>{user?.phone_number || 'No Phone Yet'}</p>
							</div>
							<div className="infos">
								<h4>Address</h4>
								<p>{user?.streen || 'No Address Yet'}</p>
							</div>
							<div className="infos">
								<h4>Country</h4>
								<p>{user?.country || 'No Country Yet'}</p>
							</div>
							<div className="infos">
								<h4>Role</h4>
								{user?.is_contributor ||
								user?.is_admin ||
								user?.is_staff ||
								user?.is_test_taker ? (
									<p>
										{user?.is_contributor && 'Contributor'}
										{user?.is_admin && 'Admin'}
										{user?.is_staff && 'Staff'}
										{user?.is_test_taker && 'Test Taker'}
									</p>
								) : (
									<p>Test Taker</p>
								)}
							</div>
						</article>
					</>
				) : (
					<Loader loadingText="Getting User..." />
				)}
			</SideBarModal>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	img {
		height: 100px;
		width: 100px;
		border-radius: 50%;
		object-fit: cover;
		display: block;
		margin: 2.5rem auto;
	}

	.generate_image {
		width: 100px;
		height: 100px;
		color: #fff;
		background: #4867f7;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: 25px;
		margin: 2.5rem auto;
	}
	.infos p {
		margin: 0;
		font-size: 14px;
		font-weight: 300;
		&.text-capitalize {
			text-transform: capitalize;
		}
	}

	.infos h4 {
		font-size: 15px;
		color: #3e4857;
		margin: 0;
		font-weight: 600;
	}
	.infos {
		display: flex;
		justify-content: space-between;

		border-bottom: 1px solid #edf1f7;
		padding: 14px 0;
		&:first-child {
			border-top: 1px solid #edf1f7;
		}
	}
`;

ResultProfile.propTypes = propTypes;

export default ResultProfile;
