/* eslint-disable consistent-return */

/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { pause } from 'codewonders-helpers';

/* -------------------------- Internal Dependencies ------------------------- */
import BASE_URL from 'services/config';
import api from 'services/api';

import { TestActions } from 'redux/developp-tests/types';
import history from 'redux/history';
import { token, user_id, user_status } from 'utils/user_persist';
import { getError } from 'utils';
import { setAlert } from 'redux/alert/actions';
import { UserActions } from 'redux/user/types';

/** =========================================== */

/**
 * @function
 * @param {Number} id
 */
export const getTestQuestions = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const { current_test } = state.tests;
		const { slug } = current_test;

		dispatch({
			type: TestActions.GET_TEST_QUESTION,
		});

		if (!slug && id) {
			// Notify.error('Please Create or Select a Test First');
			const url = `${BASE_URL}/api/tests/${id}/questions`;
			const options = api.options('GET', token, {});
			try {
				const response = await api.request(url, options);
				dispatch({
					type: TestActions.GET_TEST_QUESTION_SUCCESS,
					payload: {
						data: response.data,
					},
				});
			} catch (err) {
				dispatch({
					type: TestActions.GET_TEST_QUESTION_FAIL,
					payload: err,
				});
				getError(dispatch, err);
			}
		}
		if (slug) {
			const url = `${BASE_URL}/api/tests/${slug}/questions`;
			const options = api.options('GET', token, {});
			try {
				const response = await api.request(url, options);
				dispatch({
					type: TestActions.GET_TEST_QUESTION_SUCCESS,
					payload: {
						data: response.data,
					},
				});
			} catch (err) {
				getError(dispatch, err);
			}
		}
	};
};

/**
 * @function
 * @param {Number} offset
 * @param {Number} limit
 * @param {Array} data
 */
export const getTests = (offset = 0, limit = 10, query = []) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_TEST,
			payload: {
				isLoading: true,
				data: [],
			},
		});

		const url = `${BASE_URL}/api/tests?limit=${limit}&offset=${offset}&published=true${query.map(
			(qry) => qry
		)}`.replace(/,/g, '');

		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			return dispatch({
				type: TestActions.GET_TEST,
				payload: {
					isLoading: false,
					data: response.data,
				},
			});
		} catch (err) {
			getError(dispatch, err);
		}
	};
};
export const searchPage = (term, pageLocation, query) => {
	return async (dispatch) => {
		//TODO:Clean Up Code block
		if (pageLocation.includes('/dashboard/test-takers')) {
			dispatch({
				type: UserActions.ACTIVATE_LOADING,
			});
		} else {
			dispatch({
				type: TestActions.GET_TEST_QUESTION,
			});
		}
		let url;
		switch (true) {
			case pageLocation.includes('/dashboard/test-takers'):
				url = `${BASE_URL}/api/users?search=${term}`;
				break;
			case pageLocation === '/dashboard/my-tests' &&
				(user_status?.is_contributor ||
					user_status?.is_admin ||
					user_status?.is_superuser):
				url = `${BASE_URL}/api/tests?search=${term}&created_by=${user_id}`;
				break;
			case pageLocation === '/dashboard/my-tests' && user_status?.is_test_taker:
				url = `${BASE_URL}/api/tests/past_tests?search=${term}`;
				break;
			case pageLocation === '/dashboard/my-tests?tab=pending' &&
				user_status?.is_test_taker:
				url = `${BASE_URL}/api/tests/invites?search=${term}`;
				break;
			default:
				url = `${BASE_URL}/api/tests?search=${term}&published=true`;
		}

		const options = api.options('GET', token, {});
		try {
			const response =
				pageLocation === '/dashboard/my-tests' && user_status?.is_test_taker
					? await api.request(
							`${url}${query.map((qry) => qry)}`
								?.replace(/,/g, '')
								?.replace(/&/g, '&test__'),
							options
					  )
					: await api.request(
							`${url}${query.map((qry) => qry)}`.replace(/,/g, ''),
							options
					  );
			if (pageLocation === '/dashboard') {
				return dispatch({
					type: TestActions.GET_TEST,
					payload: {
						isLoading: false,
						data: response.data,
					},
				});
			}
			if (pageLocation.includes('/dashboard/test-takers')) {
				await dispatch({
					type: UserActions.GET_ALL_USER,
					payload: { loading: false, data: response.data },
				});
			}
			if (pageLocation === '/dashboard/my-tests') {
				if (
					user_status?.is_contributor ||
					user_status?.is_admin ||
					user_status?.is_superuser
				) {
					return dispatch({
						type: TestActions.FETCH_USER_TESTS_SUCCESS,
						payload: {
							data: response.data,
						},
					});
				} else {
					if (pageLocation === '/dashboard/my-tests?tab=pending') {
						return dispatch({
							type: TestActions.GET_PENDING_INVITES,
							payload: {
								loading: false,
								data: response.data,
							},
						});
					} else {
						return dispatch({
							type: TestActions.GET_PAST_TEST,
							payload: {
								loading: false,
								data: response.data,
							},
						});
					}
				}
			}
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {Number} offset
 * @param {Number} limit
 * @param {Number} data
 */
export const getPendingInvites = (query = []) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_PENDING_INVITES,
			payload: {
				loading: true,
			},
		});
		const url = `${BASE_URL}/api/tests/invites${query?.map((qry) => qry)}`
			?.replace(/,/g, '')
			?.replace(/invites&/g, 'invites?test__')
			?.replace(/&/g, '&test__');
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			return dispatch({
				type: TestActions.GET_PENDING_INVITES,
				payload: {
					loading: false,
					data: response.data,
				},
			});
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {Number} offset
 * @param {Number} limit
 * @param {Number} data
 */
export const getPastTest = (query = [], userId = '') => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_PAST_TEST,
			payload: {
				loading: true,
			},
		});
		const url = userId
			? `${BASE_URL}/api/tests/past_tests?user=${userId}`
			: `${BASE_URL}/api/tests/past_tests${query?.map((qry) => qry)}`
					?.replace(/,/g, '')
					?.replace(/past_tests&/g, 'past_tests?test__')
					?.replace(/&/g, '&test__');
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			return dispatch({
				type: TestActions.GET_PAST_TEST,
				payload: {
					loading: false,
					data: response.data,
				},
			});
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {String} slug
 */
export const deleteTest = (slug) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.DELETE_TEST_BY_SLUG,
			payload: {
				isLoading: true,
			},
		});
		const url = `${BASE_URL}/api/tests/${slug}`;
		const options = api.options('DELETE', token, {});
		try {
			const response = await api.request(url, options);
			await dispatch({
				type: TestActions.DELETE_TEST_BY_SLUG,
				payload: {
					isLoading: false,
				},
			});
			dispatch(setAlert(response.data.message, 'success'));
			pause(history.push('/dashboard'), 1000);
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {Number} offset
 * @param {Number} limit
 */
export const getMoreTests = (offset = 0, limit = 5, query = []) => {
	return async (dispatch) => {
		const url = `${BASE_URL}/api/tests?limit=${limit}&offset=${offset}&published=true${query.map(
			(qry) => qry
		)}`.replace(/,/g, '');
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);

			return dispatch({
				type: TestActions.GET_MORE_TESTS,
				payload: {
					isLoading: false,
					data: response.data,
				},
			});
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {String} slug
 */
export const getTestsShares = (slug) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_TESTS_SHARE,
			payload: {
				isLoading: true,
				data: [],
			},
		});
		const url = `${BASE_URL}/api/tests/${slug}/share`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			await dispatch({
				type: TestActions.GET_TESTS_SHARE,
				payload: {
					isLoading: false,
					data: response.data,
				},
			});
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {Number} id
 */
export const getTestById = (id = 0) => {
	return (dispatch) => {
		dispatch({
			type: TestActions.GET_TEST_BY_ID,
			payload: {
				isLoading: true,
				data: [],
			},
		});
		const url = `${BASE_URL}/api/tests/${id}`;
		const options = api.options('GET', token, {});
		return api
			.request(url, options)
			.then((response) => {
				dispatch({
					type: TestActions.GET_TEST_BY_ID,
					payload: {
						isLoading: false,
						data: response.data,
					},
				});
				return response.data;
			})
			.catch((err) => {
				history.push('/dashboard');
				getError(dispatch, err);
				dispatch({
					type: TestActions.GET_TEST_ERROR,
					payload: err,
				});
			});
	};
};

/**
 * @function
 * @param {String} slug
 */
export const deleteShareById = (slug, id) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.DELETE_SHARE_BY_ID,
			payload: true,
		});
		const url = `${BASE_URL}/api/tests/${slug}/share/${id}`;
		const options = api.options('DELETE', token, {});
		try {
			const response = await api.request(url, options);
			await dispatch({
				type: TestActions.DELETE_SHARE_BY_ID,
				payload: false,
			});
			dispatch(getTestById(slug));
			dispatch(getTestsShares(slug));
			dispatch(setAlert(response.data.message, 'success'));
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {Object} data
 */
export const addSharing = (data) => {
	const { slug } = data;

	return (dispatch) => {
		const url = `${BASE_URL}/api/tests/${slug}/share`;

		dispatch({
			type: TestActions.SHARE_TEST,
			payload: true,
		});

		const options = api.options('POST', token, {
			data,
		});
		return api
			.request(url, options)
			.then(() => {
				dispatch(getTestById(slug));
				dispatch(getTestsShares(slug));
				dispatch({
					type: TestActions.SHARE_TEST,
					payload: false,
				});
				dispatch(setAlert('Successfully Shared Test', 'success'));
			})
			.catch((err) => {
				getError(dispatch, err);
			});
	};
};

/**
 * @function
 * @param {Object} data
 */
export const resendTest = (slug, id, test) => {
	return (dispatch) => {
		const url = `${BASE_URL}/api/tests/${slug}/share/${id}`;

		dispatch({
			type: TestActions.RE_SHARE_TEST,
			payload: true,
		});

		const options = api.options('PATCH', token, {
			test,
		});
		return api
			.request(url, options)
			.then((res) => {
				dispatch(getTestsShares(slug));
				dispatch({
					type: TestActions.RE_SHARE_TEST,
					payload: false,
				});

				dispatch(setAlert(res.data.message, 'success'));
			})
			.catch((err) => {
				getError(dispatch, err);
			});
	};
};

/**
 * @function
 * @param {Object} data
 */
export const changePermission = (data) => {
	const { slug } = data;

	return (dispatch) => {
		const url = `${BASE_URL}/api/tests/${slug}`;

		dispatch({
			type: TestActions.CHANGE_PERMISSION,
			payload: true,
		});

		const options = api.options('PATCH', token, {
			data,
		});
		return api
			.request(url, options)
			.then(() => {
				dispatch(getTestById(slug));
				dispatch({
					type: TestActions.CHANGE_PERMISSION,
					payload: false,
				});
				dispatch(
					setAlert('Successfully changed test sharing permission', 'success')
				);
			})
			.catch((err) => {
				getError(dispatch, err);
				dispatch({
					type: TestActions.CHANGE_PERMISSION,
					payload: false,
				});
			});
	};
};

/**
 * @function
 * @returns {Array}
 */
export const fetchUserTests = (query = []) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.FETCH_USER_TESTS,
		});
		const url = `${BASE_URL}/api/tests?created_by=${user_id}${query.map(
			(qry) => qry
		)}`.replace(/,/g, '');
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.FETCH_USER_TESTS_SUCCESS,
				payload: {
					data: response.data,
				},
			});
		} catch (err) {
			getError(dispatch, err);
		}
	};
};

/**
 * Clear Test Sessions
 * @param {String} value
 * @param {String} type
 */
export const clearSession = () => {
	return (dispatch) => {
		dispatch({
			type: TestActions.CLEAR_TEST_SESSIONS,
		});
	};
};
