/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

/* ---------------------------- Style Dependency ---------------------------- */
import 'react-quill/dist/quill.snow.css';

/* --------------------------- RichTextEditor propTypes --------------------------- */
const propTypes = {
	id: PropTypes.string,
	errorMessage: PropTypes.string,
	placeholder: PropTypes.string,
	hasStrip: PropTypes.bool,
	formats: PropTypes.array,
	modules: PropTypes.object,
	isInvalid: PropTypes.bool,
	label: PropTypes.string,
	value: PropTypes.string,
};

/* -------------------------- TextArea defaultProps ------------------------- */
const defaultProps = {
	id: 0,
	placeholder: 'input',
	errorMessage: 'This field is required',
	hasStrip: null,
	formats: [
		'bold',
		'italic',
		'underline',
		'code-block',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'color',
		'background',
		'link',
		'clean',
	],
	label: 'Label',
	isInvalid: null,
	value: '',
	modules: {
		toolbar: [
			['bold', 'italic', 'underline', 'code-block', 'blockquote'],
			[
				{ list: 'ordered' },
				{ list: 'bullet' },
				{ indent: '-1' },
				{ indent: '+1' },
			],
			[{ color: [] }, { background: [] }],
			['link'],
			['clean'],
		],
	},
};

const RichTextEditor = ({
	id,
	placeholder,
	hasStrip,
	label,
	modules,
	formats,
	isInvalid,
	errorMessage,
	value,
	...rest
}) => {
	return (
		<RichTextEditorContainer
			hasStrip={hasStrip}
			isInvalid={isInvalid}
			touched={value !== ''}
			disabled={rest?.disabled}
		>
			{label && <label htmlFor={label}>{label}</label>}

			<ReactQuill
				// eslint-disable-next-line react/jsx-props-no-spreading
				value={value}
				id={id}
				{...rest}
				data-testid="rich-text-editor"
				placeholder={placeholder}
				modules={modules}
				formats={formats}
			/>

			{isInvalid && (
				<Text color="#f66262" fontSize="12px" className="error">
					{errorMessage}
				</Text>
			)}
		</RichTextEditorContainer>
	);
};

const RichTextEditorContainer = styled.div`
	margin-bottom: 1.5rem;
	.ql-snow .ql-editor pre {
		white-space: pre-wrap;
		margin-bottom: 5px;
		margin-top: 5px;
		font-family: var(--font-secondary);
		padding: 5px 10px;
		font-weight: 100;
		color: #33c739 !important;
	}
	.ql-container.ql-snow {
		border: 1px solid rgba(194, 204, 217, 0.25);
		box-sizing: border-box;
		border-radius: 4px;
		background: #ffffff;
	}
	.ql-toolbar.ql-snow {
		border: 1px solid rgba(194, 204, 217, 0.25);
		box-sizing: border-box;
		border-radius: 4px;
		background: #ffffff;
	}
	.ql-editor {
		background: #fff;
		font-style: normal;
		font-family: var(--font-primary);
		font-weight: normal;
		font-size: 15px;
		padding: 18px 21px;
	}
	label {
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		/* identical to box height, or 21px */

		color: #151a30;
	}

	&.error {
		color: red;
		font-size: 12px;
		margin-top: 3px;
		display: block;
	}
`;

const Text = styled.span`
	color: ${(props) => props.color};
	font-size: ${(props) => props.fontSize};
`;

RichTextEditor.defaultProps = defaultProps;

RichTextEditor.propTypes = propTypes;

export default RichTextEditor;
