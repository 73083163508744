/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, NavLink } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isEmpty, getState } from 'codewonders-helpers';

/* -------------------------- Internal Dependencies ------------------------- */
import { user_id, user_status } from 'utils/user_persist';
import {
	selectCurrentTestCreator,
	selectCurrentTest,
} from 'redux/developp-tests/selector';
import { deleteTest } from 'redux/developp-tests/actions';
import { clearResultSession } from 'redux/test-results/actions';
import { selectQuestion } from 'redux/developp-tests/actions/create-test';
import { DeleteModal, VerifyStart } from './components/modals';
import { time_value, retake_status } from '../../timer';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as LinkOut } from '../../../assets/icons/log-out.svg';
import { ReactComponent as Back } from '../../../assets/icons/back.svg';
import { ReactComponent as Edit } from '../../../assets/icons/pencil.svg';
// import { ReactComponent as BookMark } from '../../../assets/icons/bookmark.svg';
import { ReactComponent as More } from '../../../assets/icons/more.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';

/* ---------------------------- Style Dependency ---------------------------- */
import { Nav, Item, Items } from './styles/navbar';
import { selectisLoading } from 'redux/user/selector';

/* ------------------------- NavbarLayout propTypes ------------------------- */
const propTypes = {
	css: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	showActions: PropTypes.bool,
	history: PropTypes.object,
	location: PropTypes.object,
	deleteTest: PropTypes.func,
	selectQuestion: PropTypes.func,
	created_by: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	clearResultSession: PropTypes.func,
	test: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

/* ------------------------ NavbarLayout defaultProps ----------------------- */
const defaultProps = {
	showActions: true,
	onClick: null,
	onChange: null,
	children: <></>,
	css: null,
};

const NavbarLayout = memo((props) => {
	const {
		children,
		showActions,
		css: customStyle,
		deleteTest: deleteTests,
		selectQuestion: goToEditTest,
		history,
		location,
		test,
		isLoading,
		clearResultSession,
		created_by,
	} = props;
	const id = location.pathname.split('/')[3];
	const [show, setShow] = useState(false);
	const [verify, setVerify] = useState({
		show: false,
		type: 'test',
	});
	const [cachedSlug] = useState(id);
	const { is_contributor, is_admin, is_superuser, is_test_taker } = user_status;
	const isAuthor = is_contributor ? user_id === created_by?.id : false;

	useEffect(() => {
		clearResultSession(cachedSlug);
	}, [cachedSlug, clearResultSession]);

	useEffect(() => {
		if (!isEmpty(time_value) && !getState('TIMER')) {
			getState('TIMER');
		}
		// eslint-disable-next-line
	}, [time_value]);

	return (
		<Nav css={customStyle}>
			<div className="collapse__nav" data-testid="nav">
				<h3>
					<Back
						className="d-inline d-md-block"
						onClick={() => history.push('/dashboard')}
					/>{' '}
				</h3>
				{isAuthor || is_admin || is_superuser || is_contributor ? (
					<Items className="centered">
						<Item>
							<NavLink
								exact
								activeClassName="is-active"
								to={`/tests/details/${id}`}
							>
								Test details
							</NavLink>
						</Item>
						<Item>
							<NavLink
								exact
								activeClassName="is-active"
								to={`/tests/share/${id}`}
							>
								Share
							</NavLink>
						</Item>
						<Item>
							<NavLink
								exact
								activeClassName="is-active"
								className={`${location.pathname.includes('/tests/results/') &&
									'is-active'}`}
								to={`/tests/results/${id}`}
							>
								Results
							</NavLink>
						</Item>
					</Items>
				) : null}

				<Items className="ml-auto">
					{showActions && !isLoading ? (
						<>
							{/* TODO: Commented out (Not yet in scope of sprint) */}
							{/* <Item>
								<BookMark className="icons" />
							</Item> */}
							{isAuthor || is_admin || is_superuser ? (
								<>
									<Item>
										<button
											className="btn btn- grey d-none d-md-block"
											onClick={() => {
												goToEditTest({ question: '' });
												if (id) {
													return history.push(`/edit/test/${id}`);
												}
											}}
											type="button"
										>
											<Edit /> Edit Test
										</button>
									</Item>
								</>
							) : (
								<>
									{!isEmpty(time_value) && is_test_taker ? (
										<Item>
											<OverlayTrigger
												placement="bottom"
												overlay={
													<Tooltip id="tooltip-disabled">
														You currently have a test that you haven't
														completed. Please go and finish it
													</Tooltip>
												}
											>
												<button
													className="btn btn-developp danger"
													onClick={() => {
														if (retake_status) {
															return history.push(
																`/test-challenge/${
																	time_value?.id
																}?r=${window.btoa(id)}`
															);
														}
														return history.push(
															`/test-challenge/${time_value?.id}`
														);
													}}
													type="button"
												>
													Finish Ongoing Test
												</button>
											</OverlayTrigger>
										</Item>
									) : null}
									{!isEmpty(test) && (
										<>
											{' '}
											{test.start_test ? (
												<Item>
													<button
														className="btn btn-developp"
														onClick={() => setVerify({ ...verify, show: true })}
														type="button"
														disabled={!isEmpty(time_value) && is_test_taker}
														aria-disabled={
															!isEmpty(time_value) && is_test_taker
														}
													>
														Start Test
													</button>{' '}
												</Item>
											) : (
												<Item>
													<button
														className="btn btn- grey d-none d-md-block"
														onClick={() =>
															history.push(`/dashboard/my-tests/result/${id}`)
														}
														type="button"
													>
														View Results
													</button>
												</Item>
											)}
											{test.retake_test && !test.start_test ? (
												<Item>
													<button
														className="btn btn-developp"
														onClick={() =>
															setVerify({
																...verify,
																show: true,
																type: 'retake',
															})
														}
														type="button"
														disabled={!isEmpty(time_value) && is_test_taker}
														aria-disabled={
															!isEmpty(time_value) && is_test_taker
														}
													>
														Retake Test
													</button>
												</Item>
											) : null}{' '}
										</>
									)}
								</>
							)}
						</>
					) : null}
					{isAuthor || is_admin || is_superuser || is_contributor ? (
						<Item>
							<button
								className="btn btn- grey d-none d-md-block"
								onClick={() => history.push(`/test-challenge/preview/${id}`)}
								type="button"
							>
								<LinkOut style={{ transform: 'rotate(-90deg)' }} /> Preview
							</button>
						</Item>
					) : null}
					{isAuthor || is_admin || is_superuser ? (
						<Item>
							<Dropdown>
								<Dropdown.Toggle as={More} className="icons" />

								<Dropdown.Menu>
									<Dropdown.Item href="#" onClick={() => setShow(!show)}>
										<Delete className="dropd-icon mr-2" /> Delete Test
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Item>
					) : null}
					<Item>{children}</Item>
				</Items>
			</div>
			<DeleteModal
				show={show}
				setShow={setShow}
				deleteTests={deleteTests}
				id={id}
			/>
			<VerifyStart
				show={verify}
				setShow={setVerify}
				history={history}
				id={id}
			/>
		</Nav>
	);
});

NavbarLayout.defaultProps = defaultProps;

NavbarLayout.propTypes = propTypes;
const mapStateToProps = createStructuredSelector({
	created_by: selectCurrentTestCreator,
	test: selectCurrentTest,
	isLoading: selectisLoading,
});

export default connect(mapStateToProps, {
	deleteTest,
	selectQuestion,
	clearResultSession,
})(withRouter(NavbarLayout));
