/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import logger from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

/* -------------------------- Internal Dependencies ------------------------- */
import rootReducer from 'redux/root-reducer';
import { saveState, loadState } from './localstorage';

const persistedState = loadState();
const middlewears = [thunk];

if (process.env.NODE_ENV === 'development') {
	middlewears.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
	rootReducer,
	persistedState,
	composeEnhancers(applyMiddleware(...middlewears))
);
store.subscribe(() => {
	saveState({
		tests: store.getState().tests,
	});
});
export const persistor = persistStore(store);
export default { store, persistor };
