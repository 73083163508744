/* -------------------------- External Dependencies ------------------------- */
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import NewTestLayout from 'components/layout/create-test-layout';
import Loader from 'components/loader';
import Question from 'pages/dashboard/new-test/question';

/* -------------------------- Component Dependencies ------------------------- */
const NewTest = lazy(() => import('pages/dashboard/new-test/test-details'));

const CreateTestsRoutes = () => {
	return (
		<NewTestLayout>
			<Suspense fallback={<Loader loadingText="Please Wait" />}>
				<Switch>
					<Route path="/create/test" exact component={NewTest} />
					<Route path="/edit/test/:id" exact component={NewTest} />
					<Route
						path="/edit/test/:id/:question/:segmentId"
						exact
						component={Question}
					/>
					<Route
						exact
						path="/edit/test/:id/:question/:segmentId/:questionId"
						component={Question}
					/>
				</Switch>
			</Suspense>
		</NewTestLayout>
	);
};

export default CreateTestsRoutes;
