/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import TestLayout from 'components/layout/tests-layout';
import Loader from 'components/loader';

/* -------------------------- Components Dependencies ------------------------- */
const TestDetails = lazy(() => import('pages/about-test/details'));
const TestShare = lazy(() => import('pages/about-test/share'));
const TestResults = lazy(() => import('pages/about-test/results'));

const TestsRoutes = () => {
	return (
		<TestLayout>
			<Suspense fallback={<Loader loadingText="Please Wait" />}>
				<Switch>
					<Route path="/tests/details/:id" exact component={TestDetails} />
					<Route path="/tests/share/:id" exact component={TestShare} />
					<Route path="/tests/results/:id" exact component={TestResults} />
					<Route
						path="/tests/results/:id/:userId"
						exact
						component={TestResults}
					/>
				</Switch>
			</Suspense>
		</TestLayout>
	);
};

export default TestsRoutes;
