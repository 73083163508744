export const TestActions = {
	GET_TEST: 'GET_TEST',
	GET_TEST_ERROR: 'GET_TEST_ERROR',
	GET_TEST_BY_ID: 'GET_TEST_BY_ID',
	GET_CATEGORY: 'GET_CATEGORY',
	GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
	GET_CATEGORY_FAIL: 'GET_CATEGORY_FAIL',
	GET_LEVEL: 'GET_LEVEL',
	GET_LEVEL_SUCCESS: 'GET_LEVEL_SUCCESS',
	GET_LEVEL_FAIL: 'GET_LEVEL_FAIL',
	GET_TEST_DETAIL: 'GET_TEST_DETAIL',
	SELECT_QUESTION: 'SELECT_QUESTION',
	GET_SKILL: 'GET_SKILL',
	GET_SKILL_SUCCESS: 'GET_SKILL_SUCCESS',
	GET_SKILL_FAIL: 'GET_SKILL_FAIL',
	GET_SCORE_METHOD: 'GET_SCORE_METHOD',
	GET_SCORE_METHOD_SUCCESS: 'GET_SCORE_METHOD_SUCCESS',
	GET_SCORE_METHOD_FAIL: 'GET_SCORE_METHOD_FAIL',
	CREATE_OR_UPDATE_TEST: 'CREATE_OR_UPDATE_TEST',
	CREATE_OR_UPDATE_TEST_SUCCESS: 'CREATE_OR_UPDATE_TEST_SUCCESS',
	CREATE_OR_UPDATE_TEST_FAIL: 'CREATE_OR_UPDATE_TEST_FAIL',
	ADD_OR_UPDATE_QUESTION: 'ADD_OR_UPDATE_QUESTION',
	ADD_OR_UPDATE_QUESTION_SUCCESS: 'ADD_OR_UPDATE_QUESTION_SUCCESS',
	ADD_OR_UPDATE_QUESTION_FAIL: 'ADD_OR_UPDATE_QUESTION_FAIL',
	SELECT_TEST: 'SELECT_TEST',
	GET_TEST_QUESTION: 'GET_TEST_QUESTION',
	GET_TEST_QUESTION_SUCCESS: 'GET_TEST_QUESTION_SUCCESS',
	GET_TEST_QUESTION_FAIL: 'GET_TEST_QUESTION_FAIL',
	GET_TESTS_SHARE: 'GET_TESTS_SHARE',
	GET_MORE_TESTS: 'GET_MORE_TESTS',
	SHARE_TEST: 'SHARE_TEST',
	SELECT_TEST_QUESTION: 'SELECT_TEST_QUESTION',
	DELETE_TEST_INSTRUCTION: 'DELETE_TEST_INSTRUCTION',
	DELETE_TEST_INSTRUCTION_SUCCESS: 'DELETE_TEST_INSTRUCTION_SUCCESS',
	DELETE_TEST_INSTRUCTION_FAIL: 'DELETE_TEST_INSTRUCTION_FAIL',
	ADD_TEST_INSTRUCTION: 'ADD_TEST_INSTRUCTION',
	ADD_TEST_INSTRUCTION_SUCCESS: 'ADD_TEST_INSTRUCTION_SUCCESS',
	ADD_TEST_INSTRUCTION_FAIL: 'ADD_TEST_INSTRUCTION_FAIL',
	FETCH_CURRENT_TEST: 'FETCH_CURRENT_TEST',
	FETCH_CURRENT_TEST_SUCCESS: 'FETCH_CURRENT_TEST_SUCCESS',
	FETCH_CURRENT_TEST_FAIL: 'FETCH_CURRENT_TEST_FAIL',
	UPDATE_TEST_INSTRUCTION: 'UPDATE_TEST_INSTRUCTION',
	UPDATE_TEST_INSTRUCTION_SUCCESS: 'UPDATE_TEST_INSTRUCTION_SUCCESS',
	UPDATE_TEST_INSTRUCTION_FAIL: 'UPDATE_TEST_INSTRUCTION_FAIL',
	UPDATE_QUESTION_OPTION: 'UPDATE_QUESTION_OPTION',
	UPDATE_QUESTION_OPTION_SUCCESS: 'UPDATE_QUESTION_OPTION_SUCCESS',
	UPDATE_QUESTION_OPTION_FAIL: 'UPDATE_QUESTION_OPTION_FAIL',
	ADD_QUESTION_OPTION: 'ADD_QUESTION_OPTION',
	ADD_QUESTION_OPTION_SUCCESS: 'ADD_QUESTION_OPTION_SUCCESS',
	ADD_QUESTION_OPTION_FAIL: 'ADD_QUESTION_OPTION_FAIL',
	DELETE_QUESTION_OPTION: 'DELETE_QUESTION_OPTION',
	DELETE_QUESTION_OPTION_SUCCESS: 'DELETE_QUESTION_OPTION_SUCCESS',
	DELETE_QUESTION: 'DELETE_QUESTION',
	DELETE_QUESTION_OPTION_FAIL: 'DELETE_QUESTION_OPTION_FAIL',
	DELETE_TEST_BY_SLUG: 'DELETE_TEST_BY_SLUG',
	PUBLISH_TEST: 'PUBLISH_TEST',
	PUBLISH_TEST_SUCCESS: 'PUBLISH_TEST_SUCCESS',
	PUBLISH_TEST_FAIL: 'PUBLISH_TEST_FAIL',
	GO_TO_EDIT_TEST: 'GO_TO_EDIT_TEST',
	NEW_QUESTION: 'NEW_QUESTION',
	SET_ROOT_URL: 'SET_ROOT_URL',
	FETCH_USER_TESTS: 'FETCH_USER_TESTS',
	FETCH_USER_TESTS_SUCCESS: 'FETCH_USER_TESTS_SUCCESS',
	FETCH_USER_TESTS_FAIL: 'FETCH_USER_TESTS_FAIL',
	FETCH_CODING_LANGUAGES: 'FETCH_CODING_LANGUAGES',
	FETCH_CODING_LANGUAGES_SUCCESS: 'FETCH_CODING_LANGUAGES_SUCCESS',
	FETCH_CODING_LANGUAGES_FAIL: 'FETCH_CODING_LANGUAGES_FAIL',
	CLEAR_TEST_ERROR: 'CLEAR_TEST_ERROR',
	CHANGE_PERMISSION: 'CHANGE_PERMISSION',
	UPDATE_THIS_QUESTION: 'UPDATE_THIS_QUESTION',
	GET_PENDING_INVITES: 'GET_PENDING_INVITES',
	GET_PAST_TEST: 'GET_PAST_TEST',
	DELETE_SHARE_BY_ID: 'DELETE_SHARE_BY_ID',
	RE_SHARE_TEST: 'RE_SHARE_TEST',
	GET_SEGMENTS: 'GET_SEGMENTS',
	DELETE_SEGMENTS: 'DELETE_SEGMENTS',
	ADD_SEGMENTS: 'ADD_SEGMENTS',
	MOVE_SEGMENTS: 'MOVE_SEGMENTS',
	CLEAR_TEST_SESSIONS: 'CLEAR_TEST_SESSIONS',
};
