/* -------------------------- Internal Dependencies ------------------------- */
import { TakeTestActions } from './types';

export const initialState = {
	counter: 0,
	isLoading: false,
	currentQuestions: {
		count: 0,
		next: null,
		previous: null,
		results: [],
	},
	questionLoading: false,
	codeMessage: {},
	checkCodeLoading: false,
};

/**
 * Take Test Reducers
 * @param {Object | Array} state
 * @param {Object} action
 */
const takeTestReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case TakeTestActions.NEXT_TEST:
			return {
				...state,
				counter: payload,
				codeMessage: {},
			};
		case TakeTestActions.PREV_TEST:
			return {
				...state,
				counter: payload,
				codeMessage: {},
			};
		case TakeTestActions.GET_PREVIEW_QUESTION: {
			return { ...state, isLoading: true };
		}
		case TakeTestActions.GET_PREVIEW_QUESTION_SUCCESS: {
			return { ...state, isLoading: false, currentQuestions: payload.data };
		}
		case TakeTestActions.TEST_CODE: {
			return {
				...state,
				checkCodeLoading: payload.loading,
				codeMessage: payload.data,
			};
		}
		case TakeTestActions.GET_PREVIEW_QUESTION_FAIL: {
			return { ...state, isLoading: false };
		}
		case TakeTestActions.SEND_QUESTION: {
			return { ...state, questionLoading: payload };
		}
		case TakeTestActions.SUBMIT_QUESTION: {
			return { ...state, questionLoading: payload };
		}
		case TakeTestActions.SUBMIT_QUESTION_SUCCESS:
		case TakeTestActions.CLEAR_SESSION: {
			return {
				...state,
				questionLoading: false,
				counter: 0,
				currentQuestions: {},
			};
		}
		default:
			return { ...state };
	}
};

export default takeTestReducer;
