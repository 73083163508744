/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

/* -------------------------- Internal Dependencies ------------------------- */
import useGlobal, { globalAccordion } from 'utils/useGlobal';
import useMount from 'utils/useMount';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';

/* ------------------------- AccordionSlate PropTypes ------------------------ */
const propTypes = {
	defaultKey: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

/* --------------- AccordionHeader & AccordionCollapse PropTypes -------------- */
const actionPropTypes = {
	eventKey: PropTypes.number,
	item: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

const AccordionSlate = ({ defaultKey, children }) => {
	const [, setAccordion] = useGlobal(globalAccordion);
	// Mount DefaultKey in to the state slate
	useMount(() => {
		setAccordion(defaultKey);
	});

	return (
		<>
			<div>{children}</div>
		</>
	);
};

export const AccordionHeader = ({ eventKey, item, children, ...rest }) => {
	const [dropdown, setAccordion] = useGlobal(globalAccordion);
	const bolAccordion = eventKey === dropdown;

	function allToggle() {
		return !bolAccordion ? eventKey : null;
	}

	const [{ canDrop, isOver }, drop] = useDrop({
		accept: 'box',
		drop: () => ({ name: item }),
		collect: (monitor) => ({
			isOver: item.id !== dropdown ? monitor.isOver() : false,
			canDrop: item.id !== dropdown ? monitor.canDrop() : false,
		}),
	});

	const isActive = canDrop && isOver;
	let styles = {};
	if (isActive) {
		styles = {
			backgroundColor: '#3559ff',
			color: '#fff',
		};
	} else if (canDrop) {
		styles = {
			backgroundColor: '#e1e5ff',
		};
	}

	return (
		<div ref={item.id !== dropdown ? drop : null}>
			<AccordionButtonStyle
				type="button"
				className="accordion__header"
				style={{ ...styles }}
				aria-controls={`slate${eventKey}`}
				onClick={() => setAccordion(allToggle())}
				aria-expanded={eventKey === dropdown}
				{...rest}
			>
				<Arrow
					style={{
						transform: eventKey === dropdown ? 'rotate(90deg)' : 'rotate(0deg)',
						transition: 'all .5s ease',
					}}
				/>

				{isActive ? 'Release to drop' : children}
			</AccordionButtonStyle>
		</div>
	);
};

export const AccordionCollapse = ({ eventKey, children }) => {
	const [dropdown] = useGlobal(globalAccordion);
	return (
		<>
			{eventKey === dropdown && (
				<AccordionBodyStyle
					id={`slate${eventKey}`}
					aria-label="Collapsed"
					aria-labelledby={`slate${eventKey}`}
					className="fadeIn"
					aria-hidden={eventKey !== dropdown}
				>
					{children}
				</AccordionBodyStyle>
			)}
		</>
	);
};

AccordionCollapse.propTypes = actionPropTypes;
AccordionHeader.propTypes = actionPropTypes;
AccordionSlate.propTypes = propTypes;

export default AccordionSlate;

const AccordionButtonStyle = styled.button``;
const AccordionBodyStyle = styled.section``;
