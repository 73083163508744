/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

/* ---------------------------- Card defaultprops --------------------------- */
const defaultProps = {
	nopaddingParent: false,
	children: [],
	css: null,
	minHeight: '200px',
};

/* ----------------------------- Card PropTypes ----------------------------- */
const proptypes = {
	children: PropTypes.any,
	nopaddingParent: PropTypes.bool,
	css: PropTypes.string,
	minHeight: PropTypes.string,
};

const Card = ({
	children,
	nopaddingParent,
	css: customCss,
	minHeight,
	...rest
}) => {
	return (
		<>
			<CardStyle
				css={customCss}
				minHeight={minHeight}
				{...{ ...rest }}
				data-testid="card"
			>
				<div
					className={`${nopaddingParent ? '' : 'card-body'}`}
					data-testid="card-inner"
				>
					{children}
				</div>
			</CardStyle>
		</>
	);
};

const CardStyle = styled.div`
	background: #ffffff;
	border-radius: 6px;
	border: none;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	min-height: ${(props) => props.minHeight || 0};
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	${css`
		${(props) => props.css};
	`};
`;

Card.defaultProps = defaultProps;
Card.propTypes = proptypes;

export default Card;
