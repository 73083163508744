/* -------------------------- Internal Dependencies ------------------------- */
import BASE_URL from 'services/config';
import api from 'services/api';
import { token } from 'utils/user_persist';
import { getError } from 'utils';
import { TestResultsActions } from './types';

/**
 * @function
 * @param {String} slug
 */
export const getTestReportUsers = (slug) => {
	return async (dispatch) => {
		if (!slug) return;
		if (slug) {
			dispatch({
				type: TestResultsActions.GET_TEST_REPORT_USERS,
			});
			const url = `${BASE_URL}/api/tests/${slug}/test_report_users`;
			const options = api.options('GET', token, {});
			try {
				const response = await api.request(url, options);
				dispatch({
					type: TestResultsActions.GET_TEST_REPORT_USERS_SUCCESS,
					payload: {
						data: response.data,
					},
				});
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestResultsActions.GET_TEST_REPORT_USERS_FAIL,
					payload: err,
				});
			}
		}
	};
};

/**
 * @function
 * @param {String} slug
 */
export const getUserTestResultsById = (slug, user_id, jung_test = false) => {
	return async (dispatch) => {
		if (!slug && !user_id) return;
		if (slug && user_id) {
			dispatch({
				type: TestResultsActions.GET_USER_TEST_REPORT,
			});

			const url = `${BASE_URL}/api/tests/${slug}/${
				jung_test ? 'jung_' : ''
			}test_report/${user_id}`;
			const options = api.options('GET', token, {});
			try {
				const response = await api.request(url, options);
				dispatch({
					type: TestResultsActions.GET_USER_TEST_REPORT_SUCCESS,
					payload: {
						data: response.data,
					},
				});
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestResultsActions.GET_USER_TEST_REPORT_FAIL,
					payload: err,
				});
			}
		}
	};
};

export const clearResultSession = () => {
	return (dispatch) => {
		dispatch({
			type: TestResultsActions.CLEAR_SESSION,
		});
	};
};
