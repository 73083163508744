/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { setState, clearState } from 'codewonders-helpers';

/* -------------------------- Internal Dependencies ------------------------- */
import { UserActions } from 'redux/user/types';
import BASE_URL from 'services/config';
import api from 'services/api';
import history from 'redux/history';

import { user_id, token } from 'utils/user_persist';
import { generateErrors, getError } from 'utils';
import { setAlert } from 'redux/alert/actions';

/* ============================================ */

/**
 * Toggle Sidebar in the app
 * @function
 */
export const toggleAppHidden = () => ({
	type: UserActions.TOGGLE_SIDEBAR_HIDDEN,
});

/**
 * @function
 * @param {Object} data
 */
export const signUpUser = (data) => {
	return (dispatch) => {
		dispatch({ type: UserActions.SIGN_UP });
		const url = `${BASE_URL}/api/users/`;
		const options = api.options('POST', {}, { data });
		return api
			.request(url, options)
			.then(() => {
				dispatch({
					type: UserActions.SIGN_UP_SUCCESS,
				});
				dispatch(
					setAlert(
						'Registered Successfully, login to access your account',
						'success'
					)
				);
				history.push('/');
			})
			.catch((err) => {
				getError(dispatch, err);
				dispatch({
					type: UserActions.SIGN_UP_FAIL,
				});
			});
	};
};

/**
 * @function
 * @param {email, first_name, is_active, is_admin, is_contributor, is_staff, is_superuser, is_test_taker, last_name,} data
 */
export const setEncodedData = ({
	email,
	first_name,
	is_active,
	is_admin,
	is_contributor,
	is_staff,
	is_superuser,
	is_test_taker,
	last_name,
	photo,
}) => {
	setState(
		'DEVELOP_USER',
		window.btoa(
			JSON.stringify({
				first_name,
				last_name,
				email,
				is_admin,
				is_contributor,
				is_test_taker,
				is_superuser,
				is_staff,
				is_active,
				photo,
			})
		)
	);
};

/**
 * @function
 * @param {Object} data
 */
export const signInUser = (data) => {
	return (dispatch) => {
		dispatch({ type: UserActions.SIGN_IN });
		const url = `${BASE_URL}/api/auth/`;
		const options = api.options('POST', {}, { data });
		return api
			.request(url, options)
			.then((response) => {
				dispatch({
					type: UserActions.SIGN_IN_SUCCESS,
				});
				setState('DEVELOP_TOKEN', response.data.token);
				setState('DEVELOP_USER_ID', response.data.user.id);

				setEncodedData(response.data.user);
				window.location.reload();
				return false;
			})
			.catch((err) => {
				getError(dispatch, err);
				dispatch({
					type: UserActions.SIGN_IN_FAIL,
				});
			});
	};
};

/**
 * @function
 * @param {String} data
 */
export const forgotPassword = (data) => {
	return (dispatch) => {
		dispatch({ type: UserActions.FORGOT_PASSWORD });
		const url = `${BASE_URL}/api/auth/password/reset/`;
		const options = api.options('POST', {}, { data });
		return api
			.request(url, options)
			.then((response) => {
				dispatch({
					type: UserActions.FORGOT_PASSWORD_SUCCESS,
				});
				dispatch(setAlert(response.data.message, 'success'));
			})
			.catch((err) => {
				getError(dispatch, err);
				dispatch({
					type: UserActions.FORGOT_PASSWORD_FAIL,
					payload: { errorMessage: generateErrors(err) },
				});
			});
	};
};

/**
 * @function
 * @param {Object} data
 */
export const resetPassword = (data) => {
	return (dispatch) => {
		const { pathname } = history.location;
		const id = pathname.slice(7);
		dispatch({ type: UserActions.RESET_PASSWORD });
		const url = `${BASE_URL}/api/auth/password/set/${id}/`;
		const options = api.options('POST', {}, { data });
		return api
			.request(url, options)
			.then(() => {
				dispatch({
					type: UserActions.RESET_PASSWORD_SUCCESS,
				});
				history.push('/');
			})
			.catch((err) => {
				dispatch(setAlert(generateErrors(err), 'error'));
				dispatch({
					type: UserActions.RESET_PASSWORD_FAIL,
					payload: { errorMessage: generateErrors(err) },
				});
			});
	};
};

/**
 * @function
 * @param { user_id } getState // Get userId from state
 */
export const getCurrentUser = () => {
	return async (dispatch) => {
		dispatch({ type: UserActions.GET_USER, payload: { loading: true } });
		try {
			const url = `${BASE_URL}/api/users/${user_id}`;
			const options = api.options('GET', token, {});
			const response = await api.request(url, options);

			await dispatch({
				type: UserActions.GET_USER,
				payload: { loading: false, data: response.data },
			});
		} catch (err) {
			await dispatch({ type: UserActions.GET_USER_ERROR, payload: err });
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param { user_id } getState // Get userId from state
 */
export const getAllUsers = () => {
	return async (dispatch) => {
		dispatch({ type: UserActions.GET_ALL_USER, payload: { loading: true } });
		try {
			const url = `${BASE_URL}/api/users?is_test_taker=True`;
			const options = api.options('GET', token, {});
			const response = await api.request(url, options);
			await dispatch({
				type: UserActions.GET_ALL_USER,
				payload: { loading: false, data: response.data },
			});
		} catch (err) {
			await dispatch({
				type: UserActions.GET_ALL_USER,
				payload: { loading: false },
			});
			getError(dispatch, err);
		}
	};
};

/**
 * @function
 * @param {Object} data
 */
export const editUser = (data) => {
	return (dispatch) => {
		dispatch({
			type: UserActions.EDIT_USER,
			payload: { loading: true, data: {} },
		});
		const url = `${BASE_URL}/api/users/${user_id}/`;
		const options = api.options('PATCH', token, { data });
		return api
			.request(url, options)
			.then((response) => {
				dispatch(getCurrentUser(user_id));
				dispatch(setAlert('Profile Updated successfully', 'success'));
				setEncodedData(response.data);
			})
			.catch((err) => {
				getError(dispatch, err);
				dispatch(getCurrentUser(user_id));
				dispatch({
					type: UserActions.GET_USER_ERROR,
					payload: { errorMessage: generateErrors(err) },
				});
			});
	};
};

/**
 * @function
 * @param {Object} data
 */
export const changeUserPassword = (data) => {
	return (dispatch) => {
		dispatch({ type: UserActions.EDIT_USER_START, payload: { loading: true } });
		const url = `${BASE_URL}/api/auth/password/change/`;
		const options = api.options('POST', token, { data });
		return api
			.request(url, options)
			.then((response) => {
				dispatch(getCurrentUser());
				dispatch(setAlert(response.data.success, 'success'));
			})
			.catch((err) => {
				getError(dispatch, err);
				dispatch({
					type: UserActions.GET_USER_ERROR,
					payload: { errorMessage: generateErrors(err) },
				});
			});
	};
};

/**
 * Logs User Out and deletes data from storage
 * @function
 */
export const logOutUser = () => {
	return (dispatch) => {
		dispatch({ type: UserActions.LOGOUT_USER, payload: null });
		dispatch(setAlert('Logout Successfully', 'success'));
		history.push('/');
		return clearState();
	};
};

export const resetToTest = () => {
	return (dispatch) => {
		dispatch({ type: UserActions.RESET_TO_TEST });
	};
};

/**
 * Clear User Error Dispatch
 */
export const clearUserError = () => {
	return { type: UserActions.CLEAR_USER_ERROR };
};
