/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

/* -------------------------- Internal Dependencies ------------------------- */
import { logOutUser } from 'redux/user/actions';
import { user_status } from 'utils/user_persist';

/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as People } from 'assets/icons/test-takers.svg';
import Award from '../../../assets/icons/my-tests.svg';
import { ReactComponent as Notification } from 'assets/icons/bell.svg';
import { ReactComponent as Person } from 'assets/icons/profile.svg';
import { ReactComponent as Learn } from 'assets/icons/book-open.svg';
import { ReactComponent as Metrics } from 'assets/icons/metrics.svg';
import { ReactComponent as Ext } from 'assets/icons/arrow-up-right.svg';
import { ReactComponent as LogOut } from 'assets/icons/log-out.svg';
import { ReactComponent as Attributions } from 'assets/icons/attribbution.svg';
import { selectHidden } from 'redux/user/selector';
import { selectCurrentCount } from 'redux/notifications/selectors';
import { createStructuredSelector } from 'reselect';
import { getNotifications } from 'redux/notifications/actions';
import useMount from 'utils/useMount';

/* ---------------------------- Sidebar propTypes --------------------------- */
const propTypes = {
	hidden: PropTypes.bool.isRequired,
	count: PropTypes.number,
	getNotifications: PropTypes.func,
	logOutUser: PropTypes.func,
};

const SidebarLayout = ({
	hidden: sideBarHidden,
	logOutUser: logout,
	getNotifications,
	count,
}) => {
	const callNotifications = useCallback(() => {
		getNotifications();
	}, [getNotifications]);

	useMount(() => {
		callNotifications();
	});

	return (
		<Container sideBarHidden={sideBarHidden}>
			<Logo>Tunga_Testing</Logo>
			<div className="side__bar-items">
				<Items exact activeClassName="is-active" to="/dashboard">
					<Home />
					Tests
				</Items>
				{user_status?.is_admin ||
				user_status?.is_superuser ||
				user_status?.is_contributor ? (
					<Items activeClassName="is-active" to="/dashboard/test-takers">
						<People className="test_taker" />
						Test Takers
					</Items>
				) : null}
				<Items activeClassName="is-active" to="/dashboard/my-tests">
					{/* <Award /> */}
					<img src={Award} alt="awards" />
					My tests
				</Items>
				<Items activeClassName="is-active" to="/dashboard/notifications">
					<Notification />
					Notifications{' '}
					{count > 0 && <span className="notifications__count">{count}</span>}
				</Items>
				<Items activeClassName="is-active" to="/dashboard/profile">
					<Person />
					Profile
				</Items>
				{user_status && !user_status.is_contributor && (
					<ItemsOut href="https://tunga.thehuddle.nl/" target="_blank">
						<Learn />
						Learn <Ext className="float-right" />
					</ItemsOut>
				)}
				{user_status && (user_status?.is_admin || user_status?.is_superuser) && (
					<Items activeClassName="is-active" to="/dashboard/metrics">
						<Metrics />
						Metrics
					</Items>
				)}
				<Items
					exact
					activeClassName="is-active"
					to="/"
					onClick={() => logout()}
				>
					<LogOut />
					Log out
				</Items>

				<div className="footer__section">
					<Attributions />
				</div>
			</div>
		</Container>
	);
};

const Container = styled.aside`
	background: var(--accent);
	height: 100vh;
	width: 260px;
	transition: all 0.3s ease;
	@media screen and (max-width: 935px) {
		transform: translate(
			${(props) => (props.sideBarHidden ? '0px' : '-400px')}
		);
		z-index: 99999;
		width: 75% !important;
		&:before {
			content: '';
			position: absolute;
			background: #000000a6;
			top: 0;
			height: 100%;
			width: calc(100% - 62.5%);
			right: -28vw;
			z-index: -1;
		}
	}
	position: fixed;
	top: 0;
	left: 0;
	padding: 1.5rem;

	.footer__section {
		margin-top: 8rem;
	}
`;
const Logo = styled.h3`
	font-family: HK Grotesk;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	margin-left: 10px;
	margin-bottom: 1.9rem;
	line-height: 22px;
	/* identical to box height */

	color: #ffffff;
`;
const Items = styled(NavLink)`
	padding: 10px 15px;
	display: block;
	background: rgba(255, 255, 255, 0);
	margin-bottom: 1rem;
	border-radius: 6px;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	color: #8f9bb3 !important;
	transition: all 0.4s linear;

	.notifications__count {
		font-weight: 700;
		padding: 1px 5px;
		height: 20px;
		font-size: 12px;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: center;
		width: fit-content;
		border-radius: 50px;
		float: right;
		background: #eb5757;
	}

	&:hover {
		background: rgba(255, 255, 255, 0.03);
	}
	svg,
	img {
		display: inline-block;
		margin-right: 9.5px;
	}
	&.is-active {
		color: #ffffff !important;
		background: rgba(255, 255, 255, 0.05);
		.test_taker {
			path {
				fill: #fff !important;
				stroke: transparent !important;
			}
		}
		svg {
			path {
				stroke: #ffffff !important;
			}
		}
	}
`;
const ItemsOut = styled.a`
	padding: 10px 15px;
	display: block;
	background: rgba(255, 255, 255, 0);
	margin-bottom: 1rem;
	border-radius: 6px;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	color: #8f9bb3 !important;
	transition: all 0.4s linear;
	&:hover {
		background: rgba(255, 255, 255, 0.03);
	}
	svg {
		display: inline-block;
		margin-right: 9.5px;
	}
	&.is-active {
		color: #ffffff !important;
		background: rgba(255, 255, 255, 0.05);
		svg {
			path {
				stroke: #ffffff !important;
			}
		}
	}
`;

SidebarLayout.propTypes = propTypes;

const mapStateToProps = createStructuredSelector({
	count: selectCurrentCount,
	hidden: selectHidden,
});

export default connect(mapStateToProps, { logOutUser, getNotifications })(
	SidebarLayout
);
