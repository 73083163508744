/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isEmpty from 'codewonders-helpers/bundle-cjs/helpers/is-empty';
import styled from 'styled-components';
import history from 'redux/history';

/* --------------------------- Image dependencies --------------------------- */
import { ReactComponent as CodeTag } from 'assets/icons/code-tag.svg';
import { ReactComponent as Utils } from 'assets/icons/behaviour-tag.svg';
import { ReactComponent as Close } from 'assets/icons/close-circle.svg';
import { ReactComponent as JungTag } from 'assets/icons/jung-tag.svg';

/* ------------------------- NavbarLayout propTypes ------------------------- */
const propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	data: PropTypes.object,
};

/* ------------------------ NavbarLayout defaultProps ----------------------- */
const defaultProps = {
	children: [],
	css: null,
};

const ResultLayout = memo((props) => {
	const { children, data } = props;

	return (
		<>
			<Nav>
				{!isEmpty(data) && (
					<div className="collapse__nav container">
						<div>
							<div className="media">
								{data?.test?.category === 'Technical' ? (
									<CodeTag className="tags__card" />
								) : data?.test?.category?.name === 'Personality' ? (
									<JungTag className="tags__card" />
								) : (
									<Utils className="tags__card" />
								)}
								<div className="media-body">
									<h3>{data?.test?.title}</h3>
									<p>{data?.test?.category?.name}</p>
								</div>
							</div>
						</div>

						<Items className="ml-auto">
							<Item>
								<Close
									onClick={() => history.push('/dashboard/my-tests')}
									style={{ cursor: 'pointer' }}
								/>
							</Item>
						</Items>
					</div>
				)}
			</Nav>
			<Main>
				<div className="container">{children}</div>
			</Main>
		</>
	);
});

ResultLayout.defaultProps = defaultProps;

ResultLayout.propTypes = propTypes;

export const Nav = styled.nav`
	padding: 15px 0;
	position: fixed;
	min-height: 70px;
	top: 0;
	z-index: 999;
	background: #ffffff;
	border: 1px solid #edf1f7;

	display: flex;
	align-items: center;

	right: 0;
	width: calc(100%);
	.collapse__nav {
		display: flex;
		width: 100%;
		align-items: center;
		h3 {
			svg {
				margin-top: -4px;
				cursor: pointer;
				margin-right: 2px;
			}
		}
	}

	.media {
		svg {
			margin-right: 1rem;
		}
		h3 {
			font-family: Roboto Mono;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			/* identical to box height, or 24px */
			margin: 0;
			margin-bottom: 5px;
			color: #151a30;
		}
		p {
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 17px;
			/* identical to box height */

			color: #151a30;
			margin: 0;
			opacity: 0.7;
		}
	}
`;

export const Items = styled.div`
	display: flex;
	span:last-child {
		margin-right: 0 !important;
	}
	svg {
		&.icons {
			width: 15px;
			cursor: pointer;
		}
	}
`;

export const Item = styled.span`
	margin: 0 0.7rem;
	button {
		background: rgba(143, 155, 179, 0);
		border-radius: 4px;
		font-weight: 400;
		font-size: 15px;
		border: 1px solid #c2ccd9;
		line-height: 19px;
		padding: 8px 18px;
		color: #3e4857;
		box-shadow: none !important;
		svg {
			margin-right: 4px;
		}
		box-shadow: none !important;
	}

	.icons {
		margin: 10px 0;
	}
`;

const Main = styled.main`
	background: #fafafa;
	min-height: 100vh;
	padding: 8rem 0 2rem;
`;

export default withRouter(ResultLayout);
