/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

/* -------------------------- Internal Dependencies ------------------------- */
import { initGA, logPageView } from 'utils/analytics';

/* ----------------------------- SEO  PropTypes ----------------------------- */
const propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
};

const SEO = ({ title, description }) => {
	const logPage = useCallback(() => {
		if (!window.GA_INITIALIZED) {
			initGA();
			window.GA_INITIALIZED = true;
		}
		logPageView();
	}, []);

	useEffect(() => {
		logPage();
	}, [logPage]);

	return (
		<Helmet>
			<title>
				{title ? `${title} ` : ' Making assesments faster '} &bull; Tunga
				Testing
			</title>
			<meta
				name="description"
				content={
					description
						? `${description}`
						: 'We are creating and crafting a scalable and robust application to help developers take assessments and generate Skill IQ results at a blazing fast speed and best user experience.'
				}
			/>
			<meta
				property="og:description"
				content={
					description
						? `${description}`
						: 'We are creating and crafting a scalable and robust application to help developers take assessments and generate Skill IQ results at a blazing fast speed and best user experience.'
				}
			/>
		</Helmet>
	);
};

SEO.propTypes = propTypes;
export default SEO;
