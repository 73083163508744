/* -------------------------- External Dependencies ------------------------- */
import { v4 } from 'uuid';

/* -------------------------- Internal Dependencies ------------------------- */
import { SET_ALERT, REMOVE_ALERT } from './types';

/**
 * Set Alert
 * @param {String} msg
 * @param {String} alertType
 */
export const setAlert = (msg, alertType) => {
	return (dispatch) => {
		const id = v4();

		dispatch({
			type: SET_ALERT,
			payload: { msg: msg ?? 'An Error Occured', alertType, id },
		});
		setTimeout(() => {
			dispatch({
				type: REMOVE_ALERT,
				payload: id,
			});
		}, 8000);
	};
};

/**
 * Remove Alert
 * @param {String} id
 */
export const removeAlert = (id) => ({
	type: REMOVE_ALERT,
	payload: id,
});
