/* eslint-disable import/no-cycle */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

/* -------------------------- Internal Dependencies ------------------------- */
import Loader from 'components/loader';
import NavLayout, {
	SearchContext,
} from 'components/layout/dashboard-layout/navbar';
import TestItems from './components/test-items';
import {
	selectCurrentTests,
	selectisLoading,
} from 'redux/developp-tests/selector';
import { getTests } from 'redux/developp-tests/actions';
import TabWrapper, { TabItem } from 'components/tabs';
import SEO from 'components/seo';
import FilterTest from 'components/layout/dashboard-layout/component/filter-test';
import useGlobal, { globalFilter } from 'utils/useGlobal';
import isEmpty from 'codewonders-helpers/bundle-cjs/helpers/is-empty';
import EmptyState from 'components/empty-state';

/* ----------------------------- Home propTypes ----------------------------- */
const propTypes = {
	tests: PropTypes.any,
	getTests: PropTypes.func,
	isLoading: PropTypes.bool,
};

const Home = ({ tests, getTests: loadTests, isLoading }) => {
	const [activeTab, setActiveTab] = useState('all');
	const [searchInstance] = useGlobal(SearchContext);
	const [show, setShow] = useState(false);
	const [filter, setFilter] = useGlobal(globalFilter);

	useEffect(() => {
		setFilter([]);
		return () => {
			setFilter([]);
		};
		// eslint-disable-next-line
	}, [activeTab]);

	useEffect(() => {
		if (activeTab === 'all' && filter.length === 0) {
			loadTests();
		}
		if (activeTab === 'essential' && filter.length === 0) {
			setFilter([
				...filter,
				{
					section: 'essential',
					name: 'Essential',
					id: true,
				},
			]);
			loadTests(0, 10, ['&essential=true']);
		}
		// eslint-disable-next-line
	}, [filter, loadTests, activeTab]);

	useEffect(() => {
		if (!isEmpty(filter) && activeTab === 'all') {
			loadTests(
				0,
				10,
				filter.map((itm) => `&${itm.section}=${itm.id}`)
			);
		}
		if (!isEmpty(filter) && activeTab === 'essential') {
			loadTests(
				0,
				10,
				[...filter, { section: 'essential', id: true }].map(
					(itm) => `&${itm.section}=${itm.id}`
				)
			);
		}
		// eslint-disable-next-line
	}, [filter]);

	return (
		<Container>
			<SEO title="All Tests" />
			<NavLayout title="Tests" onClick={() => setShow(true)} />
			<FilterSlate />
			<TabWrapper className="custom__tab">
				<TabItem
					eventKey="all-tests"
					className="margin-3rem"
					title="All Tests"
					click={() => setActiveTab('all')}
				>
					<>
						{!isEmpty(filter) && <div className="spacer" />}
						{!isLoading ? (
							<>
								{tests?.count > 0 ? (
									<TestItems
										data={tests.results}
										count={tests.count}
										query={filter.map((itm) => `&${itm.section}=${itm.id}`)}
									/>
								) : (
									<>
										{!isLoading && tests?.count === 0 ? (
											<>
												{searchInstance === '' ? (
													<EmptyState text="No Test Yet." />
												) : (
													<EmptyState
														text={`No Test with the name "${searchInstance}" yet.`}
													/>
												)}
											</>
										) : (
											<Loader />
										)}
									</>
								)}
							</>
						) : (
							<Loader />
						)}
					</>
				</TabItem>
				<TabItem
					eventKey="essential"
					className="margin-3rem"
					title="Essential"
					click={() => setActiveTab('essential')}
				>
					<>
						{!isEmpty(filter) && <div className="spacer" />}

						{!isLoading ? (
							<>
								{tests?.count > 0 ? (
									<TestItems
										data={tests.results}
										count={tests.count}
										query={filter.map((itm) => `&${itm.section}=${itm.id}`)}
									/>
								) : (
									<>
										{!isLoading && tests?.count === 0 ? (
											<>
												{searchInstance === '' ? (
													<EmptyState text="No Test Yet." />
												) : (
													<EmptyState
														text={`No Test with the name "${searchInstance}" yet.`}
													/>
												)}
											</>
										) : (
											<Loader />
										)}
									</>
								)}
							</>
						) : (
							<Loader />
						)}
					</>
				</TabItem>
				{/* <TabItem
					eventKey="basics"
					className="margin-3rem"
					title="Basics"
					disabled
				>
					{tests.length > 0 ? <TestItems data={tests} /> : <Loader />}
				</TabItem> */}
				{/* <TabItem
					eventKey="bookmarked"
					className="margin-3rem"
					title="Bookmarked"
					disabled
				>
					{tests.length > 0 ? <TestItems data={tests} /> : <Loader />}
				</TabItem> */}
			</TabWrapper>
			<FilterTest show={show} closeShow={() => setShow(false)} />
		</Container>
	);
};

export const FilterSlate = () => {
	const [filter, setFilter] = useGlobal(globalFilter);

	const removeItem = (id) => {
		setFilter(filter.filter((item) => item.id !== id));
	};
	return (
		<>
			<FilterBar className="filter__bar">
				{!isEmpty(filter) &&
					filter.map((item) => (
						<button
							className="btn btn-filter-item fadeIn"
							onClick={() => removeItem(item.id)}
						>
							{item.name}
							<span>&times;</span>
						</button>
					))}
			</FilterBar>
		</>
	);
};

const Container = styled.div`
	position: relative;
	.col-md-3 {
		margin-bottom: 1.5rem;
	}
	.margin-3rem {
		margin-top: 1.6rem !important;
	}
	.spacer {
		margin: 5rem 0;
	}

	.custom__tab {
		background: white;
		position: fixed;
		z-index: 9999;
		margin-top: -1.6rem;
		width: calc(100% - var(--sidebar-width));
		right: 0;
		border-bottom: 1px solid #edf1f7;
		padding: 0 28px;
		@media screen and (max-width: 935px) {
			padding: 0 13px;
		}
	}
	h4 {
		font-family: var(--font-secondary) !important;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		/* or 24px */

		color: #151a30;
	}
	p {
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 17px;
		/* identical to box height */
		margin-bottom: 1.5rem;

		color: #3e4857;
	}
	.other__action {
		span {
			display: block;
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 17px;
			/* identical to box height */

			color: #3e4857;
			margin-top: 1rem;
			svg {
				margin-right: 3px;
				width: 24px;
			}
		}
	}
	.tags__card {
		display: block;
		margin-bottom: 1.8rem;
	}
`;

const FilterBar = styled.div`
	position: absolute;
	/* height: 70px; */
	width: 100%;
	background: #fafafa;
	z-index: 9;
	top: 1rem;
	padding: 20px 0;

	.btn-filter-item {
		background: rgba(72, 104, 248, 0.05);
		border: 1px solid #4868f8;
		box-sizing: border-box;
		border-radius: 50px;
		padding: 8px 10px 9px 15px;
		font-size: 13px;
		/* color: red; */
		margin-bottom: 5px;
		margin-right: 1rem;
		color: #4868f8;
		font-family: var(--font-secondary);

		span {
			/* font-size: 10px;
			font-weight: 900;
			padding: 2px 6px; */
			font-size: 14px;
			font-weight: 900;

			padding: 0px 5px;
			cursor: pointer;
			border: 2px solid #4868f8;
			/* transform: rotate(90deg); */
			border-radius: 50%;
			margin-left: 10px;
			margin-bottom: 0;
		}
	}
`;

export const ActionCards = styled.button`
	/* width: 96%;
	text-align: center;
	border-radius: 5px; */
	cursor: pointer;
	text-align: center;

	margin: 0 auto;
	position: absolute;
	bottom: 0;
	left: 0;
	border: none;
	font-family: 'HK Grotesk';
	width: 100%;
	z-index: 9;
	background: #fafafa;
	color: #052d64;
	font-size: 15px;
	font-weight: 600;
	display: block;
	padding: 10px;
	&.active {
		background: #fafafa;
		color: #052d64;
		font-size: 15px;
		font-weight: 600;
	}
`;

Home.propTypes = propTypes;

const mapStateToProps = createStructuredSelector({
	tests: selectCurrentTests,
	isLoading: selectisLoading,
});

export default connect(mapStateToProps, { getTests })(Home);
