/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { isEmpty } from 'codewonders-helpers';

/* -------------------------- Internal Dependencies ------------------------- */
import BASE_URL from 'services/config';
import api from 'services/api';
import { TestActions } from 'redux/developp-tests/types';
import history from 'redux/history';
import { token } from 'utils/user_persist';
import {
	toString,
	formatSkills,
	formatQuestion,
	sanitizeTest,
	getError,
} from 'utils';
import { setAlert } from 'redux/alert/actions';
import { getTestQuestions } from './';

/**
 * Get Users Tests Question by ID
 * @function
 * @async
 * @param {String} slug
 * @param {String} id
 * @returns {ObjectConstructor}
 */
export const getTestQuestionById = (slug, id) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.UPDATE_THIS_QUESTION,
			payload: { loading: true },
		});

		const url = `${BASE_URL}/api/tests/${id}/questions/${slug}`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);

			await dispatch({
				type: TestActions.UPDATE_THIS_QUESTION,
				payload: {
					loading: false,
					data: { ...formatQuestion(response.data) },
				},
			});
			return { ...formatQuestion(response.data) };
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.FETCH_CURRENT_TEST,
				payload: err,
			});
		}
	};
};
/**
 * @function
 * @async
 * @returns {Array} [All Categories]
 */
export const getCategories = () => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_CATEGORY,
		});
		const url = `${BASE_URL}/api/categories`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.GET_CATEGORY_SUCCESS,
				payload: {
					data: response.data.results,
				},
			});
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.GET_CATEGORY_FAIL,
				payload: err,
			});
		}
	};
};

/**
 * @function
 * @returns {Array} [Levels]
 */
export const getLevels = () => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_LEVEL,
		});
		const url = `${BASE_URL}/api/levels`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.GET_LEVEL_SUCCESS,
				payload: {
					data: response.data.results,
				},
			});
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.GET_LEVEL_FAIL,
				payload: err,
			});
		}
	};
};

/**
 * @function
 * @async
 * @returns {Array} [Get Skills]
 */
export const getSkills = () => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_SKILL,
		});
		const url = `${BASE_URL}/api/skills`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.GET_SKILL_SUCCESS,
				payload: {
					data: response.data.results,
				},
			});
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.GET_SKILL_FAIL,
				payload: err,
			});
		}
	};
};

/**
 * @function
 * @returns {Array} [Score Methods]
 */
export const getScoreMethods = () => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_SCORE_METHOD,
		});
		const url = `${BASE_URL}/api/scores?limit=20&offset=0`;
		const options = api.options('GET', token, {
			data: [],
		});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.GET_SCORE_METHOD_SUCCESS,
				payload: {
					data: response.data.results,
				},
			});
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.GET_SCORE_METHOD_FAIL,
				payload: err,
			});
		}
	};
};

/**
 * Create a new test or update existing test
 * @function
 * @param {Object} data
 */
export const createOrUpdateTest = (data = {}) => {
	return async (dispatch, getState) => {
		dispatch({
			type: TestActions.CREATE_OR_UPDATE_TEST,
		});
		const state = getState();
		const { current_test } = state.tests;

		const { skills: skill } = data;
		const skills = toString(skill);
		let url = '';
		let message;
		let options = {};
		if (!isEmpty(current_test)) {
			const { slug } = current_test;
			url = `${BASE_URL}/api/tests/${slug}`;
			const test = {
				...sanitizeTest(data),
			};
			options = api.options('PATCH', token, {
				data: {
					...test,
				},
			});
			message = 'Successfully Updated Test';
		} else {
			url = `${BASE_URL}/api/tests`;
			options = api.options('POST', token, {
				data: {
					...data,
					skills,
				},
			});
			message = 'Test Created Successfully';
		}

		try {
			const response = await api.request(url, options);
			const { skills: skl } = response.data;

			if (isEmpty(current_test)) {
				dispatch(addSegment(response.data.slug), 'Segment 1');
			}
			dispatch(setAlert(message, 'success'));
			dispatch({
				type: TestActions.CREATE_OR_UPDATE_TEST_SUCCESS,
				payload: {
					data: {
						...response.data,
						skills: formatSkills(skl),
					},
				},
			});
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.CREATE_OR_UPDATE_TEST_FAIL,
			});
		}
	};
};

/**
 * Add or update question if (question) update else add question
 * @function
 * @param {Object} data
 */
export const addOrUpdateQuestion = (data) => {
	return async (dispatch, getState) => {
		const state = getState();
		const { current_test, current_question } = state.tests;
		const { slug, id: test_id } = current_test;
		const qn_staus = window.location.pathname.split('/')[4];
		let url = '';
		let message;
		let options = {};

		dispatch({
			type: TestActions.ADD_OR_UPDATE_QUESTION,
		});

		if (slug) {
			if (!qn_staus.includes('new') && current_question?.id) {
				const { id } = current_question;
				url = `${BASE_URL}/api/tests/${slug}/questions/${id}`;

				options = api.options('PATCH', token, {
					data,
				});
				message = 'Question Updated Successfully.';
			} else {
				url = `${BASE_URL}/api/tests/${slug}/questions`;

				options = api.options('POST', token, {
					data: {
						...data,
						test: test_id,
					},
				});
				message = 'Question Created Successfully.';
			}

			try {
				const response = await api.request(url, options);

				await dispatch({
					type: TestActions.ADD_OR_UPDATE_QUESTION_SUCCESS,
					payload: {
						data: {
							...formatQuestion(response.data),
						},
					},
				});

				dispatch(setAlert(message, 'success'));

				await dispatch(getTestQuestions());
				await dispatch(getSegments(slug));

				if (qn_staus.includes('new')) {
					history.push(
						`/edit/test/${slug}/${response.data.id}/${data.segment}`
					);
				}
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestActions.ADD_OR_UPDATE_QUESTION_FAIL,
				});
			}
		}
	};
};

/**
 * Select Test and push data to its question reducer
 * @function
 * @param {Object} data
 */
export const selectTest = (data) => {
	return async (dispatch) => {
		const { slug } = data;

		dispatch({ type: TestActions.SELECT_QUESTION, payload: '' });
		dispatch({
			type: TestActions.SELECT_TEST,
			payload: {
				data: {
					...data,
				},
			},
		});

		history.push(`/edit/test/${slug}`);
	};
};

/**
 * Selects a question and returns it to its reducer for the question action
 * @function
 * @param {Number} id
 */
export const selectTestQuestion = (id = 0) => {
	return async (dispatch, getState) => {
		const state = getState();
		const {
			current_questions: { results },
		} = state.tests;
		const data = results.find((question) => question.id === id);
		dispatch({
			type: TestActions.SELECT_TEST_QUESTION,
			payload: {
				data: {
					...formatQuestion(data),
				},
			},
		});
	};
};

/**
 * @function
 * @param {Object} data
 * @returns {Object} [Question Data]
 */
export const deleteTestInstruction = (data = {}) => {
	return async (dispatch, getState) => {
		const state = getState();
		const { current_test } = state.tests;
		const { slug } = current_test;

		const { id } = data;
		if (!isEmpty(current_test)) {
			dispatch({
				type: TestActions.DELETE_TEST_INSTRUCTION,
			});

			const url = `${BASE_URL}/api/tests/${slug}/instructions/${id}`;
			const options = api.options('DELETE', token, {});
			try {
				const response = await api.request(url, options);
				dispatch({
					type: TestActions.DELETE_TEST_INSTRUCTION_SUCCESS,
					payload: {
						data: response.data,
					},
				});
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestActions.DELETE_TEST_INSTRUCTION_FAIL,
					payload: err,
				});
			}
		}
	};
};

/**
 *
 * @param {Number} instruction
 * @function
 * @returns [Question Data]
 */

export const addTestInstruction = (instruction) => {
	return async (dispatch, getState) => {
		const state = getState();
		const { current_test } = state.tests;
		const { slug } = current_test;

		const data = {
			instruction,
		};
		if (!isEmpty(current_test)) {
			dispatch({
				type: TestActions.ADD_TEST_INSTRUCTION,
			});
			const url = `${BASE_URL}/api/tests/${slug}/instructions`;
			const options = api.options('POST', token, {
				data,
			});
			try {
				const { instructions } = current_test;
				const response = await api.request(url, options);
				const updatedInstructions = [...instructions, response.data];
				dispatch({
					type: TestActions.ADD_TEST_INSTRUCTION_SUCCESS,
					payload: {
						data: { ...current_test, instructions: updatedInstructions },
					},
				});
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestActions.ADD_TEST_INSTRUCTION_FAIL,
					payload: err,
				});
			}
		}
	};
};

/**
 * @function
 * @param {Object} data
 */
export const selectQuestion = (data = {}) => {
	return (dispatch) => {
		const { question, question_id } = data;
		dispatch({ type: TestActions.SELECT_QUESTION, payload: question });

		if (question_id && question_id !== 'new') {
			dispatch(selectTestQuestion(question_id));
		}

		if (question_id === 'new') {
			dispatch(newQuestion());
		}
	};
};

/**
 * @function
 * @param {*} data
 */
export const updateTestInstruction = (data = {}) => {
	return async (dispatch, getState) => {
		const state = getState();
		const { current_test } = state.tests;
		const { slug, instructions } = current_test;
		let instruction_id = '';
		const { id, instruction } = data;
		if (instruction !== '') {
			if (!isEmpty(current_test) && id !== undefined) {
				if (!id) {
					instruction_id = instructions[instructions.length - 1].id;
				} else {
					instruction_id = id;
				}
				dispatch({
					type: TestActions.UPDATE_TEST_INSTRUCTION,
				});
				const url = `${BASE_URL}/api/tests/${slug}/instructions/${instruction_id}`;
				const options = api.options('PATCH', token, {
					data: {
						instruction,
					},
				});
				try {
					const response = await api.request(url, options);
					dispatch({
						type: TestActions.UPDATE_TEST_INSTRUCTION_SUCCESS,
						payload: {
							data: response.data,
						},
					});
				} catch (err) {
					getError(dispatch, err);
					dispatch({
						type: TestActions.UPDATE_TEST_INSTRUCTION_FAIL,
						payload: err,
					});
				}
			} else {
				dispatch(addTestInstruction(instruction));
			}
		}
	};
};

/**
 * @function
 * @param {String} id
 * @param {String} index
 */
export const deleteQuestion = (id = '') => {
	return async (dispatch) => {
		const qn_id = window.location.pathname.split('/')[3];
		if (id) {
			dispatch({
				type: TestActions.DELETE_QUESTION,
				payload: true,
			});
			const url = `${BASE_URL}/api/tests/${qn_id}/questions/${id}`;
			const options = api.options('DELETE', token, {});
			try {
				const response = await api.request(url, options);
				await dispatch({
					type: TestActions.DELETE_QUESTION,
					payload: false,
				});
				dispatch(setAlert(response.data.message, 'success'));
				dispatch(getTestQuestions(qn_id));
				history.push(`/edit/test/${qn_id}`);
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestActions.DELETE_QUESTION,
					payload: false,
				});
			}
		}
	};
};

/**
 * @function
 * @param {String} id
 * @param {String} index
 */
export const deleteQuestionOption = (id = '') => {
	return async (dispatch) => {
		const qn_id = window.location.pathname.split('/')[4];
		if (id) {
			dispatch({
				type: TestActions.DELETE_QUESTION_OPTION,
			});
			const url = `${BASE_URL}/api/tests/questions/${qn_id}/options/${id}`;
			const options = api.options('DELETE', token, {});
			try {
				const response = await api.request(url, options);
				await dispatch({
					type: TestActions.DELETE_QUESTION_OPTION_SUCCESS,
					payload: {
						data: response.data,
					},
				});
				dispatch(setAlert('Question Option Deleted Successfully', 'success'));
				dispatch(getTestQuestions());
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestActions.DELETE_QUESTION_OPTION_FAIL,
					payload: err,
				});
			}
		}
	};
};

/**
 * @function
 * @param {Object} data
 * @returns [Question Data]
 */
export const updateQuestionOption = (data = {}) => {
	return async (dispatch) => {
		const qn_id = window.location.pathname.split('/')[4];
		const { id, option } = data;
		if (id && option) {
			dispatch({
				type: TestActions.UPDATE_QUESTION_OPTION,
			});
			const url = `${BASE_URL}/api/tests/questions/${qn_id}/options/${id}`;
			const options = api.options('PATCH', token, {
				data: {
					option,
				},
			});
			try {
				const response = await api.request(url, options);

				await dispatch({
					type: TestActions.UPDATE_QUESTION_OPTION_SUCCESS,
					payload: {
						data: response.data,
					},
				});
			} catch (err) {
				getError(dispatch, err);
				dispatch({
					type: TestActions.UPDATE_QUESTION_OPTION_FAIL,
					payload: err,
				});
			}
		}
	};
};

/**
 * @function
 * @param {Object} data
 */
export const publishTest = (data = {}) => {
	return async (dispatch, getState) => {
		const state = getState();
		const { current_test } = state.tests;
		const { slug } = current_test;
		dispatch({
			type: TestActions.PUBLISH_TEST,
		});
		const url = `${BASE_URL}/api/tests/${slug}`;
		const options = api.options('PATCH', token, {
			data,
		});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.PUBLISH_TEST_SUCCESS,
				payload: {
					data: response.data,
				},
			});
			dispatch(setAlert('Test Published Successfully', 'success'));
			history.push('/dashboard/my-tests');
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.PUBLISH_TEST_FAIL,
				payload: err,
			});
		}
	};
};

/**
 * @function
 * @returns [Question Data]
 */
export const newQuestion = () => {
	return (dispatch) => {
		dispatch({ type: TestActions.NEW_QUESTION });
	};
};

/**
 * @function
 * @returns [Root Page]
 */
export const handleBackButton = () => {
	return (dispatch) => {
		// eslint-disable-next-line no-restricted-globals
		history.push('/dashboard/my-tests');
		dispatch({
			type: TestActions.SELECT_QUESTION,
			payload: { question: null },
		});
	};
};

export const fetchCodingLanguages = () => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.FETCH_CODING_LANGUAGES,
		});
		const url = `${BASE_URL}/api/tests/language`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.FETCH_CODING_LANGUAGES_SUCCESS,
				payload: {
					data: [...response.data],
				},
			});
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.FETCH_CODING_LANGUAGES_FAIL,
				payload: err,
			});
		}
	};
};

/**
 * Get Segments
 * @param {String} slug
 */
export const getSegments = (slug) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.GET_SEGMENTS,
			payload: {
				loading: true,
			},
		});
		const url = `${BASE_URL}/api/tests/${slug}/segments`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.GET_SEGMENTS,
				payload: {
					loading: false,
					data: response.data,
				},
			});
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.GET_SEGMENTS,
				payload: {
					loading: false,
				},
			});
		}
	};
};

export const deleteSegmentById = (slug, id) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.DELETE_SEGMENTS,
			payload: {
				loading: true,
			},
		});
		const url = `${BASE_URL}/api/tests/${slug}/segments/${id}`;
		const options = api.options('DELETE', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: TestActions.DELETE_SEGMENTS,
				payload: {
					loading: false,
				},
			});
			dispatch(setAlert(response.data.message, 'success'));
			dispatch(getSegments(slug));
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.DELETE_SEGMENTS,
				payload: {
					loading: false,
				},
			});
		}
	};
};

export const addSegment = (slug, data) => {
	return async (dispatch) => {
		dispatch({
			type: TestActions.ADD_SEGMENTS,
			payload: {
				loading: true,
			},
		});
		const url = `${BASE_URL}/api/tests/${slug}/segments`;
		const options = api.options('POST', token, {
			data,
		});
		try {
			await api.request(url, options);
			dispatch({
				type: TestActions.ADD_SEGMENTS,
				payload: {
					loading: false,
				},
			});
			dispatch(setAlert('Segment Added Successfully', 'success'));
			dispatch(getSegments(slug));
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.ADD_SEGMENTS,
				payload: {
					loading: false,
				},
			});
		}
	};
};
export const moveSegment = (slug, id, data, segment) => {
	return async (dispatch) => {
		debugger;
		dispatch({
			type: TestActions.MOVE_SEGMENTS,
			payload: {
				loading: true,
			},
		});
		const url = `${BASE_URL}/api/tests/${slug}/questions/${id}`;
		const options = api.options('PATCH', token, {
			data,
		});

		try {
			await api.request(url, options);
			dispatch({
				type: TestActions.MOVE_SEGMENTS,
				payload: {
					loading: false,
				},
			});

			debugger;

			await Promise.all([
				dispatch(setAlert('Question Moved Successfully', 'success')),
				dispatch(getSegments(slug)),
				dispatch(getTestQuestions(slug)),
			]);

			history.push(
				`/edit/test/${slug}/${id}/${data.segment}/i?segment=${segment.segmentNum}`
			);
		} catch (err) {
			getError(dispatch, err);
			dispatch({
				type: TestActions.MOVE_SEGMENTS,
				payload: {
					loading: false,
				},
			});
		}
	};
};
