/* -------------------------------------------------------------------------- */
/*                            External Dependecies                            */
/* -------------------------------------------------------------------------- */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

/* --------------------------- Internal Dependency -------------------------- */
import isAuthenticated from 'utils/is-authenticated';

/* ---------------------------- Image Dependency ---------------------------- */
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';

/* -------------------------- AuthLayout PropTypes -------------------------- */
const propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

/* ------------------------- AuthLayout defaultProps ------------------------ */
const defaultProps = {
	children: <></>,
};

const AuthLayout = ({ children }) => {
	const { search } = useLocation();

	if (isAuthenticated()) {
		const nextParam = new URLSearchParams(search).get('next');
		return <Redirect to={nextParam || '/dashboard'} />;
	}

	return (
		<>
			<Wrapper>
				<div className="grid_layout d-block d-md-grid">
					<div className="grid-1 d-flex">
						<div className="child__item container" data-testid="auth-layout">
							<Logo className="mb-4" />
							{children}
						</div>
					</div>
					<div className="grid-2 d-none d-md-block">
						<img
							// eslint-disable-next-line global-require
							src={require('../../../assets/images/Body.png')}
							alt="layout banner"
						/>
					</div>
				</div>
			</Wrapper>
		</>
	);
};

const Wrapper = styled.div`
	.d-md-grid {
		@media (min-width: 768px) {
			display: grid !important;
		}
	}
	.grid_layout {
		display: grid;
		min-height: 100vh;
		grid-template-columns: 1fr 1.4fr;
		grid-template-rows: 100%;
		grid-column-gap: 0px;
		background: #fafafa;
		.grid {
			&-1 {
				display: flex;
				align-items: center;
				justify-content: center;
				@media (max-width: 768px) {
					height: 100vh !important;
				}
				.child__item {
					flex: 0.589 0;
					@media (max-width: 768px) {
						flex: 1 0 !important;
					}
				}
			}
			&-2 {
				img {
					width: 100%;
					height: 100vh;
					object-fit: cover;
				}
			}
		}
	}
`;

export const AuthStylingLayoutChildren = styled.div`
	h3 {
		font-style: normal;
		font-weight: 600;
		font-size: 23px;
		line-height: 150%;
		margin-bottom: 4px;
		margin-top: 2px;
		color: #151a30;
	}
	.intro__text {
		font-size: 15px;
		margin-bottom: 3rem;
		line-height: 150%;
		color: #3e4857;
	}
	.intro__link-ext {
		display: flex;
		justify-content: flex-end;
		margin-bottom: -23px;
		font-size: 14px;
		line-height: 150%;
		text-align: right;
		font-weight: 600;
		color: #4868f8;
	}
	.intro__link-link-out {
		font-size: 14px;
		margin-top: 1rem;
		text-align: center;
		font-weight: 300;
	}
	button {
		margin-top: 2.2rem;
	}
`;

AuthLayout.defaultProps = defaultProps;

AuthLayout.propTypes = propTypes;

export default AuthLayout;
