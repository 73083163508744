/* -------------------------- Internal Dependencies ------------------------- */
import { createSelector } from 'reselect';

export const selectTestResults = (state) => state.test_results;

export const selectCurrentTestReportUsers = createSelector(
	[selectTestResults],
	(tests) => tests.currentTestReportUsers.results
);

export const selectCurrentUserResult = createSelector(
	[selectTestResults],
	(tests) => tests.currentUserTestResult
);

export const selectCurrentReportStatus = createSelector(
	[selectTestResults],
	(tests) => tests.isLoading
);
export const selectCurrentResultState = createSelector(
	[selectTestResults],
	(tests) => tests.isLoadingResult
);
