/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

/* --------------------------- Internal Dependecy --------------------------- */
import NavbarLayout from './navbar';

/* -------------------------- TestLayout propTypes -------------------------- */
const propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	location: PropTypes.object,
};

/* ------------------------- TestLayout defaultProps ------------------------ */
const defaultProps = {
	children: <></>,
};

const TestLayout = ({ children, location }) => {
	const getRouteActions = location?.pathname?.includes('/tests/details');
	const getResultsRoutes = location?.pathname?.includes('/tests/results');
	return (
		<>
			<NavbarLayout showActions={getRouteActions} data-testid="tests" />
			<Main>
				{!getResultsRoutes ? (
					<Container>
						<Row className="justify-content-center">
							<Col md={8}>
								<Wrapper>{children}</Wrapper>
							</Col>
						</Row>
					</Container>
				) : (
					<Wrapper>{children}</Wrapper>
				)}
			</Main>
		</>
	);
};

const Main = styled.main`
	width: 100%;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	display: block;
	background: #fafafa;
	min-height: 100vh;
	.container {
		min-height: 100vh;
	}
`;
const Wrapper = styled.section`
	padding: 6rem 0px 0;
	@media (max-width: 786px) {
		padding: 5rem 0px 0 !important;
	}
`;

TestLayout.defaultProps = defaultProps;

TestLayout.propTypes = propTypes;
export default withRouter(TestLayout);
