const makeObservable = (target) => {
	let listeners = [];
	let value = target;

	const get = () => {
		return value;
	};

	const set = (newValue) => {
		if (value === newValue) return;
		value = newValue;
		listeners.forEach((l) => l(value));
	};

	const unsubscribe = (listenerFunc) => {
		listeners = listeners.filter((l) => l !== listenerFunc);
	};

	const subscribe = (listenerFunc) => {
		listeners.push(listenerFunc);
		return () => unsubscribe(listenerFunc);
	};

	return {
		get,
		set,
		subscribe,
	};
};

export default makeObservable;
