export const UserActions = {
	SET_CURRENT_USER: 'SET_CURRENT_USER',
	SIGN_IN: 'SIGN_IN',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAIL: 'SIGN_IN_FAIL',
	SIGN_OUT: 'SIGN_OUT',
	SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
	SIGN_OUT_FAIL: 'SIGN_OUT_FAIL',
	SIGN_UP: 'SIGN_UP',
	SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
	SIGN_UP_FAIL: 'SIGN_UP_FAIL',
	CHECK_USER_SESSION: 'CHECK_USER_SESSION',
	RESET_PASSWORD: 'RESET_PASSWORD',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',
	FORGOT_PASSWORD: 'FORGOT_PASSWORD',
	FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
	FORGOT_PASSWORD_FAIL: 'FORGOT_PASSWORD_FAIL',
	TOGGLE_SIDEBAR_HIDDEN: 'TOGGLE_SIDEBAR_HIDDEN',
	GET_USER: 'GET_USER',
	GET_ALL_USER: 'GET_ALL_USER',
	EDIT_USER: 'EDIT_USER',
	EDIT_USER_START: 'EDIT_USER_START',
	ACTIVATE_LOADING: 'ACTIVATE_LOADING',
	GET_USER_ERROR: 'GET_USER_ERROR',
	LOGOUT_USER: 'LOGOUT_USER',
	SELECT_QUESTION: 'SELECT_QUESTION',
	RESET_TO_TEST: 'RESET_TO_TEST',
	CLEAR_USER_ERROR: 'CLEAR_USER_ERROR',
};
