/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import { useState, useEffect, useMemo } from 'react';

/* --------------------------- Internal Dependency -------------------------- */
import makeObservable from 'utils/makeObservable';

export const globalTime = makeObservable({ time: 0 });
export const globalFilter = makeObservable([]);
export const globalAccordion = makeObservable(0);
const useGlobal = (store) => {
	const [global, setglobal] = useState(store.get());

	useEffect(() => {
		return store.subscribe(setglobal);
		// eslint-disable-next-line
	}, []);

	const setAction = useMemo(() => (state) => store.set(state), [store]);
	return [global, setAction];
};

export default useGlobal;
