/* -------------------------- Internal Dependencies ------------------------- */
import { TestActions } from './types';

export const initialState = {
	categories: [],
	coding_languages: [],
	current_question: {},
	current_questions: {
		results: [],
	},
	current_test: {},
	current_tests_shares: [],
	isLoading: false,
	isDetailsLoading: false,
	isCategoryLoading: false,
	levels: [],
	segments: [],
	isMyTestLoading: false,
	my_tests: {},
	question: 'multiple',
	score_methods: [],
	skills: [],
	pending_invites: {},
	past_tests: [],
	tests: {
		count: 0,
		results: [],
	},
};

const testsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	const FAIL_RESPONSE = {
		...state,
		isLoading: false,
	};
	switch (type) {
		case TestActions.GET_TEST: {
			return {
				...state,
				isLoading: payload.isLoading,
				tests: payload.data,
				current_question: {},
				current_test: {},
				current_questions: { results: [] },
			};
		}
		case TestActions.GET_MORE_TESTS: {
			return {
				...state,
				isLoading: payload.isLoading,
				tests: {
					...state.tests,
					previous: payload.data.previous,
					next: payload.data.next,
					results: state.tests.results.concat(...payload.data.results),
				},
			};
		}
		case TestActions.GET_TEST_BY_ID: {
			return {
				...state,
				isDetailsLoading: payload.isLoading,
				current_test: payload.data,
			};
		}
		case TestActions.GET_TESTS_SHARE: {
			return {
				...state,
				isLoading: payload.isLoading,
				current_tests_shares: payload.data,
			};
		}
		case TestActions.DELETE_SHARE_BY_ID: {
			return { ...state, isLoading: payload };
		}
		case TestActions.GET_TEST_ERROR: {
			return { ...state, isLoading: false };
		}
		case TestActions.GET_PAST_TEST: {
			return {
				...state,
				past_tests: payload.data,
				isLoading: payload.loading,
			};
		}
		case TestActions.GET_PENDING_INVITES: {
			return {
				...state,
				pending_invites: payload.data,
				isLoading: payload.loading,
			};
		}
		case TestActions.GET_CATEGORY: {
			return { ...state, isCategoryLoading: true };
		}
		case TestActions.DELETE_TEST_BY_SLUG: {
			return { ...state, isLoading: payload.loading };
		}
		case TestActions.SHARE_TEST: {
			return { ...state, isLoading: payload };
		}
		case TestActions.CHANGE_PERMISSION: {
			return { ...state, isLoading: payload };
		}
		case TestActions.GET_CATEGORY_SUCCESS: {
			return { ...state, isCategoryLoading: false, categories: payload.data };
		}
		case TestActions.GET_CATEGORY_FAIL: {
			return { ...state, isCategoryLoading: false };
		}

		case TestActions.GET_LEVEL_FAIL:
		case TestActions.GET_SKILL_FAIL:
		case TestActions.GET_SCORE_METHOD_FAIL:
		case TestActions.CREATE_OR_UPDATE_TEST_FAIL:
		case TestActions.ADD_OR_UPDATE_QUESTION_FAIL:
		case TestActions.GET_TEST_QUESTION_FAIL:
		case TestActions.DELETE_TEST_INSTRUCTION_FAIL:
		case TestActions.ADD_TEST_INSTRUCTION_FAIL:
		case TestActions.FETCH_CURRENT_TEST_FAIL:
		case TestActions.UPDATE_TEST_INSTRUCTION_FAIL:
		case TestActions.UPDATE_QUESTION_OPTION_FAIL:
		case TestActions.ADD_QUESTION_OPTION_FAIL:
		case TestActions.DELETE_QUESTION_OPTION_FAIL:
		case TestActions.PUBLISH_TEST_FAIL:
		case TestActions.FETCH_USER_TESTS_FAIL:
		case TestActions.FETCH_CODING_LANGUAGES_FAIL: {
			return FAIL_RESPONSE;
		}
		case TestActions.GET_LEVEL: {
			return { ...state, isLoading: true };
		}
		case TestActions.RE_SHARE_TEST: {
			return { ...state, isLoading: payload };
		}
		case TestActions.GET_LEVEL_SUCCESS: {
			return { ...state, isLoading: false, levels: payload.data };
		}

		case TestActions.SELECT_QUESTION: {
			return { ...state, question: payload, isLoading: true };
		}
		case TestActions.GET_SKILL: {
			return { ...state, isLoading: true };
		}
		case TestActions.GET_SKILL_SUCCESS: {
			return { ...state, isLoading: false, skills: payload.data };
		}

		case TestActions.GET_SCORE_METHOD: {
			return { ...state, isLoading: true };
		}
		case TestActions.GET_SCORE_METHOD_SUCCESS: {
			return { ...state, isLoading: false, score_methods: payload.data };
		}

		case TestActions.CREATE_OR_UPDATE_TEST: {
			return { ...state, isLoading: true };
		}

		case TestActions.CREATE_OR_UPDATE_TEST_SUCCESS: {
			return { ...state, isLoading: false, current_test: payload.data };
		}

		case TestActions.ADD_OR_UPDATE_QUESTION: {
			return { ...state, isLoading: true };
		}

		case TestActions.ADD_OR_UPDATE_QUESTION_SUCCESS: {
			return { ...state, isLoading: false, current_question: payload.data };
		}

		case TestActions.SELECT_TEST: {
			return {
				...state,
				current_test: payload.data,
				current_question: {},
				current_questions: { results: [] },
			};
		}

		case TestActions.SELECT_TEST_QUESTION: {
			return { ...state, current_question: payload.data };
		}

		case TestActions.GET_TEST_QUESTION: {
			return { ...state, isLoading: true };
		}

		case TestActions.GET_TEST_QUESTION_SUCCESS: {
			return { ...state, isLoading: false, current_questions: payload.data };
		}
		case TestActions.DELETE_TEST_INSTRUCTION: {
			return { ...state, isLoading: false };
		}
		case TestActions.DELETE_TEST_INSTRUCTION_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case TestActions.ADD_TEST_INSTRUCTION: {
			return { ...state, isLoading: false };
		}
		case TestActions.ADD_TEST_INSTRUCTION_SUCCESS: {
			return { ...state, isLoading: false, current_test: payload.data };
		}

		case TestActions.FETCH_CURRENT_TEST: {
			return { ...state, isLoading: true };
		}
		case TestActions.FETCH_CURRENT_TEST_SUCCESS: {
			return { ...state, isLoading: false, current_question: payload.data };
		}

		case TestActions.UPDATE_TEST_INSTRUCTION: {
			return { ...state, isLoading: false };
		}
		case TestActions.UPDATE_TEST_INSTRUCTION_SUCCESS: {
			return { ...state, isLoading: false };
		}
		case TestActions.UPDATE_QUESTION_OPTION: {
			return { ...state, isLoading: false };
		}
		case TestActions.UPDATE_QUESTION_OPTION_SUCCESS: {
			return { ...state };
		}
		case TestActions.ADD_QUESTION_OPTION: {
			return { ...state, isLoading: false };
		}
		case TestActions.ADD_QUESTION_OPTION_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case TestActions.DELETE_QUESTION_OPTION: {
			return { ...state, isLoading: false };
		}
		case TestActions.DELETE_QUESTION: {
			return { ...state, isLoading: payload };
		}

		case TestActions.DELETE_QUESTION_OPTION_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case TestActions.PUBLISH_TEST: {
			return { ...state, isLoading: true };
		}
		case TestActions.PUBLISH_TEST_SUCCESS: {
			return { ...state, isLoading: false };
		}

		case TestActions.NEW_QUESTION: {
			return { ...state, current_question: {}, isLoading: false };
		}
		case TestActions.FETCH_USER_TESTS: {
			return { ...state, isMyTestLoading: true };
		}
		case TestActions.FETCH_USER_TESTS_SUCCESS: {
			return {
				...state,
				isMyTestLoading: false,
				my_tests: payload.data || state.my_tests,
			};
		}
		case TestActions.CLEAR_TEST_SESSIONS: {
			return {
				...state,
				current_question: {},
				current_test: {},
				current_questions: { results: [] },
				segments: [],
			};
		}
		case TestActions.FETCH_CODING_LANGUAGES: {
			return { ...state, isLoading: true };
		}
		case TestActions.FETCH_CODING_LANGUAGES_SUCCESS: {
			return { ...state, isLoading: false, coding_languages: payload.data };
		}
		case TestActions.GET_SEGMENTS: {
			return {
				...state,
				isLoading: payload.loading,
				segments: payload.data,
			};
		}
		case TestActions.DELETE_SEGMENTS: {
			return {
				...state,
				isLoading: payload.loading,
			};
		}
		case TestActions.MOVE_SEGMENTS: {
			return {
				...state,
				isLoading: payload.loading,
			};
		}
		case TestActions.ADD_SEGMENTS: {
			return {
				...state,
				isLoading: payload.loading,
			};
		}
		case TestActions.UPDATE_THIS_QUESTION: {
			return {
				...state,
				isLoading: payload.loading,
				current_question: payload.data,
			};
		}
		default:
			return { ...state };
	}
};

export default testsReducer;
