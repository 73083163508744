/* -------------------------- External Dependencies ------------------------- */
import { getState, setState, clearState } from 'codewonders-helpers';

/**
 * @function
 */
export const GET_CURRENT_TEST = () => {
	const token = getState('CURRENT_TEST')
		? window.atob(getState('CURRENT_TEST'))
		: getState('CURRENT_TEST');
	return JSON.parse(token);
};

/**
 *
 * @param {String} val
 */
export const SET_CURRENT_TEST = (val) =>
	setState('CURRENT_TEST', window.btoa(JSON.stringify(val)));

/**
 * @funtion
 */
export const CLEAR_CURRENT_TEST = () => clearState('CURRENT_TEST');

/**
 * @funtion
 */
export const GET_CURRENT_QUESTION = () => {
	const token = getState('CURRENT_QUESTION')
		? window.atob(getState('CURRENT_QUESTION'))
		: getState('CURRENT_QUESTION');

	return JSON.parse(token);
};

/**
 * @param {String} val
 */

export const SET_CURRENT_QUESTION = (val) =>
	setState('CURRENT_QUESTION', window.btoa(JSON.stringify(val)));

/**
 * @function
 */
export const CLEAR_CURRENT_QUESTION = () => clearState('CURRENT_QUESTION');
