/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'codewonders-helpers/bundle-cjs/helpers/is-empty';

/* -------------------------- Internal Dependencies ------------------------- */
import { getUserTestResultsById } from 'redux/test-results/actions';
import { user_id } from 'utils/user_persist';
import {
	selectCurrentResultState,
	selectCurrentUserResult,
} from 'redux/test-results/selectors';
import Loader from 'components/loader';
import Results from 'pages/about-test/results/components/results-content';
import ResultLayout from '../components/takers-result-layout';
import Card from 'components/card';
import { ACADEMY_URL } from 'services/config';
import { trunc } from 'utils';
/* ---------------------------- Image Dependency ---------------------------- */
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up-right.svg';

/* ------------------------ TestTakerResult propTypes ----------------------- */
const propTypes = {
	getUserTestResultsById: PropTypes.func,
	isLoading: PropTypes.bool,
	result: PropTypes.object,
};

const TestTakerResult = ({
	getUserTestResultsById: getTestResult,
	isLoading,
	result,
}) => {
	const { id } = useParams();
	const PeronalityTest = id === 'jung-personality-test';

	useEffect(() => {
		getTestResult(id, user_id, PeronalityTest);
	}, [getTestResult, id, PeronalityTest]);

	return (
		<Wrapper>
			{!isLoading ? (
				<ResultLayout
					data={(!isEmpty(result?.results) && result?.results[0]) || result}
				>
					<div className="result__section">
						{!PeronalityTest && <h3 className="mb-md-4">Test summary</h3>}
						<Results data={result} isMyTest />
						{!isEmpty(result?.recommended_courses) && (
							<>
								<h3 className="mb-4">
									Recommended courses <ArrowUp />{' '}
								</h3>
								<div className="grid">
									{result?.recommended_courses.map((course) => (
										<a
											href={ACADEMY_URL + course.slug}
											target="_blank"
											rel="noopener noreferrer"
										>
											<CardStyle
												minHeight="280px"
												key={course.id}
												nopaddingParent
											>
												<img src={course.image} alt={course.title} />
												<div className="card-body">
													<h4>{course.title}</h4>
													<p>{trunc(course.description, 80)}</p>
												</div>
											</CardStyle>
										</a>
									))}
								</div>
							</>
						)}
					</div>
				</ResultLayout>
			) : (
				<Loader />
			)}
		</Wrapper>
	);
};
const Wrapper = styled.div`
	.result__section {
		h3 {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			/* identical to box height */

			color: #3e4857;
			margin-bottom: 1rem;
			&.mb-4 {
				margin-top: 5.5rem;
			}
		}
	}
	.grid {
		grid-column-gap: 38px;
		a {
			text-decoration: none;
		}
	}
`;

const CardStyle = styled(Card)`
	img {
		width: 100%;
		height: 200px;
		object-fit: cover;
	}
	h4 {
		font-family: Roboto Mono;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 0.8rem;
		line-height: 150%;
		/* or 24px */

		color: #151a30;
		&:hover {
			text-decoration: underline;
		}
	}
	p {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.5;
		margin: 0.4rem 0;
		/* identical to box height */

		color: #3e4857;
	}
`;

TestTakerResult.propTypes = propTypes;

const mapStateToProps = createStructuredSelector({
	isLoading: selectCurrentResultState,
	result: selectCurrentUserResult,
});

export default connect(mapStateToProps, {
	getUserTestResultsById,
})(TestTakerResult);
