/* -------------------------- Internal Dependencies ------------------------- */
import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const selectisLoading = createSelector(
	[selectUser],
	(user) => user?.isLoading
);

export const selectHidden = createSelector([selectUser], (user) => user.hidden);

export const selectCurrentUser = createSelector(
	[selectUser],
	(user) => user.currentUser
);

export const selectAllUsers = createSelector(
	[selectUser],
	(user) => user.allUsers
);

export const selectUserError = createSelector(
	[selectUser],
	(user) => user.error
);

export const selectUserMessage = createSelector(
	[selectUser],
	(user) => user.errorMessage
);
