/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

/* -------------------------- Internal Dependencies ------------------------- */
import { padDigits } from 'utils';
import useGlobal, { globalTime } from 'utils/useGlobal';
import { user_status } from 'utils/user_persist';
import history from 'redux/history';
import { setState, getState, isEmpty } from 'codewonders-helpers';
import { useParams } from 'react-router-dom';
import { clearState } from 'codewonders-helpers/bundle-cjs/helpers/localstorage';

/* ----------------------------- Timer PropTypes ---------------------------- */
const propTypes = {
	duration: PropTypes.string,
	submitQuestion: PropTypes.func,
	segment: PropTypes.string,
};

/* --------------------------- Timer defaultProps --------------------------- */
const defaultProps = {
	duration: '00:00:00',
	cb: () => {},
};

const time_token = window.atob(getState('TIMER') ? getState('TIMER') : 'e30=');

export const time_value = JSON.parse(time_token);
export const retake_status = getState('DEVELOP_STATUS');

const Timer = ({ duration, submitQuestion, segment }) => {
	const [hours, setHours] = useState('00');
	const [minutes, setMinutes] = useState('00');
	const [seconds, setSeconds] = useState('00');
	const [time, setAction] = useGlobal(globalTime);
	const [countdownTimer, setCountDown] = useState(1);
	const [start] = useState(Date.now());
	const { id: testId } = useParams();

	const localState = useMemo(
		() => ({
			id: time_value?.id,
			current_time: time_value?.current_time,
		}),
		// eslint-disable-next-line
		[]
	);

	const timer = useCallback(() => {
		let diff;

		const [
			durationHour,
			durationMinute,
			durationSeconds,
		] = duration.trim().split(':');

		const fullSeconds =
			60 * 60 * durationHour + durationMinute * 60 + Number(durationSeconds);

		let countdown;

		if (!isEmpty(time_value) && time_value?.id === localState?.id) {
			// assert countdown to memoized countdown and deduct it
			countdown =
				localState?.current_time - Math.floor((Date.now() - start) / 1000);
		} else {
			countdown = fullSeconds - Math.floor((Date.now() - start) / 1000);
		}

		diff = countdown;

		// Replace Time Values
		setHours(padDigits(Math.floor(diff / 3600), 2));
		diff %= 3600;
		setMinutes(padDigits(Math.floor(diff / 60), 2));
		setSeconds(padDigits(Math.floor(diff % 60), 2));

		if (countdown !== 0 || Math.sign(countdown) !== -1) {
			// Store timer state
			setState(
				'TIMER',
				window.btoa(
					JSON.stringify({
						id: testId,
						current_time: countdown,
					})
				)
			);

			// Broaden the state of the time for the countdown
			setAction(fullSeconds - countdown);

			//Monitor Countdown
			setCountDown(countdown);
		}
	}, [duration, start, setAction, localState, testId]);

	useEffect(() => {
		timer();
		const timerValue = setInterval(timer, 1000);
		if (countdownTimer === 0 || Math.sign(countdownTimer) === -1) {
			// Clear stored time to avoid time clashing
			clearInterval(timerValue);

			// Clear stored time to avoid time clashing
			clearState('TIMER');

			// Clear Stored Answers
			clearState('SELECTED_ANSWER');

			// Reset the countdown
			setCountDown(0);
		}

		// Cleanup
		return () => {
			clearInterval(timerValue);
			getState('TIMER');
		};
	}, [timer, countdownTimer]);

	useEffect(() => {
		if (countdownTimer === 0 || Math.sign(countdownTimer) === -1) {
			if (
				(user_status && user_status.is_admin) ||
				user_status.is_superuser ||
				user_status.is_contributor
			) {
				// Push to dashboard after
				history.push('/dashboard');
			} else {
				// Submit question
				submitQuestion(
					`${padDigits(Math.floor(time / 3600), 2)}:${padDigits(
						Math.floor(time / 60),
						2
					)}:${padDigits(Math.floor(time % 60), 2)}`,
					segment
				);
			}
		}
	}, [countdownTimer, submitQuestion, time, segment]);

	return (
		<>
			<span data-testid="timer">
				{hours}:{minutes}:{seconds}
			</span>
		</>
	);
};

Timer.propTypes = propTypes;
Timer.defaultProps = defaultProps;

export default Timer;
