/* -------------------------- External Dependencies ------------------------- */
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import Layout from 'components/layout/dashboard-layout';
import NewTestLayout from 'components/layout/create-test-layout';
import Loader from 'components/loader';
import TestTakerResult from 'pages/dashboard/my-tests/views/takers-results';
import Dashboard from 'pages/dashboard/public-tests';

import { user_status } from 'utils/user_persist';
/* -------------------------- Components Dependencies ------------------------- */
const MyTests = lazy(() => import('pages/dashboard/my-tests'));
const Notications = lazy(() => import('pages/dashboard/notifications'));
const TestTakers = lazy(() => import('pages/dashboard/test-takers'));
const Profile = lazy(() => import('pages/dashboard/profile'));
const NewTest = lazy(() => import('pages/dashboard/new-test'));
const Metrics = lazy(() => import('pages/dashboard/metrics'));
const DashboardRoutes = ({ location }) => {
	return (
		<>
			{location?.pathname === '/dashboard/my-tests/new' ? (
				<NewTestLayout>
					<Suspense fallback={<Loader loadingText="Please Wait" />}>
						<NewTest />
					</Suspense>
				</NewTestLayout>
			) : location?.pathname.includes('/dashboard/my-tests/result/') ? (
				<>
					<Switch>
						<Route
							path="/dashboard/my-tests/result/:id"
							exact
							component={TestTakerResult}
						/>
					</Switch>
				</>
			) : (
				<Layout>
					<Switch>
						<Route path="/dashboard" exact component={Dashboard} />
					</Switch>
					<Suspense fallback={<Loader loadingText="Please Wait" />}>
						<Switch>
							<Route path="/dashboard/my-tests" exact component={MyTests} />
							<Route
								path="/dashboard/my-tests/result/:id"
								exact
								component={TestTakerResult}
							/>
							<Route path="/dashboard/notifications" component={Notications} />
							{(user_status?.is_admin || user_status?.is_superuser) && (
								<Route path="/dashboard/metrics" component={Metrics} />
							)}
							<Route path="/dashboard/profile" component={Profile} />{' '}
							{user_status?.is_admin ||
							user_status?.is_superuser ||
							user_status?.is_contributor ? (
								<>
									<Route
										path="/dashboard/test-takers"
										exact
										component={TestTakers}
									/>
									<Route
										path="/dashboard/test-takers/:userId"
										exact
										component={TestTakers}
									/>
								</>
							) : null}
							{/* <Route path="*" exact component={ErrorPage} /> */}
						</Switch>
					</Suspense>
				</Layout>
			)}
		</>
	);
};

export default DashboardRoutes;
