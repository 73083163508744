import styled, { css } from 'styled-components';

export const Nav = styled.nav`
	position: fixed;
	min-height: 70px;
	top: 0;
	z-index: 999;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 0 40px;
	@media screen and (max-width: 935px) {
		padding: 0 20px;
	}
	right: 0;
	width: calc(100%);
	.collapse__nav {
		display: flex;
		width: 100%;
		align-items: center;
		h3 {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			margin: 0;
			/* identical to box height */

			color: #151a30;
			svg {
				margin-top: -4px;
				cursor: pointer;
				margin-right: 2px;
			}
		}
	}
	.centered {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0px);

		a {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			transition: all 0.4s ease;
			color: #3e4857;
			&.is-active {
				color: #4868f8;
				border-bottom: 2px solid #4868f8;
				padding-bottom: 6px;
			}
		}
	}

	${css`
		${(props) => props.css};
	`};
`;

export const Items = styled.div`
	display: flex;
	span:last-child {
		margin-right: 0 !important;
	}
	svg {
		&.icons {
			width: 15px;
			cursor: pointer;
		}
	}
`;

export const Item = styled.span`
	margin: 0 0.7rem;
	button {
		&.danger {
			background: #e62613 !important;
			padding: 8px 29px !important;
		}
		&.btn-developp {
			font-size: 15px;
			background: var(--theme-primary);
			color: #fff;
			font-weight: 500;
			padding: 8px 38px;
		}
		&.grey {
			background: rgba(143, 155, 179, 0);
			border-radius: 4px;
			font-weight: 400;
			font-size: 15px;
			border: 1px solid #c2ccd9;
			line-height: 19px;
			padding: 8px 18px;
			color: #3e4857;
			box-shadow: none !important;
			&:focus {
				outline: solid 2px var(--theme-primary);
			}
			svg {
				margin-right: 4px;
			}
			box-shadow: none !important;
		}
	}

	.icons {
		margin: 10px 0;
	}
`;
export const ModalContainer = styled.div`
	p {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 180%;
		color: #3e4857;
		font-family: var(--font-secondary);
	}
	h5 {
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 19px;
	}
	.btn-default {
		padding: 10px 28px;
		margin-right: 1rem;
		border: 1px solid #3e4857;
		box-sizing: border-box;
		border-radius: 4px;
	}
	.btn-developp {
		color: #fff;
		padding: 11px 33px;
	}
`;
