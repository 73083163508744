/* -------------------------- Internal Dependencies ------------------------- */
import { NotificationsActions } from './types';

export const initialState = {
	isLoading: false,
	notifications: {
		unread: 0,
		notifications: [],
	},
};

/**
 * Take Test Reducers
 * @param {Object | Array} state
 * @param {Object} action
 */
const notificationsReducers = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case NotificationsActions.GET_NOTIFICATIONS:
			return {
				...state,
				isLoading: payload.loading,
				notifications: payload.data,
			};
		case NotificationsActions.READ_NOTIFICATIONS:
			return {
				...state,
				isLoading: payload.loading,
			};

		default:
			return { ...state };
	}
};

export default notificationsReducers;
