const PerformanceStat = () => {
	if (process.env.NODE_ENV === 'development') {
		const callback = (list) => {
			const data = {};

			function DataRow(Column, value) {
				this.Column = Column;
				this.value = value;
			}
			list.getEntries().forEach((entry) => {
				data.serverLatency = new DataRow(
					'Server Latency',
					entry.responseStart - entry.requestStart
				);
				data.downloadTime = new DataRow(
					'Download Time',
					entry.responseEnd - entry.responseStart
				);
				data.totalAppLoadTime = new DataRow(
					'Total app load time',
					entry.responseEnd - entry.requestStart
				);
				data.firstMeaningfulPaint = new DataRow(
					'First Meaningful Paint',
					entry.responseEnd
				);
				data.firstPaint = new DataRow('First Paint', entry.startTime);
				console.log('%cPerfomance Timing', 'color: yellow; font-size: 15px');
				console.table(data);
			});
		};

		const observer = new PerformanceObserver(callback);
		observer.observe({ entryTypes: ['resource', 'navigation', 'paint'] });
	}
};

export default PerformanceStat;
