/* -------------------------- Internal Dependencies ------------------------- */
import { TestResultsActions } from './types';

export const initialState = {
	isLoading: false,
	currentTestReportUsers: {
		count: 0,
		next: null,
		previous: null,
		results: [],
	},
	isLoadingResult: false,
	currentUserTestResult: {},
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
const testResultsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case TestResultsActions.GET_TEST_REPORT_USERS: {
			return { ...state, isLoading: true };
		}
		case TestResultsActions.GET_USER_TEST_REPORT: {
			return { ...state, isLoadingResult: true };
		}
		case TestResultsActions.GET_TEST_REPORT_USERS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				currentTestReportUsers: payload.data,
			};
		}
		case TestResultsActions.GET_USER_TEST_REPORT_SUCCESS: {
			return {
				...state,
				isLoadingResult: false,
				currentUserTestResult: payload.data,
			};
		}

		case TestResultsActions.GET_TEST_REPORT_USERS_FAIL:
		case TestResultsActions.GET_USER_TEST_REPORT_FAIL: {
			return { ...state, isLoading: false };
		}
		case TestResultsActions.CLEAR_SESSION: {
			return {
				isLoading: false,
				currentTestReportUsers: {
					count: 0,
					next: null,
					previous: null,
					results: [],
				},
				currentUserTestResult: {},
			};
		}

		default:
			return { ...state };
	}
};

export default testResultsReducer;
