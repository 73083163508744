/* -------------------------- External Dependencies ------------------------- */
import { isEmpty } from 'codewonders-helpers';

/* --------------------------- Internal Dependency -------------------------- */
import { setAlert } from 'redux/alert/actions';

/* eslint-disable no-unused-expressions */

/**
 * @function
 * @param {Strine} text
 * @param {Number} number
 */
export const trunc = (text, number) => {
	return text.substr(0, number - 1) + (text.length >= number ? `...` : '');
};

/**
 * @param {String} name
 */
export const getFirstAndLastName = (name) => {
	const spacer = name ? name.trim().indexOf(' ') : 0;
	const first_name = name.trim().slice(0, spacer);
	const last_name = name.trim().slice(spacer + 1);
	return { first_name, last_name };
};

/**
 * @function
 * @param {String} str
 */
export const IsJsonString = (str) => {
	try {
		const json = JSON.parse(str);
		return typeof json === 'object';
	} catch (e) {
		return false;
	}
};

/**
 * @function
 * @param {Object} state
 * @param {Boolean} otherConditions
 * @param {Function} callback
 */
export const checkState = (state, otherConditions, callback) => {
	if (isEmpty(state) || otherConditions) {
		return callback;
	}
	return false;
};

/**
 * @function
 */
export const getHours = () => {
	const y = Array.from(Array(13), (_, x) => x).reduce((acc, elem) => {
		elem < 10
			? acc.push({ text: `0${elem}`, value: `0${elem}` })
			: acc.push({ text: elem, value: elem });
		return acc;
	}, []);
	return y;
};

export const getMinutes = () => {
	const y = Array.from(Array(61), (_, x) => x).reduce((acc, elem) => {
		elem < 10
			? acc.push({ text: `0${elem}`, value: `0${elem}` })
			: acc.push({ text: elem, value: elem });
		return acc;
	}, []);
	return y;
};

export const getSeconds = () => {
	const y = Array.from(Array(61), (_, x) => x).reduce((acc, elem) => {
		elem < 10
			? acc.push({ text: `0${elem}`, value: `0${elem}` })
			: acc.push({ text: elem, value: elem });
		return acc;
	}, []);
	return y;
};

/**
 * @function
 * @param {Array} array
 */
export const toString = (array) => {
	const skills = array.map((arr) => arr.value);
	return skills;
};

/**
 * @function
 * @param {Array} skills
 */
export const formatSkills = (skills) => {
	return skills.map((skill) => {
		return { value: skill.id, label: skill.name };
	});
};

/**
 * @function
 * @param {Array} question
 */
export const formatQuestion = (question = {}) => {
	const { time } = question;
	const hours = time ? time.slice(0, 2) : '00';
	const minutes = time ? time.slice(3, 5) : '00';
	const seconds = time ? time.slice(6) : '00';
	return { ...question, hours, minutes, seconds };
};

/**
 * @function
 * @param {Object} obj
 */
export const getObjectId = (obj) => {
	const isObj = obj != null && obj.constructor.name === 'Object';
	return isObj ? obj.id : obj;
};

/**
 * @function
 * @param {Object} test
 */
export const sanitizeTest = (test) => {
	const { skills, category, level, score } = test;
	delete test.created_at;
	delete test.updated_at;
	delete test.instructions;
	return {
		...test,
		skills: toString(skills),
		category: getObjectId(category),
		level: getObjectId(level),
		score: getObjectId(score),
	};
};

/**
 * @function
 * @param {Object} question
 */
export const sanitizeTestQuestion = (question) => {
	delete question.created_at;
	delete question.updated_at;
	delete question.hours;
	delete question.options;
	delete question.minutes;
	delete question.seconds;
	return {
		...question,
	};
};

export const getThemes = () => {
	const themes = [
		'monokai',
		'github',
		'tomorrow',
		'kuroir',
		'twilight',
		'xcode',
		'textmate',
		'solarized_dark',
		'solarized_light',
		'terminal',
	];
	return themes;
};

export const getLanguages = () => {
	return [
		'javascript',
		'java',
		'python',
		'text',
		'ruby',
		'php',
		'golang',
		'csharp',
		'rust',
		'bash',
		'elixir',
		'typescript',
	];
};
/**
 *
 * @param {Array} codingLanguages
 * @param {String} mode
 */
export const getCodingLanguageID = (mode) => {
	const languages = [
		{ id: 63, language: 'javascript' },
		{ id: 62, language: 'java' },
		{ id: 71, language: 'python' },
		{ id: 46, language: 'bash' },
		{ id: 43, language: 'text' },
		{ id: 72, language: 'ruby' },
		{ id: 68, language: 'php' },
		{ id: 60, language: 'golang' },
		{ id: 51, language: 'csharp' },
		{ id: 57, language: 'elixir' },
		{
			id: 49,
			language: 'C',
		},
		{
			id: 53,
			language: 'C++',
		},
		{ id: 73, language: 'rust' },
		{ id: 74, language: 'typescript' },
	];
	return languages.filter((lang) => lang.language === mode)[0].id;
};

const mapErrorToStrings = (error) => {
	if (!isEmpty(error)) {
		const allowedErrors = [
			'phone_number',
			'email',
			'password',
			'message',
			'non_field_errors',
			'detail',
			'instructions',
			'error',
			'skills',
			'time',
		];

		const filteredError = Object.keys(error).filter((e) =>
			allowedErrors.includes(e)
		);
		return filteredError
			.map((key) => {
				if (!Array.isArray(error[key])) {
					return error[key];
				}
				return error[key]
					.map((msg) => {
						if (typeof msg === 'string') {
							const errorKey = filteredError[0];
							return `${errorKey}: ${msg.replace('This field', key)}`;
						}
						if (typeof msg === 'object') {
							const errorKey = Object.keys(msg);
							const errorMsg = msg[errorKey][0];
							return `${errorKey}: ${errorMsg}`;
						}
						return null;
					})
					.filter((e) => e);
			})
			.reduce((acc, val) => acc.concat(val), []);
	}

	return [];
};

/**
 * @function
 * @param {any} error
 * @returns {String}
 */
export const generateErrors = (error) => {
	if (error.response) {
		const [errors] = mapErrorToStrings(error.response.data);

		return errors;
	}
	if (error.data) {
		const [errors] = mapErrorToStrings(error.data);
		return errors;
	}
	return 'An Error Occured';
};

/**
 * @function
 * @param {String} duration
 * @param {} display
 * @returns {Function}
 */
export const startTimer = (duration, display) => {
	const start = Date.now();
	let diff;
	let hours;
	let minutes;
	let seconds;
	const fullSeconds =
		60 * duration.trim().split(':')[0] * 60 +
		duration.split(':')[1] * 60 +
		parseInt(duration.split(':')[2]);
	const timer = () => {
		diff = fullSeconds - (((Date.now() - start) / 1000) | 0);

		hours = Math.floor(diff / 3600);
		diff %= 3600;
		minutes = (diff / 60) | 0;
		seconds = diff % 60 | 0;

		hours = hours < 10 ? `0${hours}` : hours;
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		seconds = seconds < 10 ? `0${seconds}` : seconds;

		display.textContent = `${hours}:${minutes}:${seconds}`;

		if (diff <= 0) {
			display.textContent = `00:00:00`;
		}
	};
	if (display) {
		timer();
		setInterval(timer, 1000);
	}
	return duration;
};

/**
 *
 * @param {*} dispatch
 * @param {*} type
 * @param {*} err
 */

export const getError = (dispatch, err, type = 'error') => {
	return dispatch(setAlert(generateErrors(err), type));
};

/**
 * @function
 * @param {*} n
 * @returns {String}
 */
export const nth = (n) =>
	['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';

/**
 *
 * @param {Number} number
 * @param {Number} digits
 */

export const padDigits = (number, digits) => {
	return (
		Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
	);
};

/**
 * Get Avatar
 * @param {String} val
 */
export const getAvatar = (val) => {
	return val
		? val.split('')[0].match(/^[aeiw]+$/gim)
			? '#218625'
			: val.split('')[0].match(/^[ouyg]+$/gim)
			? '#e91e63'
			: val.split('')[0].match(/^[bdfm]+$/gim)
			? '#ff5722'
			: '#062e64'
		: '#062e64'; // Use as default if val is undefined
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 */
export const capitalize = (string) => {
	if (typeof string !== 'string') return '';
	return (
		string.charAt(0).toUpperCase() +
		[...string]
			.join('')
			?.toLowerCase()
			?.slice(1)
	);
};

const a = [
	'',
	'one ',
	'two ',
	'three ',
	'four ',
	'five ',
	'six ',
	'seven ',
	'eight ',
	'nine ',
	'ten ',
	'eleven ',
	'twelve ',
	'thirteen ',
	'fourteen ',
	'fifteen ',
	'sixteen ',
	'seventeen ',
	'eighteen ',
	'nineteen ',
];

const b = [
	'',
	'',
	'twenty',
	'thirty',
	'forty',
	'fifty',
	'sixty',
	'seventy',
	'eighty',
	'ninety',
];

/**
 *
 * @param {Number} num
 */
export const numtoWords = (num) => {
	// eslint-disable-next-line no-cond-assign
	if ((num = num.toString()).length > 9) return 'overflow';
	const n = `000000000${num}`
		.substr(-9)
		.match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
	// eslint-disable-next-line consistent-return
	if (!n) return;
	let str = '';
	str +=
		// eslint-disable-next-line eqeqeq
		n[1] != 0
			? `${a[Number(n[1])] || `${b[n[1][0]]} ${a[n[1][1]]}`}crore `
			: '';
	str +=
		// eslint-disable-next-line eqeqeq
		n[2] != 0 ? `${a[Number(n[2])] || `${b[n[2][0]]} ${a[n[2][1]]}`}lakh ` : '';
	str +=
		// eslint-disable-next-line eqeqeq
		n[3] != 0
			? `${a[Number(n[3])] || `${b[n[3][0]]} ${a[n[3][1]]}`}thousand `
			: '';
	str +=
		// eslint-disable-next-line eqeqeq
		n[4] != 0
			? `${a[Number(n[4])] || `${b[n[4][0]]} ${a[n[4][1]]}`}hundred `
			: '';
	str +=
		// eslint-disable-next-line eqeqeq
		n[5] != 0
			? // eslint-disable-next-line eqeqeq
			  `${(str != '' ? 'and ' : '') +
					(a[Number(n[5])] || `${b[n[5][0]]} ${a[n[5][1]]}`)}`
			: '';
	return capitalize(str);
};

/**
 * Ordinal Number
 * @param {Number} n
 */

export const getNumberWithOrdinal = (n) => {
	const ordinals = [
		'zeroth',
		'first',
		'second',
		'third',
		'fourth',
		'fifth',
		'sixth',
		'seventh',
		'eight',
		'nineth',
		'tenth',
	];
	const tens = {
		20: 'twenty',
		30: 'thirty',
		40: 'forty',
	};
	const ordinalTens = {
		30: 'thirtieth',
		40: 'fortieth',
		50: 'fiftieth',
	};

	if (n <= 20) {
		return ordinals[n];
	}

	if (n % 10 === 0) {
		return ordinalTens[n];
	}

	return tens[n - (n % 10)] + ordinals[n % 10];
};
