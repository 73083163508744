/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { createSelector } from 'reselect';

/**
 * Seletc all tests reducers state
 * @param {*} state
 */
const selectTests = (state) => state.tests;

export const selectCurrentTests = createSelector(
	[selectTests],
	(tests) => tests.tests
);

export const selectCurrentQuestionStatus = createSelector(
	[selectTests],
	(tests) => tests.current_test.public
);

export const selectCurrentTest = createSelector(
	[selectTests],
	(tests) => tests.current_test
);

export const selectCurrentPendingInvites = createSelector(
	[selectTests],
	(tests) => tests.pending_invites
);

export const selectCurrentPastTest = createSelector(
	[selectTests],
	(tests) => tests.past_tests
);

export const selectCurrentTestShares = createSelector(
	[selectTests],
	(tests) => tests.current_tests_shares
);

export const selectCurrentQuestionOptions = createSelector(
	[selectTests],
	(tests) => tests.current_questions.results
);

export const selectCurrentCategories = createSelector(
	[selectTests],
	(tests) => tests.categories
);
export const selectisCategoryLoading = createSelector(
	[selectTests],
	(tests) => tests.isCategoryLoading
);
export const selectCurrentSegments = createSelector(
	[selectTests],
	(tests) => tests.segments
);

export const selectQuestionFetched = createSelector(
	[selectTests],
	(tests) => tests.questionFetched
);

export const selectCurrentQuestion = createSelector(
	[selectTests],
	(tests) => tests.current_question
);

export const selectCurrentLevels = createSelector(
	[selectTests],
	(tests) => tests.levels
);

export const selectCurrentTestDetail = createSelector(
	[selectTests],
	(tests) => tests.test_details
);

export const selectCurrentSkills = createSelector(
	[selectTests],
	(tests) => tests.skills
);

export const selectCurrentScoreMethods = createSelector(
	[selectTests],
	(tests) => tests.score_methods
);

export const selectisLoading = createSelector(
	[selectTests],
	(tests) => tests.isLoading
);

export const selectisDetailsLoading = createSelector(
	[selectTests],
	(tests) => tests.isDetailsLoading
);

export const selectmyTestLoading = createSelector(
	[selectTests],
	(tests) => tests.isMyTestLoading
);

export const selectMyCurrentTests = createSelector([selectTests], (tests) =>
	tests.my_tests.results ? tests.my_tests.results : []
);

export const selectCurrentTestCreator = createSelector([selectTests], (tests) =>
	tests.current_test ? tests.current_test.created_by : ''
);

export const selectCodingLanguages = createSelector(
	[selectTests],
	(tests) => tests.coding_languages
);

export const selectQuestionType = createSelector(
	[selectTests],
	(tests) => tests.question
);
