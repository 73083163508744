/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* --------------------------- Internal Dependency -------------------------- */
import SidebarLayout from './sidebar';

/* ------------------------ DashbaordLayout propTypes ----------------------- */
const propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

/* ---------------------- DashbaordLayout defaultProps ---------------------- */
const defaultProps = {
	children: <></>,
};

const DashbaordLayout = ({ children }) => {
	return (
		<>
			<SidebarLayout />
			<Main>
				<Wrapper>{children}</Wrapper>
			</Main>
		</>
	);
};

const Main = styled.main`
	float: right;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	width: calc(100% - var(--sidebar-width));
	display: block;
	background: #fafafa;
	min-height: 100vh;
`;
const Wrapper = styled.section`
	padding: 6rem 40px 0;
	@media (max-width: 786px) {
		padding: 5rem 20px 0 !important;
	}
`;

DashbaordLayout.defaultProps = defaultProps;

DashbaordLayout.propTypes = propTypes;

export default DashbaordLayout;
