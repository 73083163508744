/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAccordionToggle, Accordion } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import isEmpty from 'codewonders-helpers/bundle-cjs/helpers/is-empty';
/* -------------------------- Internal Dependencies ------------------------- */
import SideBarModal from 'components/sidebar-modal';
import {
	selectisCategoryLoading,
	selectCurrentCategories,
	selectCurrentLevels,
	selectCurrentSkills,
} from 'redux/developp-tests/selector';
import {
	getCategories,
	getLevels,
	getSkills,
} from 'redux/developp-tests/actions/create-test';
import Loader from 'components/loader';
import useGlobal, { globalFilter } from 'utils/useGlobal';
/* --------------------------- Image Dependencies --------------------------- */
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow-right.svg';

/* ------------------------- FilterTest propTypes ------------------------ */
const propTypes = {
	show: PropTypes.bool,
	closeShow: PropTypes.func,
	categories: PropTypes.array,
	levels: PropTypes.array,
	skills: PropTypes.array,
	isCategoryLoading: PropTypes.bool,
	getCategories: PropTypes.func,
	getLevels: PropTypes.func,
	getSkills: PropTypes.func,
};

/* ---------------------- AccordionHeader propTypes ---------------------- */
const AccordionpropTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
	eventKey: PropTypes.string,
	callback: PropTypes.func,
};

const FilterTest = ({
	show,
	closeShow,
	categories,
	levels,
	skills,
	isCategoryLoading,
	getCategories: loadCategories,
	getLevels: loadLevels,
	getSkills: loadSkills,
}) => {
	const [filter, setFilter] = useGlobal(globalFilter);

	const handleChange = (name, val, e) => {
		if (
			e.target.checked &&
			isEmpty(filter.find((item) => item.id === e.target.value))
		) {
			setFilter([
				...filter,
				{
					section: name,
					name: val,
					id: e.target.value,
				},
			]);
		}
		if (
			!e.target.checked &&
			!isEmpty(filter.find((item) => item.id === e.target.value))
		) {
			setFilter(filter.filter((item) => item.id !== e.target.value));
		}
	};

	const checkChecked = (id) => {
		if (!isEmpty(filter.find((item) => item.id === id))) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		loadCategories();
		loadLevels();
		loadSkills();
	}, [loadCategories, loadLevels, loadSkills]);

	return (
		<Wrapper>
			<SideBarModal show={show} closeShow={closeShow} size="sm">
				<h2>Filters</h2>

				{!isCategoryLoading ? (
					<>
						<Accordion defaultActiveKey="0">
							<AccordionHeader eventKey="0" className="mt-4">
								CATEGORY
							</AccordionHeader>
							<Accordion.Collapse eventKey="0">
								<AccordionBody>
									{!isEmpty(categories) &&
										categories.map((category) => (
											<div
												className="custom-control custom-checkbox"
												key={category.id}
											>
												<input
													type="checkbox"
													className="custom-control-input"
													id={category.name}
													name={category.name}
													value={category.id}
													checked={checkChecked(category.id)}
													onChange={(e) => {
														handleChange('category', category.name, e);
													}}
												/>
												<label
													className="custom-control-label"
													htmlFor={category.name}
												>
													{category.name}
												</label>
											</div>
										))}
								</AccordionBody>
							</Accordion.Collapse>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<AccordionHeader eventKey="1">LEVELS</AccordionHeader>
							<Accordion.Collapse eventKey="1">
								<AccordionBody>
									{!isEmpty(levels) &&
										levels.map((level) => (
											<div
												className="custom-control custom-checkbox"
												key={level.id}
											>
												<input
													type="checkbox"
													className="custom-control-input"
													id={level.name}
													name={level.name}
													value={level.id}
													checked={checkChecked(level.id)}
													onChange={(e) => {
														handleChange('level', level.name, e);
													}}
												/>
												<label
													className="custom-control-label"
													htmlFor={level.name}
												>
													{level.name}
												</label>
											</div>
										))}
								</AccordionBody>
							</Accordion.Collapse>
						</Accordion>

						<Accordion defaultActiveKey="2">
							<AccordionHeader eventKey="2">SKILLS</AccordionHeader>
							<Accordion.Collapse eventKey="2">
								<AccordionBody>
									{!isEmpty(skills) &&
										skills.map((skill) => (
											<div
												className="custom-control custom-checkbox"
												key={skill.id}
											>
												<input
													type="checkbox"
													className="custom-control-input"
													id={skill.name}
													name={skill.name}
													value={skill.id}
													checked={checkChecked(skill.id)}
													onChange={(e) => {
														handleChange('skills', skill.name, e);
													}}
												/>
												<label
													className="custom-control-label"
													htmlFor={skill.name}
												>
													{skill.name}
												</label>
											</div>
										))}
								</AccordionBody>
							</Accordion.Collapse>
						</Accordion>
					</>
				) : (
					<Loader />
				)}
			</SideBarModal>
		</Wrapper>
	);
};

export const AccordionHeader = ({ children, eventKey, callback, ...rest }) => {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey)
	);

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<>
			<button
				type="button"
				onClick={decoratedOnClick}
				{...rest}
				className="accordion__header"
			>
				{children}
				<Arrow
					style={{
						transform: isCurrentEventKey ? 'rotate(0deg)' : 'rotate(90deg)',
						transition: 'all .5s ease',
					}}
				/>
			</button>
		</>
	);
};

const Wrapper = styled.div`
	h2 {
		font-style: normal;
		font-weight: 600;
		margin-top: 1.5rem;
		font-size: 19px;

		/* identical to box height, or 27px */

		color: #151a30;
	}
	.custom-control-label::before {
		position: absolute;
		top: 0.15rem;
		left: -1.5rem;
		display: block;
		width: 1.1rem;
		height: 1.1rem;
		pointer-events: none;
		content: '';
		background-color: #fff;
		border: #8f9bb3 solid 2px;
	}
	.custom-control-label::after {
		position: absolute;
		top: 0.15rem;
		left: -1.5rem;
		display: block;
		width: 1.1rem;
		height: 1.1rem;
		content: '';
		background: no-repeat 50%/50% 50%;
	}
	.custom-control-input:checked ~ .custom-control-label::before {
		color: #fff;
		border-color: #8e9bb3;
		background-color: #8e9bb3;
	}

	.custom-control-label {
		position: relative;

		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 150%;
		margin-bottom: 0.8rem;
		color: #3e4857;
		vertical-align: top;
	}
	.accordion__header {
		background: white;
		border: none;
		display: flex;
		width: 100%;
		font-style: normal;
		font-family: var(--font-primary);
		font-weight: 600;
		border-bottom: 1px solid #edf1f7;
		font-size: 13px;
		line-height: 150%;
		padding: 0.3rem 0;
		margin: 2rem 0 1rem;
		text-transform: uppercase;
		color: #8f9bb3;
		align-items: center;
		outline: 0 !important;
		justify-content: space-between;
	}
`;

const AccordionBody = styled.header``;

FilterTest.propTypes = propTypes;
AccordionHeader.propTypes = AccordionpropTypes;

const mapStateToProps = createStructuredSelector({
	categories: selectCurrentCategories,
	levels: selectCurrentLevels,
	skills: selectCurrentSkills,
	isCategoryLoading: selectisCategoryLoading,
});

export default connect(mapStateToProps, {
	getCategories,
	getLevels,
	getSkills,
})(FilterTest);
