/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';

const MBTI_CODES = {
	INTJ: (
		<>
			<h3>Introverted - Intuitive - Thinking - Judging</h3>
			<p>
				It can be lonely at the top. As one of the rarest personality types –
				and one of the most capable – Architects (INTJs) know this all too well.
				Rational and quick-witted, Architects may struggle to find people who
				can keep up with their nonstop analysis of everything around them.
			</p>

			<b>A Thirst for Knowledge</b>
			<p>
				These personalities can be both the boldest of dreamers and the
				bitterest of pessimists. Architects believe that, through willpower and
				intelligence, they can achieve even the most challenging of goals. But
				they may be cynical about human nature more generally, assuming that
				most people are lazy, unimaginative, or simply doomed to mediocrity.
			</p>

			<p>
				Architects derive much of their self-esteem from their knowledge and
				mental acuity. In school, people with this personality type may have
				been called “bookworms” or “nerds.” But rather than taking these labels
				as insults, many Architects embrace them. They are confident in their
				ability to teach themselves about – and master – any topic that
				interests them, whether that’s coding or capoeira or classical music.
			</p>
			<b>The Chess Game of Life</b>
			<p>
				This personality type is full of contradictions. Architects are
				imaginative yet decisive, ambitious yet private, and curious yet
				focused. From the outside, these contradictions may seem baffling, but
				they make perfect sense once you understand the inner workings of the
				Architect mind.
			</p>
			<p>
				For Architects, life is like a giant game of chess. Relying on strategy
				rather than chance, they contemplate the strengths and weaknesses of
				each move before they make it. And they never lose faith that, with
				enough ingenuity and insight, they can find a way to win – no matter
				what challenges might arise along the way.
			</p>
		</>
	),
	INTP: (
		<>
			<h3>Introverted - Intuitive - Thinking - Prospecting</h3>
			<p>
				The Logician personality type is fairly rare, making up only three
				percent of the population, which is definitely a good thing for them, as
				there’s nothing they’d be more unhappy about than being “common”.
				Logicians pride themselves on their inventiveness and creativity, their
				unique perspective and vigorous intellect. Usually known as the
				philosopher, the architect, or the dreamy professor, Logicians have been
				responsible for many scientific discoveries throughout history.
			</p>
			<b>The Unexamined Life Is Not Worth Living</b>
			<p>
				Logicians are known for their brilliant theories and unrelenting logic –
				in fact, they are considered the most logically precise of all the
				personality types. They love patterns, and spotting discrepancies
				between statements could almost be described as a hobby, making it a bad
				idea to lie to a Logician. This makes it ironic that Logicians’ word
				should always be taken with a grain of salt – it’s not that they are
				dishonest, but people with the Logician personality type tend to share
				thoughts that are not fully developed, using others as a sounding board
				for ideas and theories in a debate against themselves rather than as
				actual conversation partners.
			</p>
			<p>
				This may make them appear unreliable, but in reality no one is more
				enthusiastic and capable of spotting a problem, drilling through the
				endless factors and details that encompass the issue and developing a
				unique and viable solution than Logicians – just don’t expect punctual
				progress reports. People who share the Logician personality type aren’t
				interested in practical, day-to-day activities and maintenance, but when
				they find an environment where their creative genius and potential can
				be expressed, there is no limit to the time and energy Logicians will
				expend in developing an insightful and unbiased solution.
			</p>
			<b>Move Themselves</b>
			<p>
				Further, Logicians are unlikely to understand emotional complaints at
				all, and their friends won’t find a bedrock of emotional support in
				them. People with the Logician personality type would much rather make a
				series of logical suggestions for how to resolve the underlying issue, a
				perspective that is not always welcomed by their more sensitive
				companions. This will likely extend to most social conventions and goals
				as well, like planning dinners and getting married, as Logicians are far
				more concerned with originality and efficient results.
			</p>
			<p>
				The one thing that really holds Logicians back is their restless and
				pervasive fear of failure. Logician personalities are so prone to
				reassessing their own thoughts and theories, worrying that they’ve
				missed some critical piece of the puzzle, that they can stagnate, lost
				in an intangible world where their thoughts are never truly applied.
				Overcoming this self-doubt stands as the greatest challenge Logicians
				are likely to face, but the intellectual gifts – big and small –
				bestowed on the world when they do makes it worth the fight.
			</p>
		</>
	),
	ENTJ: (
		<>
			<h3>Extraverted - Intuitive - Thinking - Judging</h3>
			<p>
				Commanders are natural-born leaders. People with this personality type
				embody the gifts of charisma and confidence, and project authority in a
				way that draws crowds together behind a common goal. However, Commanders
				are also characterized by an often ruthless level of rationality, using
				their drive, determination and sharp minds to achieve whatever end
				they’ve set for themselves. Perhaps it is best that they make up only
				three percent of the population, lest they overwhelm the more timid and
				sensitive personality types that make up much of the rest of the world –
				but we have Commanders to thank for many of the businesses and
				institutions we take for granted every day.
			</p>
			<b>Happiness Lies in the Joy of Achievement</b>
			<p>
				If there’s anything Commanders love, it’s a good challenge, big or
				small, and they firmly believe that given enough time and resources,
				they can achieve any goal. This quality makes people with the Commander
				personality type brilliant entrepreneurs, and their ability to think
				strategically and hold a long-term focus while executing each step of
				their plans with determination and precision makes them powerful
				business leaders. This determination is often a self-fulfilling
				prophecy, as Commanders push their goals through with sheer willpower
				where others might give up and move on, and their Extraverted (E) nature
				means they are likely to push everyone else right along with them,
				achieving spectacular results in the process.
			</p>
			<b>Cultivating the Science of Human Relationships</b>
			<p>
				Emotional expression isn’t the strong suit of any Analyst type, but
				Commanders’ distance from their emotions is especially public, and felt
				directly by a much broader swath of people. Especially in a professional
				environment, Commanders will simply crush the sensitivities of those
				they view as inefficient, incompetent or lazy. To people with the
				Commander personality type, emotional displays are displays of weakness,
				and it’s easy to make enemies with this approach – Commanders will do
				well to remember that they absolutely depend on having a functioning
				team, not just to achieve their goals, but for their validation and
				feedback as well, something Commanders are, curiously, very sensitive
				to.
			</p>
			<p>
				Commanders are true powerhouses, and they cultivate an image of being
				larger than life – and often enough they are. They need to remember
				though, that their stature comes not just from their own actions, but
				from the actions of the team that props them up, and that it’s important
				to recognize the contributions, talents and needs, especially from an
				emotional perspective, of their support network. Even if they have to
				adopt a &quot;fake it ‘til you make it&quot; mentality, if Commanders
				are able to combine an emotionally healthy focus alongside their many
				strengths, they will be rewarded with deep, satisfying relationships and
				all the challenging victories they can handle.
			</p>
		</>
	),
	ENTP: (
		<>
			<h3>Extraverted - Intuitive - Thinking - Prospecting</h3>
			<p>
				No one loves the process of mental sparring more than the Debater
				personality type, as it gives them a chance to exercise their
				effortlessly quick wit, broad accumulated knowledge base, and capacity
				for connecting disparate ideas to prove their points. Debaters are the
				ultimate devil’s advocate, thriving on the process of shredding
				arguments and beliefs and letting the ribbons drift in the wind for all
				to see. They don’t always do this because they are trying to achieve
				some deeper purpose or strategic goal, though. Sometimes it’s for the
				simple reason that it’s fun.
			</p>
			<p>
				An odd juxtaposition arises with Debaters, as they are uncompromisingly
				honest, but will argue tirelessly for something they don’t actually
				believe in, stepping into another’s shoes to argue a truth from another
				perspective. Playing the devil’s advocate helps people with the Debater
				personality type to not only develop a better sense of others’
				reasoning, but a better understanding of opposing ideas – since Debaters
				are the ones arguing them.
			</p>
			<p>
				This tactic shouldn’t be confused with the sort of mutual understanding
				Diplomat personalities seek – Debaters, like all Analyst personality
				types, are on a constant quest for knowledge, and what better way to
				gain it than to attack and defend an idea, from every angle, from every
				side?
			</p>
			<b>Opportunity Is Missed Because It Looks Like Hard Work</b>
			<p>
				Debaters have a longer road than most in harnessing their natural
				abilities – their intellectual independence and free-form vision are
				tremendously valuable when they’re in charge, or at least have the ear
				of someone who is, but getting there can take a level of follow-through
				that Debaters struggle with.
			</p>
			<p>
				Once they’ve secured such a position, Debaters need to remember that for
				their ideas to come to fruition, they will always depend on others to
				assemble the pieces – if they’ve spent more time “winning” arguments
				than they have building consensus, many Debaters will find they simply
				don’t have the support necessary to be successful. Playing devil’s
				advocate so well, people with this personality type may find that the
				most complex and rewarding intellectual challenge is to understand a
				more sentimental perspective, and to argue consideration and compromise
				alongside logic and progress.
			</p>
		</>
	),
	INFJ: (
		<>
			<h3>Introverted - Intuitive - Feeling - Judging</h3>
			<p>
				Advocates are the rarest personality types of all. Still, Advocates
				leave their mark on the world. They have a deep sense of idealism and
				integrity, but they aren’t idle dreamers – they take concrete steps to
				realize their goals and make a lasting impact.
			</p>
			<p>
				Advocates’ unique combination of personality traits makes them complex
				and quite versatile. For example, Advocates can speak with great passion
				and conviction, especially when standing up for their ideals. At other
				times, however, they may choose to be soft-spoken and understated,
				preferring to keep the peace rather than challenge others.
			</p>
			<b>Standing Up for What’s Right</b>
			<p>
				Advocates generally strive to do what’s right – and they want to help
				create a world where others do the right thing as well. People with this
				personality type may feel called to use their strengths – including
				creativity, imagination, and sensitivity – to uplift others and spread
				compassion. Concepts like egalitarianism and karma can mean a great deal
				to Advocates.
			</p>
			<b>A Personal Mission</b>
			<p>
				Many Advocates feel compelled to find a mission for their lives. When
				they encounter inequity or unfairness, they tend to think, “How can I
				fix this?” They are well-suited to support a movement to right a wrong,
				no matter how big or small. Advocates just need to remember that while
				they’re busy taking care of the world, they need to take care of
				themselves too.
			</p>
		</>
	),
	INFP: (
		<>
			<h3>Introverted - Intuitive - Feeling - Prospecting</h3>
			<p>
				On the outside, Mediators (INFPs) may seem quiet or even shy. But they
				often have vibrant, passionate inner lives. Because they make up such a
				small portion of the population, people with this personality type may
				sometimes feel misunderstood or out of step with the world. Fortunately,
				their caring nature can help them create and sustain deep relationships
				with their loved ones.
			</p>
			<b>Speaking Their Truth</b>
			<p>
				Many Mediators are curious about the depths of human nature, and they
				often make an effort to understand other people’s true feelings. This
				can make them capable of great empathy. It can also enable them to
				communicate in ways that are sensitive, original, and quite moving.
			</p>
			<p>
				Perhaps because of these strengths, Mediators tend to crave
				opportunities for creative self-expression. It comes as no surprise that
				many famous Mediators are poets, writers, and actors. People with this
				personality type often enjoy dreaming up all sorts of stories and
				possibilities.
			</p>
			<p>
				Mediators have a talent for self-expression. They may reveal their
				innermost thoughts and secrets through metaphors and fictional
				characters. By using their imaginations in this way, Mediators can
				explore their inner nature and their place in the world. That said, they
				can have a tendency to daydream and fantasize rather than take action.
				If they don’t act on their dreams and ideas, Mediators are likely to end
				up feeling frustrated or unfulfilled.
			</p>
			<b>In Search of a Calling</b>
			<p>
				Mediators may feel directionless or stuck unless they connect with a
				sense of purpose for their lives. For many Mediators, this purpose has
				something to do with helping and uplifting others. Empathetic by nature,
				these personalities may feel other people’s suffering as if it were
				their own. This only strengthens their motivation to be of service.
			</p>
			<p>
				Although Mediators might want to help everyone, they may need to focus
				their attention and energy on one worthy cause at a time. Otherwise,
				they can become so overwhelmed by all the problems they can’t fix that
				they’re tempted to give up on even trying. This is a sad sight for
				Mediators’ friends, who often depend on their hopeful outlook.
			</p>
			<p>
				Fortunately, like flowers in the spring, Mediators’ creativity and
				idealism can bloom even after the darkest of seasons. Although they know
				the world will never be perfect, Mediators still care about making it
				better however they can. This quiet belief in doing the right thing may
				explain why these personalities so often inspire compassion, kindness,
				and beauty wherever they go.
			</p>
		</>
	),
	ENFJ: (
		<>
			<h3>Extraverted - Intuitive - Feeling - Judging</h3>
			<p>
				Protagonists are natural-born leaders, full of passion and charisma.
				Forming around two percent of the population, they are oftentimes our
				politicians, our coaches and our teachers, reaching out and inspiring
				others to achieve and to do good in the world. With a natural confidence
				that begets influence, Protagonists take a great deal of pride and joy
				in guiding others to work together to improve themselves and their
				community.
			</p>
			<b>Firm Believers in the People</b>
			<p>
				People are drawn to strong personalities, and Protagonists radiate
				authenticity, concern and altruism, unafraid to stand up and speak when
				they feel something needs to be said. They find it natural and easy to
				communicate with others, especially in person, and their Intuitive (N)
				trait helps people with the Protagonist personality type to reach every
				mind, be it through facts and logic or raw emotion. Protagonists easily
				see people’s motivations and seemingly disconnected events, and are able
				to bring these ideas together and communicate them as a common goal with
				an eloquence that is nothing short of mesmerizing.
			</p>
			<p>
				The interest Protagonists have in others is genuine, almost to a fault –
				when they believe in someone, they can become too involved in the other
				person’s problems, placing too much trust in them. Luckily, this trust
				tends to be a self-fulfilling prophecy, as Protagonists’ altruism and
				authenticity inspire those they care about to become better themselves.
				But if they aren’t careful, they can overextend their optimism,
				sometimes pushing others further than they’re ready or willing to go.
			</p>
			<p>
				Protagonists are vulnerable to another snare as well: they have a
				tremendous capacity for reflecting on and analyzing their own feelings,
				but if they get too caught up in another person’s plight, they can
				develop a sort of emotional hypochondria, seeing other people’s problems
				in themselves, trying to fix something in themselves that isn’t wrong.
				If they get to a point where they are held back by limitations someone
				else is experiencing, it can hinder Protagonists’ ability to see past
				the dilemma and be of any help at all. When this happens, it’s important
				for Protagonists to pull back and use that self-reflection to
				distinguish between what they really feel, and what is a separate issue
				that needs to be looked at from another perspective.
			</p>
			<b>the Support of a Cause We Believe to Be Just</b>
			<p>
				People with the Protagonist personality type are passionate altruists,
				sometimes even to a fault, and they are unlikely to be afraid to take
				the slings and arrows while standing up for the people and ideas they
				believe in. It is no wonder that many famous Protagonists are cultural
				or political icons – this personality type wants to lead the way to a
				brighter future, whether it’s by leading a nation to prosperity, or
				leading their little league softball team to a hard-fought victory.
			</p>
		</>
	),
	ENFP: (
		<>
			<h3>Extraverted - Intuitive - Feeling - Prospecting</h3>
			<p>
				The Campaigner personality is a true free spirit. They are often the
				life of the party, but unlike types in the Explorer Role group,
				Campaigners are less interested in the sheer excitement and pleasure of
				the moment than they are in enjoying the social and emotional
				connections they make with others. Charming, independent, energetic and
				compassionate, the 7% of the population that they comprise can certainly
				be felt in any crowd.
			</p>
			<b>You Can Change the World With Just an Idea</b>
			<p>
				More than just sociable people-pleasers though, Campaigners, like all
				their Diplomat cousins, are shaped by their Intuitive (N) quality,
				allowing them to read between the lines with curiosity and energy. They
				tend to see life as a big, complex puzzle where everything is connected
				– but unlike Analyst personality types, who tend to see that puzzle as a
				series of systemic machinations, Campaigners see it through a prism of
				emotion, compassion and mysticism, and are always looking for a deeper
				meaning.
			</p>
			<p>
				Campaigners are fiercely independent, and much more than stability and
				security, they crave creativity and freedom. Many other types are likely
				to find these qualities irresistible, and if they’ve found a cause that
				sparks their imagination, Campaigners will bring an energy that
				oftentimes thrusts them into the spotlight, held up by their peers as a
				leader and a guru – but this isn’t always where independence-loving
				Campaigners want to be. Worse still if they find themselves beset by the
				administrative tasks and routine maintenance that can accompany a
				leadership position. Campaigners’ self-esteem is dependent on their
				ability to come up with original solutions, and they need to know that
				they have the freedom to be innovative – they can quickly lose patience
				or become dejected if they get trapped in a boring role.
			</p>
			<b>Don’t Lose That ’Little Spark of Madness’</b>
			<p>
				Luckily, Campaigners know how to relax, and they are perfectly capable
				of switching from a passionate, driven idealist in the workplace to that
				imaginative and enthusiastic free spirit on the dance floor, often with
				a suddenness that can surprise even their closest friends. Being in the
				mix also gives them a chance to connect emotionally with others, giving
				them cherished insight into what motivates their friends and colleagues.
				They believe that everyone should take the time to recognize and express
				their feelings, and their empathy and sociability make that a natural
				conversation topic.
			</p>
			<p>
				The Campaigner personality type needs to be careful, however – if they
				rely too much on their intuition, assume or anticipate too much about a
				friend’s motivations, they can misread the signals and frustrate plans
				that a more straightforward approach would have made simple. This kind
				of social stress is the bugbear that keeps harmony-focused Diplomats
				awake at night. Campaigners are very emotional and sensitive, and when
				they step on someone’s toes, they both feel it.
			</p>
		</>
	),
	ISTJ: (
		<>
			<h3>Introverted - Sensing - Thinking - Judging</h3>
			<p>
				The Logistician personality type is thought to be the most abundant,
				making up around 13% of the population. Their defining characteristics
				of integrity, practical logic and tireless dedication to duty make
				Logisticians a vital core to many families, as well as organizations
				that uphold traditions, rules and standards, such as law offices,
				regulatory bodies and military. People with the Logistician personality
				type enjoy taking responsibility for their actions, and take pride in
				the work they do – when working towards a goal, Logisticians hold back
				none of their time and energy completing each relevant task with
				accuracy and patience.
			</p>
			<p>
				Logisticians don’t make many assumptions, preferring instead to analyze
				their surroundings, check their facts and arrive at practical courses of
				action. Logistician personalities are no-nonsense, and when they’ve made
				a decision, they will relay the facts necessary to achieve their goal,
				expecting others to grasp the situation immediately and take action.
				Logisticians have little tolerance for indecisiveness, but lose patience
				even more quickly if their chosen course is challenged with impractical
				theories, especially if they ignore key details – if challenges become
				time-consuming debates, Logisticians can become noticeably angry as
				deadlines tick nearer.
			</p>
			<b>
				Associate With Those of Good Quality if You Esteem Your Reputation...
			</b>
			<p>
				When Logisticians say they are going to get something done, they do it,
				meeting their obligations no matter the personal cost, and they are
				baffled by people who don’t hold their own word in the same respect.
				Combining laziness and dishonesty is the quickest way to get on
				Logisticians’ bad side. Consequently, people with the Logistician
				personality type often prefer to work alone, or at least have their
				authority clearly established by hierarchy, where they can set and
				achieve their goals without debate or worry over other’s reliability.
			</p>
			<p>
				Logisticians have sharp, fact-based minds, and prefer autonomy and
				self-sufficiency to reliance on someone or something. Dependency on
				others is often seen by Logisticians as a weakness, and their passion
				for duty, dependability and impeccable personal integrity forbid falling
				into such a trap.
			</p>
			<p>
				This sense of personal integrity is core to Logisticians, and goes
				beyond their own minds – Logistician personalities adhere to established
				rules and guidelines regardless of cost, reporting their own mistakes
				and telling the truth even when the consequences for doing so could be
				disastrous. To Logisticians, honesty is far more important than
				emotional considerations, and their blunt approach leaves others with
				the false impression that Logisticians are cold, or even robotic. People
				with this type may struggle to express emotion or affection outwardly,
				but the suggestion that they don’t feel, or worse have no personality at
				all, is deeply hurtful.
			</p>
		</>
	),
	ISFJ: (
		<>
			<h3>Intuitive - Sensing - Feeling - Judging</h3>
			<p>
				The Defender personality type is quite unique, as many of their
				qualities defy the definition of their individual traits. Though
				sensitive, Defenders have excellent analytical abilities; though
				reserved, they have well-developed people skills and robust social
				relationships; and though they are generally a conservative type,
				Defenders are often receptive to change and new ideas. As with so many
				things, people with the Defender personality type are more than the sum
				of their parts, and it is the way they use these strengths that defines
				who they are.
			</p>
			<p>
				Defenders are true altruists, meeting kindness with kindness-in-excess
				and engaging the work and people they believe in with enthusiasm and
				generosity. There’s hardly a better type to make up such a large
				proportion of the population, nearly 13%. Combining the best of
				tradition and the desire to do good, Defenders are found in lines of
				work with a sense of history behind them, such as medicine, academics
				and charitable social work.
			</p>
			<p>
				Defender personalities (especially Turbulent ones) are often meticulous
				to the point of perfectionism, and though they procrastinate, they can
				always be relied on to get the job done on time. Defenders take their
				responsibilities personally, consistently going above and beyond, doing
				everything they can to exceed expectations and delight others, at work
				and at home.
			</p>
			<b>We Must Be Seen to Be Believed</b>
			<p>
				The challenge for Defenders is ensuring that what they do is noticed.
				They have a tendency to underplay their accomplishments, and while their
				kindness is often respected, more cynical and selfish people are likely
				to take advantage of Defenders’ dedication and humbleness by pushing
				work onto them and then taking the credit. Defenders need to know when
				to say no and stand up for themselves if they are to maintain their
				confidence and enthusiasm.
			</p>
			<p>
				Naturally social, an odd quality for Introverts, Defenders utilize
				excellent memories not to retain data and trivia, but to remember
				people, and details about their lives. When it comes to gift-giving,
				Defenders have no equal, using their imagination and natural sensitivity
				to express their generosity in ways that touch the hearts of their
				recipients. While this is certainly true of their coworkers, whom people
				with the Defender personality type often consider their personal
				friends, it is in family that their expressions of affection fully
				bloom.
			</p>
			<b>If I Can Protect You, I Will</b>
			<p>
				Defender personalities are a wonderful group, rarely sitting idle while
				a worthy cause remains unfinished. Defenders’ ability to connect with
				others on an intimate level is unrivaled among Introverts, and the joy
				they experience in using those connections to maintain a supportive,
				happy family is a gift for everyone involved. They may never be truly
				comfortable in the spotlight, and may feel guilty taking due credit for
				team efforts, but if they can ensure that their efforts are recognized,
				Defenders are likely to feel a level of satisfaction in what they do
				that many other personality types can only dream of.
			</p>
		</>
	),

	ESTJ: (
		<>
			<h3>Extraverted - Sensing - Thinking - Judging</h3>
			<p>
				Executives are representatives of tradition and order, utilizing their
				understanding of what is right, wrong and socially acceptable to bring
				families and communities together. Embracing the values of honesty,
				dedication and dignity, people with the Executive personality type are
				valued for their clear advice and guidance, and they happily lead the
				way on difficult paths. Taking pride in bringing people together,
				Executives often take on roles as community organizers, working hard to
				bring everyone together in celebration of cherished local events, or in
				defense of the traditional values that hold families and communities
				together.
			</p>
			<b>Anyone Worth Their Salt Sticks Up for What They Believe Is Right...</b>
			<p>
				Demand for such leadership is high in democratic societies, and forming
				no less than 11% of the population, it’s no wonder that many of
				America’s presidents have been Executives. Strong believers in the rule
				of law and authority that must be earned, Executive personalities lead
				by example, demonstrating dedication and purposeful honesty, and an
				utter rejection of laziness and cheating, especially in work. If anyone
				declares hard, manual work to be an excellent way to build character, it
				is Executives.
			</p>
			<p>
				Executives are aware of their surroundings and live in a world of clear,
				verifiable facts – the surety of their knowledge means that even against
				heavy resistance, they stick to their principles and push an unclouded
				vision of what is and is not acceptable. Their opinions aren’t just
				empty talk either, as Executives are more than willing to dive into the
				most challenging projects, improving action plans and sorting details
				along the way, making even the most complicated tasks seem easy and
				approachable.
			</p>
			<p>
				However, Executives don’t work alone, and they expect their reliability
				and work ethic to be reciprocated – people with this personality type
				meet their promises, and if partners or subordinates jeopardize them
				through incompetence or laziness, or worse still, dishonesty, they do
				not hesitate to show their wrath. This can earn them a reputation for
				inflexibility, a trait shared by all Sentinel personalities, but it’s
				not because Executives are arbitrarily stubborn, but because they truly
				believe that these values are what make society work.
			</p>
			<b>
				...But Still Better Are Those Who Acknowledge When They Are in Error
			</b>
			<p>
				Executives are classic images of the model citizen: they help their
				neighbors, uphold the law, and try to make sure that everyone
				participates in the communities and organizations they hold so dear. The
				main challenge for Executives is to recognize that not everyone follows
				the same path or contributes in the same way. A true leader recognizes
				the strength of the individual, as well as that of the group, and helps
				bring those individuals’ ideas to the table. That way, Executives really
				do have all the facts, and are able to lead the charge in directions
				that work for everyone.
			</p>
		</>
	),

	ESFJ: (
		<>
			<h3>Extraverted - Sensing - Feeling - Judging</h3>
			<p>
				People who share the Consul personality type are, for lack of a better
				word, popular – which makes sense, given that it is also a very common
				personality type, making up twelve percent of the population. In high
				school, Consuls are the cheerleaders and the quarterbacks, setting the
				tone, taking the spotlight and leading their teams forward to victory
				and fame. Later in life, Consuls continue to enjoy supporting their
				friends and loved ones, organizing social gatherings and doing their
				best to make sure everyone is happy.
			</p>
			<p>
				Discussing scientific theories or debating European politics isn’t
				likely to capture Consuls’ interest for too long. Consuls are more
				concerned with fashion and their appearance, their social status and the
				standings of other people. Practical matters and gossip are their bread
				and butter, but Consuls do their best to use their powers for good.
			</p>
			<b>Respecting the Wisdom of Leadership</b>
			<p>
				Consuls are altruists, and they take seriously their responsibility to
				help and to do the right thing. Unlike their Diplomat relatives however,
				people with the Consul personality type will base their moral compass on
				established traditions and laws, upholding authority and rules, rather
				than drawing their morality from philosophy or mysticism. It’s important
				for Consuls to remember though, that people come from many backgrounds
				and perspectives, and what may seem right to them isn’t always an
				absolute truth.
			</p>
			<p>
				Consuls love to be of service, enjoying any role that allows them to
				participate in a meaningful way, so long as they know that they are
				valued and appreciated. This is especially apparent at home, and Consuls
				make loyal and devoted partners and parents. Consul personalities
				respect hierarchy, and do their best to position themselves with some
				authority, at home and at work, which allows them to keep things clear,
				stable and organized for everyone.
			</p>
			<b>Play Dates Aren’t Just for the Kids!</b>
			<p>
				Supportive and outgoing, Consuls can always be spotted at a party –
				they’re the ones finding time to chat and laugh with everyone! But their
				devotion goes further than just breezing through because they have to.
				Consuls truly enjoy hearing about their friends’ relationships and
				activities, remembering little details and always standing ready to talk
				things out with warmth and sensitivity. If things aren’t going right, or
				there’s tension in the room, Consuls pick up on it and to try to restore
				harmony and stability to the group.
			</p>
			<p>
				Being pretty conflict-averse, Consuls spend a lot of their energy
				establishing social order, and prefer plans and organized events to
				open-ended activities or spontaneous get-togethers. People with this
				personality type put a lot of effort into the activities they’ve
				arranged, and it’s easy for Consuls’ feelings to be hurt if their ideas
				are rejected, or if people just aren’t interested. Again, it’s important
				for Consuls to remember that everyone is coming from a different place,
				and that disinterest isn’t a comment about them or the activity they’ve
				organized – it’s just not their thing.
			</p>
		</>
	),
	ISTP: (
		<>
			<h3>Introverted - Sensing - Thinking - Prospecting</h3>

			<p>
				Virtuosos love to explore with their hands and their eyes, touching and
				examining the world around them with cool rationalism and spirited
				curiosity. People with this personality type are natural Makers, moving
				from project to project, building the useful and the superfluous for the
				fun of it, and learning from their environment as they go. Often
				mechanics and engineers, Virtuosos find no greater joy than in getting
				their hands dirty pulling things apart and putting them back together,
				just a little bit better than they were before.
			</p>
			<p>
				Virtuosos explore ideas through creating, troubleshooting, trial and
				error and first-hand experience. They enjoy having other people take an
				interest in their projects and sometimes don’t even mind them getting
				into their space. Of course, that’s on the condition that those people
				don’t interfere with Virtuosos’ principles and freedom, and they’ll need
				to be open to Virtuosos returning the interest in kind.
			</p>
			<b>Dare to Differ</b>
			<p>
				While their mechanical tendencies can make them appear simple at a
				glance, Virtuosos are actually quite enigmatic. Friendly but very
				private, calm but suddenly spontaneous, extremely curious but unable to
				stay focused on formal studies, Virtuoso personalities can be a
				challenge to predict, even by their friends and loved ones. Virtuosos
				can seem very loyal and steady for a while, but they tend to build up a
				store of impulsive energy that explodes without warning, taking their
				interests in bold new directions.
			</p>
			<p>
				Rather than some sort of vision quest though, Virtuosos are merely
				exploring the viability of a new interest when they make these seismic
				shifts. Virtuosos’ decisions stem from a sense of practical realism, and
				at their heart is a strong sense of direct fairness, a “do unto others”
				attitude, which really helps to explain many of Virtuosos’ puzzling
				traits. Instead of being overly cautious though, avoiding stepping on
				toes in order to avoid having their toes stepped on, Virtuosos are
				likely to go too far, accepting likewise retaliation, good or bad, as
				fair play.
			</p>
			<b>Nothing Is as Boring as Everyone Agreeing With You</b>
			<p>
				Virtuosos will come to learn that many other personality types have much
				more firmly drawn lines on rules and acceptable behavior than they do –
				they don’t want to hear an insensitive joke, and certainly wouldn’t tell
				one back, and they wouldn’t want to engage in horseplay, even with a
				willing party. If a situation is already emotionally charged, violating
				these boundaries can backfire tremendously.
			</p>
			<p>
				Virtuosos have a particular difficulty in predicting emotions, but this
				is just a natural extension of their fairness, given how difficult it is
				to gauge Virtuosos’ emotions and motivations. However, their tendency to
				explore their relationships through their actions rather than through
				empathy can lead to some very frustrating situations. People with the
				Virtuoso personality type struggle with boundaries and guidelines,
				preferring the freedom to move about and color outside the lines if they
				need to.
			</p>
		</>
	),
	ISFP: (
		<>
			<h3>Introverted - Sensing - Feeling - Prospecting</h3>
			<p>
				Adventurer personalities are true artists, but not necessarily in the
				typical sense where they’re out painting happy little trees. Often
				enough though, they are perfectly capable of this. Rather, it’s that
				they use aesthetics, design and even their choices and actions to push
				the limits of social convention. Adventurers enjoy upsetting traditional
				expectations with experiments in beauty and behavior – chances are,
				they’ve expressed more than once the phrase “Don’t box me in!”
			</p>
			<b>Happy to Be Who They Are</b>
			<p>
				Adventurers live in a colorful, sensual world, inspired by connections
				with people and ideas. These personalities take joy in reinterpreting
				these connections, reinventing and experimenting with both themselves
				and new perspectives. No other type explores and experiments in this way
				more. This creates a sense of spontaneity, making Adventurers seem
				unpredictable, even to their close friends and loved ones.
			</p>
			<p>
				Despite all this, Adventurers are definitely Introverts, surprising
				their friends further when they step out of the spotlight to be by
				themselves to recharge. Just because they are alone though, doesn’t mean
				people with the Adventurer personality type sit idle – they take this
				time for introspection, assessing their principles. Rather than dwelling
				on the past or the future, Adventurers think about who they are. They
				return from their cloister, transformed.
			</p>
			<b>Meaning Is in Every Expression of Life</b>
			<p>
				The biggest challenge facing Adventurers is planning for the future.
				Finding constructive ideals to base their goals on and working out goals
				that create positive principles is no small task. Adventurers don’t plan
				their futures in terms of assets and retirement. Rather, they plan
				actions and behaviors as contributions to a sense of identity, building
				a portfolio of experiences, not stocks.
			</p>
			<p>
				If these goals and principles are noble, Adventurers can act with
				amazing charity and selflessness – but it can also happen that people
				with the Adventurer personality type establish a more self-centered
				identity, acting with selfishness, manipulation and egoism. It’s
				important for Adventurers to remember to actively become the person they
				want to be. Developing and maintaining a new habit may not come
				naturally, but taking the time each day to understand their motivations
				allows Adventurers to use their strengths to pursue whatever they’ve
				come to love.
			</p>
		</>
	),
	ESTP: (
		<>
			<h3>Extraverted - Sensing - Thinking - Prospecting</h3>
			<p>
				Entrepreneurs always have an impact on their immediate surroundings –
				the best way to spot them at a party is to look for the whirling eddy of
				people flitting about them as they move from group to group. Laughing
				and entertaining with a blunt and earthy humor, Entrepreneur
				personalities love to be the center of attention. If an audience member
				is asked to come on stage, Entrepreneurs volunteer – or volunteer a shy
				friend.
			</p>
			<p>
				Theory, abstract concepts and plodding discussions about global issues
				and their implications don’t keep Entrepreneurs interested for long.
				Entrepreneurs keep their conversation energetic, with a good dose of
				intelligence, but they like to talk about what is – or better yet, to
				just go out and do it. Entrepreneurs leap before they look, fixing their
				mistakes as they go, rather than sitting idle, preparing contingencies
				and escape clauses.
			</p>
			<b>Never Confuse Movement With Action</b>
			<p>
				Entrepreneurs are the likeliest personality type to make a lifestyle of
				risky behavior. They live in the moment and dive into the action – they
				are the eye of the storm. People with the Entrepreneur personality type
				enjoy drama, passion, and pleasure, not for emotional thrills, but
				because it’s so stimulating to their logical minds. They are forced to
				make critical decisions based on factual, immediate reality in a process
				of rapid-fire rational stimulus response.
			</p>
			<p>
				This makes school and other highly organized environments a challenge
				for Entrepreneurs. It certainly isn’t because they aren’t smart, and
				they can do well, but the regimented, lecturing approach of formal
				education is just so far from the hands-on learning that Entrepreneurs
				enjoy. It takes a great deal of maturity to see this process as a
				necessary means to an end, something that creates more exciting
				opportunities.
			</p>
			<p>
				Also challenging is that to Entrepreneurs, it makes more sense to use
				their own moral compass than someone else’s. Rules were made to be
				broken. This is a sentiment few high school instructors or corporate
				supervisors are likely to share, and can earn Entrepreneur personalities
				a certain reputation. But if they minimize the trouble-making, harness
				their energy, and focus through the boring stuff, Entrepreneurs are a
				force to be reckoned with.
			</p>
			<b>Most People Don’t Listen Well Enough</b>
			<p>
				With perhaps the most perceptive, unfiltered view of any type,
				Entrepreneurs have a unique skill in noticing small changes. Whether a
				shift in facial expression, a new clothing style, or a broken habit,
				people with this personality type pick up on hidden thoughts and motives
				where most types would be lucky to pick up anything specific at all.
				Entrepreneurs use these observations immediately, calling out the change
				and asking questions, often with little regard for sensitivity.
				Entrepreneurs should remember that not everyone wants their secrets and
				decisions broadcast.
			</p>
		</>
	),
	ESFP: (
		<>
			<h3>Extraverted - Sensing - Feeling - Prospecting</h3>
			<p>
				If anyone is to be found spontaneously breaking into song and dance, it
				is the Entertainer personality type. Entertainers get caught up in the
				excitement of the moment, and want everyone else to feel that way, too.
				No other personality type is as generous with their time and energy as
				Entertainers when it comes to encouraging others, and no other
				personality type does it with such irresistible style.
			</p>
			<b>We Are All of Us Stars...</b>
			<p>
				Entertainers love the spotlight, and all the world’s a stage. Many
				famous people with the Entertainer personality type are indeed actors,
				but they love putting on a show for their friends too, chatting with a
				unique and earthy wit, soaking up attention and making every outing feel
				a bit like a party. Utterly social, Entertainers enjoy the simplest
				things, and there’s no greater joy for them than just having fun with a
				good group of friends.
			</p>
			<p>
				It’s not just talk either – Entertainers have the strongest aesthetic
				sense of any personality type. From grooming and outfits to a
				well-appointed home, Entertainer personalities have an eye for fashion.
				Knowing what’s attractive the moment they see it, Entertainers aren’t
				afraid to change their surroundings to reflect their personal style.
				Entertainers are naturally curious, exploring new designs and styles
				with ease.
			</p>
			<p>
				Though it may not always seem like it, Entertainers know that it’s not
				all about them – they are observant, and very sensitive to others’
				emotions. People with this personality type are often the first to help
				someone talk out a challenging problem, happily providing emotional
				support and practical advice. However, if the problem is about them,
				Entertainers are more likely to avoid a conflict altogether than to
				address it head-on. Entertainers usually love a little drama and
				passion, but not so much when they are the focus of the criticisms it
				can bring.
			</p>
			<b>...And We Deserve to Twinkle</b>
			<p>
				The biggest challenge Entertainers face is that they are often so
				focused on immediate pleasures that they neglect the duties and
				responsibilities that make those luxuries possible. Complex analysis,
				repetitive tasks, and matching statistics to real consequences are not
				easy activities for Entertainers. They’d rather rely on luck or
				opportunity, or simply ask for help from their extensive circle of
				friends. It is important for Entertainers to challenge themselves to
				keep track of long-term things like their retirement plans or sugar
				intake – there won’t always be someone else around who can help to keep
				an eye on these things.
			</p>
			<p>
				Entertainers recognize value and quality, which on its own is a fine
				trait. In combination with their tendency to be poor planners though,
				this can cause them to live beyond their means, and credit cards are
				especially dangerous. More focused on leaping at opportunities than in
				planning out long-term goals, Entertainers may find that their
				inattentiveness has made some activities unaffordable.
			</p>
		</>
	),
};

export default MBTI_CODES;
