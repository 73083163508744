/* -------------------------- Internal Dependencies ------------------------- */
import BASE_URL from 'services/config';
import api from 'services/api';
import { token } from 'utils/user_persist';
import { getError } from 'utils';
import { NotificationsActions } from './types';

/**
 * @function
 */
export const getNotifications = () => {
	return async (dispatch) => {
		dispatch({
			type: NotificationsActions.GET_NOTIFICATIONS,
			payload: {
				loading: true,
				data: null,
			},
		});

		const url = `${BASE_URL}/api/notifications`;
		const options = api.options('GET', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: NotificationsActions.GET_NOTIFICATIONS,
				payload: {
					loading: false,
					data: response.data,
				},
			});
		} catch (err) {
			dispatch({
				type: NotificationsActions.GET_NOTIFICATIONS,
				payload: {
					loading: false,
					data: null,
				},
			});
			getError(dispatch, err);
		}
	};
};

export const markRead = () => {
	return async (dispatch) => {
		dispatch({
			type: NotificationsActions.READ_NOTIFICATIONS,
			payload: {
				loading: true,
			},
		});

		const url = `${BASE_URL}/api/notifications/read`;
		const options = api.options('PATCH', token, {});
		try {
			const response = await api.request(url, options);
			dispatch({
				type: NotificationsActions.READ_NOTIFICATIONS,
				payload: {
					loading: false,
					data: response.data.message,
				},
			});
		} catch (err) {
			dispatch({
				type: NotificationsActions.READ_NOTIFICATIONS,
				payload: {
					loading: false,
				},
			});
			getError(dispatch, err);
		}
	};
};
