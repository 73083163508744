/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FieldArray, Field } from 'formik';
import AceEditor from 'react-ace';

/* -------------------------- Internal Dependencies ------------------------- */
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import Input, { Text } from 'components/input';
import {
	getHours,
	getMinutes,
	getSeconds,
	getLanguages,
	getThemes,
} from 'utils';
import useMount from 'utils/useMount';
import RichTextEditor from 'components/rich-text-editor';
import CodeEditorField from './code-editor';

/* ---------------------------- Image Dependecies --------------------------- */
import { ReactComponent as Close } from '../../../../assets/icons/ic_round-close.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';

/* --------------------------- Styles Dependencies -------------------------- */
import {
	DescriptionContainer,
	H3,
	Description,
	OptionInputContainer,
	AddOptionContainer,
	CompletionTimeHeader,
	SelectGroupContainer,
	SelectContainer,
	CodeEditorContainer,
	OptionGroupContainer,
	CodeSelectContainer,
	ThemeSelectContainer,
} from '../styles/question';

const CodeQuestionContainer = ({
	handleChange,
	changeValue,
	setFieldValue,
	handleBlur,
	values,
	timeError,
	errors,
	touched,
}) => {
	const [hour] = useState(getHours());
	const [minute] = useState(getMinutes());
	const [second] = useState(getSeconds());

	const [themes] = useState(getThemes());
	const [languages] = useState(getLanguages());

	const LoadTheme = useCallback(() => {
		themes.forEach((theme) =>
			require(`ace-builds/src-noconflict/theme-${theme}`)
		);
		languages.forEach((lang) => {
			try {
				// eslint-disable-next-line import/no-dynamic-require
				require(`ace-builds/src-noconflict/mode-${lang}`);
				// eslint-disable-next-line import/no-dynamic-require
				require(`ace-builds/src-noconflict/snippets/${lang}`);
			} catch (e) {
				// eslint-disable-next-line import/no-dynamic-require
				require(`ace-builds/src-noconflict/mode-${'text'}`);
			}
		});
	}, [themes, languages]);

	useMount(() => LoadTheme());

	return (
		<>
			<div className="row">
				<div className="col-md-4">
					<DescriptionContainer>
						<H3>Question</H3>
						<Description>Enter Question</Description>
					</DescriptionContainer>
				</div>
				<div className="col-md-8">
					<RichTextEditor
						hasStrip
						id="description"
						name="description"
						onBlur={(e) =>
							handleBlur({
								target: { value: e, name: 'description', id: 'description' },
							})
						}
						label=""
						placeholder="Write question here"
						value={values.description}
						onChange={(e) => {
							handleChange({
								target: { value: e, name: 'description', id: 'description' },
							});
							changeValue('description', e);
						}}
						errorMessage={errors.description}
						isInvalid={errors.description && touched.description}
					/>
				</div>

				<>
					<div className="col-md-4">
						<DescriptionContainer>
							<H3>Code (optional)</H3>
							<Description>
								Enter correct code and test for this question
							</Description>
						</DescriptionContainer>
					</div>
					<div className="col-md-8">
						<CodeEditorContainer>
							<OptionGroupContainer>
								<CodeSelectContainer>
									{errors.mode && (
										<Text color="#f66262" fontSize="12px" className="error">
											{errors.mode}
										</Text>
									)}
									<Field
										name="mode"
										as="select"
										onChange={(e) => {
											setFieldValue('mode', e.target.value);
											changeValue('mode', e.target.value);
										}}
									>
										{languages.map((lang, index) => (
											<option key={index} value={lang}>
												{lang}
											</option>
										))}
									</Field>
									<ArrowDown />
								</CodeSelectContainer>
								<ThemeSelectContainer>
									<Field name="theme" as="select">
										<option defaultValue={values.theme}>Select Theme</option>
										{themes.map((lang, index) => (
											<option key={index} value={lang}>
												{lang}
											</option>
										))}
									</Field>
									<ArrowDown />
								</ThemeSelectContainer>
							</OptionGroupContainer>
							<Field
								id="code_snippet"
								placeholder="Your code goes here"
								name="code_snippet"
								value={values.code_snippet}
								changeValue={changeValue}
								mode={values.mode}
								theme={values.theme}
								component={CodeEditorField || AceEditor}
							/>
						</CodeEditorContainer>
					</div>
					<div className="col-md-4">
						<DescriptionContainer>
							<H3>Expected Output (optional)</H3>
							<Description>
								Enter the expected output of this question
							</Description>
						</DescriptionContainer>
					</div>
					<div className="col-md-8">
						<CodeEditorContainer>
							<Field
								id="expected_output"
								isOutputEditor
								name="expected_output"
								changeValue={changeValue}
								value={values.expected_output}
								mode={values.mode}
								theme={values.theme}
								component={CodeEditorField || AceEditor}
							/>
						</CodeEditorContainer>
					</div>
					<div className="col-md-4">
						<DescriptionContainer>
							<H3>Stdin (optional)</H3>
							<Description>Optional, Input for the coding question</Description>
						</DescriptionContainer>
					</div>
					<div className="col-md-8">
						<FieldArray
							name="stdin"
							render={(arrayHelpers) => (
								<div>
									{values.stdin &&
										values.stdin.map((std, index) => (
											<OptionInputContainer key={index}>
												<Field
													id={`stdin[${index}]`}
													type="text"
													className="ml-2"
													name={`stdin[${index}]`}
													placeholder="Input"
													onBlur={() => {
														changeValue('stdin', values.stdin);
													}}
												/>
												{values.stdin.length === 1 ? (
													<div />
												) : (
													<Close
														onClick={() => {
															arrayHelpers.remove(index);
															return changeValue(
																'stdin',
																values.stdin.filter(
																	(val, indx) => indx !== index
																)
															);
														}}
													/>
												)}
											</OptionInputContainer>
										))}
									<AddOptionContainer
										onClick={() => {
											arrayHelpers.push('');
										}}
									>
										<Plus /> <span>Add new stdin</span>
										<span
											className="d-block mt-2"
											style={{
												color: 'red',
												fontSize: '12px',
											}}
										>
											{errors.stdin && errors.stdin.length > 0 ? (
												<>Stdin is required</>
											) : (
												''
											)}{' '}
											<br />
										</span>
									</AddOptionContainer>
								</div>
							)}
						/>
					</div>
				</>

				<div className="col-md-4">
					<DescriptionContainer>
						<H3>Configure</H3>
						<Description>
							Set skills, expected time for task completion and number of
							points.
						</Description>
					</DescriptionContainer>
				</div>
				<div className="col-md-8">
					<Input
						hasStrip
						id="points"
						name="points"
						type="number"
						onBlur={handleBlur}
						label="Number of points"
						placeholder="Enter points"
						value={values.points}
						onChange={(e) => {
							handleChange(e);
							changeValue('points', e.target.value);
						}}
						errorMessage={errors.points}
						isInvalid={errors.points && touched.points}
					/>
					<CompletionTimeHeader>Expected completion time</CompletionTimeHeader>
					<SelectGroupContainer>
						<SelectContainer questionType="coding">
							<Field
								name="hours"
								as="select"
								onChange={(e) => {
									setFieldValue('hours', e.target.value);
									changeValue('hours', e.target.value);
								}}
							>
								<option value="">Hours</option>
								{hour.map((hr, index) => (
									<option key={index} value={hr.value}>
										{hr.text}
									</option>
								))}
							</Field>
							<ArrowDown />
						</SelectContainer>
						<SelectContainer questionType="coding">
							<Field
								name="minutes"
								as="select"
								onChange={(e) => {
									setFieldValue('minutes', e.target.value);
									changeValue('minutes', e.target.value);
								}}
							>
								<option value="">Minutes</option>
								{minute.map((min, index) => (
									<option key={index} value={min.value}>
										{min.text}
									</option>
								))}
							</Field>
							<ArrowDown />
						</SelectContainer>
						<SelectContainer questionType="coding">
							<Field
								name="seconds"
								as="select"
								onChange={(e) => {
									setFieldValue('seconds', e.target.value);
									changeValue('seconds', e.target.value);
								}}
							>
								<option value="">Seconds</option>
								{second.map((sec, index) => (
									<option key={index} value={sec.value}>
										{sec.text}
									</option>
								))}
							</Field>
							<ArrowDown />
						</SelectContainer>
					</SelectGroupContainer>
					<span className="d-block" style={{ color: 'red', fontSize: '12px' }}>
						{timeError ||
							(values.hours === '00' &&
								values.minutes === '00' &&
								values.seconds === '00' && <>Time cannot be 00:00:00</>)}
						{touched.hours && errors.hours} <br />
						{touched.minutes && errors.minutes} <br />
						{touched.seconds && errors.seconds}
					</span>
				</div>
			</div>
		</>
	);
};

CodeQuestionContainer.propTypes = {
	handleChange: PropTypes.func,
	changeValue: PropTypes.func,
	setFieldValue: PropTypes.func,
	handleBlur: PropTypes.func,
	values: PropTypes.object,
	timeError: PropTypes.string,
	errors: PropTypes.any,
	touched: PropTypes.any,
};

export default connect(null)(CodeQuestionContainer);
