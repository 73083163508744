/* -------------------------- External Dependencies ------------------------- */
import { setState, getState } from 'codewonders-helpers';

export const loadState = () => {
	try {
		const serializedState = window?.atob(getState('DEVELOP_TEST'));
		if (serializedState === null) {
			return JSON.parse('{}');
		}
		return JSON.parse(serializedState);
	} catch (error) {
		return undefined;
	}
};

export const saveState = (state) => {
	try {
		if (!state.tests) {
			return;
		}
		const serializedState = JSON.stringify(state);
		setState('DEVELOP_TEST', window?.btoa(serializedState));
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log(err);
	}
};
