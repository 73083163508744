/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import Button from 'components/button';
import { moveSegment } from 'redux/developp-tests/actions/create-test';
import wordsToNumbers from 'words-to-numbers';

/* ------------------------- DragComponent PropTypes ------------------------ */
const propTypes = {
	items: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	dispatch: PropTypes.func,
};

const DragComponent = ({ items: question, dispatch, children, ...rest }) => {
	const [showDragged, toggleDragModal] = useState({
		show: false,
		title: null,
		id: 0,
	});

	const [{ isDragging }, drag] = useDrag({
		item: { name: question.name, type: 'box' },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult) {
				toggleDragModal((prev) => {
					return {
						...prev,
						show: true,
						title: dropResult.name.name,
						id: dropResult.name.id,
					};
				});
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	return (
		<>
			<div
				ref={drag}
				style={{
					opacity: isDragging ? 0.4 : 1,
					display: 'inline-block',
					background: isDragging ? '#ffeded' : null,
				}}
				{...rest}
			>
				{children}
			</div>
			<Modal
				title={`Move ${question.name}`}
				show={showDragged.show}
				closeModal={() => toggleDragModal({ ...showDragged, show: false })}
			>
				<div>
					<p>
						You are about to move <strong>{question.name}</strong> from{' '}
						<strong>{question.segment}</strong> into{' '}
						<strong>{showDragged.title} </strong>. Are you sure you want to
						continue ?
					</p>
					<Button
						className="btn-developp mt-3 mb-auto"
						onClick={() => {
							dispatch(
								moveSegment(
									rest.slug,
									question.questId,
									{
										segment: showDragged?.id,
									},
									{
										segmentNum:
											showDragged.title &&
											wordsToNumbers(
												showDragged?.title?.replace('Segment', '')
											).trim(),
									}
								)
							);

							toggleDragModal((prev) => {
								return {
									...prev,
									show: false,
								};
							});
						}}
						style={{ padding: '0.785rem 2rem' }}
					>
						Continue
					</Button>
				</div>
			</Modal>
		</>
	);
};

DragComponent.propTypes = propTypes;

export default DragComponent;
