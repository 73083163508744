/* ------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import { useEffect, useMemo } from 'react';

const useMount = (cb) =>
	useEffect(() => {
		cb();
		// eslint-disable-next-line
	}, []);

export const useWillMount = (effect) => useMemo(effect, []);

export default useMount;
